import React, { useState, useEffect } from "react";

import MonthlyMenu from "./Menu/MonthlyMenu";
import SaleMeunu from "./Menu/SaleMenu";
import MnmSaleTable from "./Table/MnmSaleTable";
import { api } from "../../api";
import { num } from "../../Components/System/mosFn";

const MnmSale = ({ match }) => {
  const [result, setResult] = useState([]);

  const fetchData = async () => {
    await api
      .post("/mnm/listProductsByMonth", [match.params.y, match.params.m])
      .then((xx) => {
        setResult(
          xx.data.map((aa) => {
            aa.product =
              num(aa.A) +
              "|" +
              aa.B +
              "|" +
              (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "") +
              aa.C +
              "|" +
              aa.D;
            return aa;
          })
        );
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, [match.params.y, match.params.m]);
  return (
    <div>
      <div className="text-center">
        <MonthlyMenu match={match} type={"sale"} />
      </div>
      <main role="main" className="container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
          <h2>สรุปรายการขาย</h2>
          <SaleMeunu match={match} />
        </div>

        <h4>
          ข้อมูลของ เดือน {match.params.m} ปี {match.params.y}
        </h4>
      </main>
      <main className="container">
        <MnmSaleTable
          data={result}
          time={{ year: match.params.y, month: match.params.m }}
        />
      </main>
    </div>
  );
};

export default MnmSale;
