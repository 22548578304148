import React from "react";

const MacBtn = ({ macName, chooseMac, addedMac }) => {
  return (
    <button
      className="btn btn-outline-secondary flex1"
      style={
        addedMac[macName] === true
          ? { backgroundColor: "rgb(200, 200, 200)" }
          : { backgroundColor: "rgb(240, 240, 240)" }
      }
      onClick={() => chooseMac(macName)}
    >
      {macName}
    </button>
  );
};

const NoMacBtn = () => {
  return <button disabled className="btn btn-outline-secondary flex1"></button>;
};

const WeavAddChooseMac = ({ chooseMac, addedMac }) => {
  return (
    <div className="row">
      <div className="btn-group btn-block col-12">
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="A1" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="A2" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="A3" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="A4" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="A5" />
      </div>
      <div className="btn-group btn-block col-12">
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="B1" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="B2" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="B3" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="B4" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="B5" />
      </div>
      <div className="btn-group btn-block col-12">
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="C1" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="C2" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="C3" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="C4" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="C5" />
        {/* <NoMacBtn /> */}
      </div>
      <div className="btn-group btn-block col-12">
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="D1" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="D2" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="D3" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="D4" />
        <MacBtn chooseMac={chooseMac} addedMac={addedMac} macName="D6" />
      </div>
      {/* <div className="col-12">เสริม :</div>
      <div className="btn-group btn-block col-12">
        <NoMacBtn />
        <NoMacBtn />
        <NoMacBtn />
        <NoMacBtn />
        <NoMacBtn />
      </div> */}
    </div>
  );
};

export default WeavAddChooseMac;
