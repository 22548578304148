import React, { useState, useEffect } from "react";

import MonthlyMenu from "./Menu/MonthlyMenu";
import ProductionMenu from "./Menu/ProductionMenu";
import MnmDayTable from "./Table/MnmDayTable";
import MnmDayGraph from "./Table/MnmDayGraph";
import { api } from "../../api";
import { num } from "../../Components/System/mosFn";

const MnmDay = ({ match }) => {
  const { m, y } = match.params;
  const [result, setResult] = useState([]);
  const fetchData = async () => {
    await api
      .post("/mnm/searchMonth", [y, m, y, m])
      .then((xx) => {
        setResult(xx.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, [y, m]);

  let summeter = 0;
  const nday = result.length;
  result.forEach((i) => (summeter += i.TotalMeter));

  return (
    <div>
      <div className="text-center">
        <MonthlyMenu match={match} type={"day"} />
      </div>
      <main role="main" className="container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
          <h2>สรุปประจำวัน</h2>
          <ProductionMenu match={match} />
        </div>

        <h4>
          ข้อมูลของ เดือน {m} ปี {y}
        </h4>
        <h2 style={{ textAlign: "right" }} id="avgdata">
          มิเตอร์เฉลี่ย {num(summeter / nday)}
        </h2>
      </main>
      <MnmDayGraph data={result} />
      <MnmDayTable data={result} />
    </div>
  );
};

export default MnmDay;
