import React, { useState, useEffect } from "react";
import { api } from "../../../../api";
import SearchProduct from "../../../../Components/Others/SearchProduct";
import { Link } from "react-router-dom";
import { num } from "../../../../Components/System/mosFn";

const MnmProdTool = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(["", "", "", ""]);
  const [filtered, setFiltered] = useState([]);

  const handleOnChange = (e, type) => {
    const newSearch = [...search];
    newSearch[type] = e.target.value;
    setSearch(newSearch);
  };
  const handleClearSearch = () => {
    setSearch(["", "", "", ""]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get("/mnm/getProdSaleV");
      setData(
        res.data.result.map((aa) => {
          aa.A = num(aa.A);
          aa.B =
            "" +
            aa.B +
            (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "");
          return aa;
        })
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    const newFiltered = data
      .filter((aa) => {
        return aa.A.toString().indexOf(search[0]) !== -1;
      })
      .filter((aa) => {
        return aa.B.toString().indexOf(search[1]) !== -1;
      })
      .filter((aa) => {
        return aa.C.toString().indexOf(search[2]) !== -1;
      })
      .filter((aa) => {
        return aa.D.toString().indexOf(search[3]) !== -1;
      });
    setFiltered(newFiltered);
  }, [search, data]);

  return (
    // <div className="container">
    <div>
      <SearchProduct
        onChange={handleOnChange}
        onClick={handleClearSearch}
        value={search}
      />
      <main className="tb">
        <div className="table-head">
          <div className="textcenter flex1">สินค้า</div>
          <div className="flex4 center" />
          <div className="textcenter foption"></div>
        </div>
        {filtered.map((row, idx) => (
          <div className="table-body" key={`ptool-${idx}`}>
            <div className="flexrow">
              <div className="flex1 center">
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <b>{row.A}</b> | <b> {row.B} </b>| <b>{row.C}</b> |{" "}
                  <b>{row.D}</b>
                </div>
              </div>
              <Link
                className="btn btn-secondary foption cc"
                to={`/management/prodTool/prod/${row.prodID}`}
              >
                ดูการผลิต
              </Link>
              <Link
                className="btn btn-secondary foption cc"
                to={`/management/prodTool/sale/${row.prodID}`}
              >
                ดูการขาย
              </Link>
            </div>
          </div>
        ))}
      </main>
    </div>
  );
};

export default MnmProdTool;
