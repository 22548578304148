import React, { useState, useEffect } from "react";
import MonthlyMenu from "./Menu/MonthlyMenu";
import ProductionMenu from "./Menu/ProductionMenu";
import { api } from "../../api";
import { num } from "../../Components/System/mosFn";

const MnmProduct = ({ match }) => {
  const { m, y } = match.params;
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const res = await api.get(`/mnm/product/${y}/${m}`);
    setData(
      res.data.result.map((aa) => {
        aa.A = num(aa.A);
        aa.B =
          "" +
          aa.B +
          (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "");
        return aa;
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, [m, y]);

  return (
    <div>
      <div className="text-center">
        <MonthlyMenu match={match} type={"product"} />
      </div>
      <main role="main" className="container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
          <h2>สรุปรายการผลิต</h2>
          <ProductionMenu match={match} />
        </div>

        <h4>
          ข้อมูลของ เดือน {m} ปี {y}
        </h4>
      </main>
      <table className="table table-striped table-condensed">
        <thead>
          <tr>
            <th>รายการ</th>
            <th>ราคาหั่งเช้ง</th>
            <th>จำนวนผืน</th>
            <th>มูลค่า(หั่งเช้ง)</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, idx) => (
            <tr key={`mnmproduct-${idx}`}>
              <td>
                {row.A}|{row.B}|{row.C}|{row.D}
              </td>
              <td>{row.price}</td>
              <td>{num(row.piece)}</td>
              <td>{num(row.price * row.piece)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MnmProduct;
