import React from "react";
import { Route } from "react-router-dom";
import WeavMain from "./WeavMain";
import WeavAdd from "./WeavAdd";
import WeavRpt from "./WeavRpt";
import Header from "../../../Components/Header/Header";
import ProtectedRoute from "../../../Components/System/ProtectedRoute";
import Menu from "../../../Components/UI/Menu";

const Weaving = ({ match }) => {
  const link = [
    { name: "เครื่องทอ", to: "" },
    { name: "เพิ่มมีเตอร์", to: "add", auth: "prodWeavAdd" },
    { name: "รายการเพิ่มมีเตอร์", to: "report" },
  ];
  return (
    <div className="container">
      <Header name="แผนกทอ" />
      <Menu match={match.path} link={link} />
      <hr />
      <div>
        <Route exact path={`${match.path}`} component={WeavMain} />
        <ProtectedRoute
          access="prodWeavAdd"
          path={`${match.path}/add`}
          component={WeavAdd}
        />
        <Route path={`${match.path}/report`} component={WeavRpt} />
      </div>
    </div>
  );
};

export default Weaving;
