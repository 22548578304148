import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import { api } from "../../api";

const MnmRequest = () => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    await api
      .get("/mnm/listRequest")
      .then((xx) => {
        setData(xx.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onClickCf = async (idx) => {
    const { reqQuery, reqID } = data[idx];
    const reqData = JSON.parse(data[idx].reqData);
    const res = await api.post(reqQuery, reqData);
    console.log(res);
    if (res.data.success) {
      api.post("/mnm/confirmReq", { reqID: reqID }).then(() => {
        alert(res.data.result);
        fetchData();
      });
    } else {
      alert("ไม่สำเร็จ " + JSON.parse(res.data.error).sqlMessage);
    }
  };

  const onClickCc = (idx) => {
    const { reqID } = data[idx];
    api.post("/mnm/cancelReq", { reqID: reqID }).then((yy) => {
      fetchData();
    });
  };

  const status = (reqStatus) => {
    if (reqStatus === 0) {
      return "pending";
    } else if (reqStatus === 1) {
      return "confirmed";
    } else if (reqStatus === 2) {
      return "cancelled";
    } else {
      return "error";
    }
  };
  return (
    <div className="container">
      <Header name="คำขอแก้ไข" />
      <div>
        {data.map((row, idx) => (
          <div
            key={`req-${idx}`}
            className="flexcol"
            style={{
              border: "1px solid black",
              margin: "3px",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            <div className="flexrow">
              <b style={{ margin: "5px" }}>{row.reqID}</b>
              <div style={{ margin: "5px" }}>
                <div
                  style={{
                    backgroundColor: `${
                      row.reqStatus === 0
                        ? "gray"
                        : row.reqStatus === 1
                        ? "green"
                        : "red"
                    }`,
                    width: "1rem",
                    height: "1rem",
                    borderRadius: "50%",
                  }}
                  title={status(row.reqStatus)}
                />
              </div>
              <div style={{ margin: "5px" }}>{row.reqName}</div>
              <div style={{ margin: "5px" }}>{row.reqQuery}</div>
              <div style={{ margin: "5px" }}>{row.reqDate}</div>
              <div style={{ margin: "5px" }}>{row.reqUser}</div>
            </div>
            <div className="flexrow">
              <div style={{ margin: "5px" }}>
                {/* {JSON.stringify(row.reqData)} */}
                {row.reqData}
              </div>
            </div>
            <div style={{ margin: "5px" }}>{row.reqReason}</div>
            {!row.reqStatus && (
              <div className="flexrow" style={{ justifyContent: "center" }}>
                <button
                  className="btn btn-success"
                  onClick={() => onClickCf(idx)}
                >
                  ยืนยัน
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => onClickCc(idx)}
                >
                  ยกเลิก
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MnmRequest;
