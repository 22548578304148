import React from "react";
import { Route, Redirect } from "react-router-dom";
import MenuBar from "../Header/MenuBar";
import { withAuth } from "../../AuthContext";

function ProtectedRoute({ component: Component, access, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.auth.isAuthenticated ? (
          !access || rest.auth.access[access] === 1 ? (
            <span>
              <MenuBar />

              <Component {...props} />
            </span>
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default withAuth(ProtectedRoute);
