import React from "react";
import { num } from "../../Components/System/mosFn";
import CardName from "./Components/CardName";

const ListCart = ({
  cart,
  idx,
  clickAmount,
  onChange,
  onClick,
  onChangeD,
  onChangeP,
}) => {
  return (
    <div>
      <div className="cart-list-body">
        <div className="cart-list-left">
          <div className="cart-list-left-row1 text-center">
            <CardName data={cart} />
            {/* <div className="">
              <b>
                {cart.A}x{cart.B}x{cart.C}x{cart.D}
                <Grade>{cart.grade} </Grade>
              </b>
            </div> */}
            <div className="">ราคาหั่งเช้ง {cart.price}</div>
          </div>
          <div className="cart-list-left-row2">
            <div style={{ flex: "1 0 0" }} className="mcol-md">
              ส่วนลด (%)
              <input
                className="form-control"
                onChange={(e) => {
                  onChangeD(e, idx);
                }}
                value={cart.discount}
              />
            </div>

            <div style={{ flex: "1 0 0" }} className="mcol-md">
              ราคาขาย (ต่อผิน)
              <input
                className="form-control"
                onChange={(e) => {
                  onChangeP(e, idx);
                }}
                value={cart.salePrice}
              />
            </div>
          </div>
        </div>
        <div className="cart-list-right">
          <div className="cart-amount">
            <div
              className="cart-amount-btn"
              onClick={() => {
                clickAmount("-", idx);
              }}
            >
              −
            </div>
            <input
              className="cart-amount-input text-center"
              value={cart.amount}
              onChange={(e) => onChange(e, idx)}
              style={{ width: "50px" }}
            />
            <div
              className="cart-amount-btn"
              onClick={() => {
                clickAmount("+", idx);
              }}
            >
              +
            </div>
          </div>
          <div>{num(cart.salePrice * cart.amount)}</div>
          <div className="cart-delbtn" onClick={() => onClick(idx)}>
            ลบ
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCart;
