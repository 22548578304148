import React, { useState, useEffect } from "react";
import CardData from "./CardData";
import SearchProduct from "../../Components/Others/SearchProduct";
import ProdAdding from "./Main/ProdAdding";

const InvMain = ({ data, reload }) => {
  const [newFiltered, setFiltered] = useState([]);
  const [search, setSearch] = useState(["", "", "", ""]);

  const handleOnChange = (e, type) => {
    const newSearch = [...search];
    newSearch[type] = e.target.value;
    setSearch(newSearch);
  };
  const handleClearSearch = () => {
    setSearch(["", "", "", ""]);
  };

  useEffect(() => {
    const newFiltered = data
      .filter((aa) => {
        return aa.A.toString().indexOf(search[0]) !== -1;
      })
      .filter((aa) => {
        return aa.B.toString().indexOf(search[1]) !== -1;
      })
      .filter((aa) => {
        return aa.C.toString().indexOf(search[2]) !== -1;
      })
      .filter((aa) => {
        return aa.D.toString().indexOf(search[3]) !== -1;
      });
    setFiltered(newFiltered);
  }, [search, data]);
  return (
    <div>
      <ProdAdding reload={reload} />
      <SearchProduct
        onChange={handleOnChange}
        onClick={handleClearSearch}
        value={search}
      />
      {newFiltered.map((row, idx) => (
        <CardData value={row} key={`cardd-${idx}`} reload={reload} />
      ))}
    </div>
  );
};

export default InvMain;
