import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Cart.css";

import ListCust from "./ListCust";
import ListCart from "./ListCart";
import CartFooter from "./CartFooter";
import ChosenCust from "./ChosenCust";
import CustAdding from "../Customer/Components/CustAdding";
import { api } from "../../api";
import { withAuth } from "../../AuthContext";

const Cart = ({
  auth,
  cart,
  clickAmount,
  onChange,
  onChangeD,
  onChangeP,
  onClick,
  onClose,
  reload,
  onOpen,
}) => {
  const [realPrice, setRealPrice] = useState(0);
  const [sumPrice, setSumPrice] = useState(0);
  const [totalPercentDiscount, setPercentDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderDate, setOrderDate] = useState(new Date());
  const [remark, setRemark] = useState("");
  const [cust, setCust] = useState([]);
  const [isChoosingCust, setChoosingCust] = useState(true);
  const [isAddingCust, setAddingCust] = useState(false);

  const getChooseCust = (data) => {
    setCust(data);
    setChoosingCust(false);
  };

  const convertDate = (date) => {
    return (
      date.getUTCFullYear() +
      "-" +
      ("0" + (date.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getUTCDate()).slice(-2)
    );
  };
  const round = (num) => {
    return Math.round(num * 100) / 100;
  };

  const sumAllPrice = () => {
    let sprice = 0;
    let rprice = 0;
    for (let i = 0; i < cart.length; i++) {
      sprice += cart[i].salePrice * cart[i].amount;
      rprice += cart[i].price * cart[i].amount;
    }
    setSumPrice(sprice);
    setRealPrice(rprice);
  };

  const handleOnChangeTotalPrice = (e) => {
    setTotalPrice(e.target.value);
    const xx = round(100 - (e.target.value / realPrice) * 100);
    setPercentDiscount(xx);
    // updatePercent();
  };

  const handleOnChangeRemark = (e) => {
    setRemark(e.target.value);
  };

  const handleOnClickSell = () => {
    if (!cust.custID) return alert("เลือกลูกค้า");
    if (totalPrice === 0 || isNaN(totalPrice)) return alert("ใส่ราคาขาย");

    const clearData = () => {
      setTotalPrice(0);
      setRealPrice(0);
      setSumPrice(0);
      setPercentDiscount(0);
      setRemark("");
      setCust([]);
    };

    const dataOrder = {
      custID: cust.custID,
      totalprice: totalPrice,
      remark: remark,
      addDiscount: round(sumPrice - totalPrice),
      discount: totalPercentDiscount,
      orderDate: convertDate(orderDate),
      submitter: auth.user,
    };
    const orderDetail = cart.map((aa) => ({
      prodID: aa.prodID,
      quantity: aa.amount,
      discount: aa.discount,
      salePrice: aa.salePrice,
    }));
    api
      .post("/inv/sell", {
        order: dataOrder,
        orderDetail: orderDetail,
      })
      .then((xx) => {
        xx.data.success
          ? alert(xx.data.message)
          : alert(
              "ผิดพลาด: " +
                JSON.parse(xx.data.error).sqlMessage +
                "\nโปรดแจ้งข้อความนี้"
            );
        clearData();
        return reload();
      });
  };

  const handleClickSearch = () => {
    setChoosingCust((prev) => !prev);
    setAddingCust(false);
  };
  const handleClickAdd = () => {
    setAddingCust((prev) => !prev);
    setChoosingCust(false);
  };
  const handleClickCancel = () => {
    setAddingCust((prev) => !prev);
  };

  const afterSubmitAdding = (id, data) => {
    setAddingCust(false);
    setCust({ custID: id, ...data });
    getChooseCust({ custID: id, ...data });
  };

  useEffect(() => {
    sumAllPrice();
    const xx = !Number.isNaN(totalPrice / realPrice)
      ? round(100 - (totalPrice / realPrice) * 100)
      : 0;
    setPercentDiscount(xx);
  }, [totalPrice, realPrice, cart]);

  // const updatePercent = () => {
  //   sumAllPrice();
  //   const xx = !Number.isNaN(totalPrice / realPrice)
  //     ? round(100 - (totalPrice / realPrice) * 100)
  //     : 0;
  //   setPercentDiscount(xx);
  // };

  const modalStyle = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    pointerEvents: "auto",
    backgroundColor: "#f2f2f2",
    backgroundClip: " padding-box",
    outline: 0,
  };

  return (
    <div style={modalStyle}>
      {/* <ModalContent> */}
      <div className="modal-header">
        <h4 className="modal-title">ขายสินค้า</h4>
      </div>

      <div className="modal-body">
        <div className="cust-search">
          <div className="cust-search-1 btn-group">
            <button
              type="button"
              className="btn btn-outline-secondary btn-s"
              onClick={handleClickSearch}
            >
              ค้นหา
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary btn-s"
              onClick={handleClickAdd}
            >
              ลูกค้าใหม่
            </button>
            <button type="button" className="btn btn-outline-secondary btn-s">
              ไม่ระบุลูกค้า
            </button>
          </div>
        </div>

        {isAddingCust ? (
          <CustAdding
            afterSubmit={afterSubmitAdding}
            onClickCancel={handleClickCancel}
          />
        ) : isChoosingCust ? (
          <ListCust chooseCust={getChooseCust} />
        ) : (
          <ChosenCust data={cust} />
        )}

        <hr />
        <div className="flexrow">
          <label className="mcol-2">วันที่บิล</label>
          <div className="mcol-3">
            <DatePicker
              className="form-control"
              selected={orderDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date) => {
                setOrderDate(date);
              }}
            />
          </div>
        </div>

        <div className="cart">
          {cart.map((data, idx) => (
            <ListCart
              key={`cart-${data.prodID}`}
              cart={data}
              idx={idx}
              onChange={onChange}
              onChangeD={onChangeD}
              onChangeP={onChangeP}
              onClick={onClick}
              clickAmount={clickAmount}
            />
          ))}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="btn-cart-add" onClick={onOpen}>
              + เพิ่มรายการ
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-md">
            <label>รายละเอียดเพิ่มเติม</label>
            <input
              type="text"
              className="form-control"
              id="remarks"
              value={remark}
              onChange={(e) => handleOnChangeRemark(e)}
            />
          </div>
        </div>
        <div className="mmodal-footer">
          <CartFooter
            sumPrice={sumPrice}
            onChange={handleOnChangeTotalPrice}
            percent={totalPercentDiscount}
            onClickSell={handleOnClickSell}
            totalPrice={totalPrice}
          />
        </div>
      </div>
      {/* </ModalContent> */}
    </div>
  );
};

export default withAuth(Cart);
