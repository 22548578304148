import React, { useState } from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import { api } from "../../../../api";
import { num } from "../../../../Components/System/mosFn";

const WeavDataRow = ({ data, handleClickDeleteData }) => {
  const [detail, setDetail] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const handleClickMore = () => {
    if (detail.length === 0) {
      fetchData();
    }
    setOpen((prev) => !prev);
  };
  // const handleClickDeleteData = async () => {
  //   console.log("del " + data.weavingID);
  //   let submitData = { weavingID: data.weavingID };
  //   let postUrl = "/weav/delWeavingData";
  //   if (auth.level !== "admin") {
  //     submitData = {
  //       name: "Delete Order",
  //       query: postUrl,
  //       data: { weavingID: data.weavingID },
  //       user: auth.user
  //     };
  //     postUrl = "/request";
  //   }
  //   const res = await api.post(postUrl, submitData);
  //   if (res.data.success) {
  //     alert(res.data.result);
  //     // reload();
  //   } else {
  //     alert(
  //       "ไม่สำเร็จ โปรดแจ้งข้อความนี้" + JSON.parse(res.data.error).sqlMessage
  //     );
  //   }
  // };

  const fetchData = async () => {
    await api.get("/weav/getWeavDetail/" + data.weavingID).then((xx) => {
      if (!xx.data.success) return alert(xx.data.error);
      setDetail(
        xx.data.result.map((aa) => {
          aa.A = num(aa.A);
          aa.B =
            "" +
            aa.B +
            (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "");
          return aa;
        })
      );
    });
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(220,220,220)",
        margin: "5px",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: "1 0 0", margin: "auto", textAlign: "center" }}>
          {data.weavingID}
        </div>
        <div style={{ flex: "2 0 0", margin: "auto", textAlign: "center" }}>
          {data.dataDate}
        </div>
        <div style={{ flex: "2 0 0", margin: "auto", textAlign: "center" }}>
          {data.reportDate}
        </div>
        <div style={{ flex: "1 0 0", margin: "auto", textAlign: "center" }}>
          {data.reporter}
        </div>
        <div style={{ flex: "3 0 0", margin: "5px", display: "flex" }}>
          <button
            className="btn btn-secondary"
            style={{ flex: "1 0 0" }}
            onClick={handleClickMore}
          >
            ดูเพิ่มเติม
          </button>
          <button
            className="btn"
            onClick={() =>
              handleClickDeleteData(
                { weavingID: data.weavingID },
                "/weav/delWeavingData",
                "ลบรายการทอ"
              )
            }
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
      {isOpen && detail.length > 0 && (
        <div
          style={{ margin: "5px", backgroundColor: "white", padding: "5px" }}
        >
          {detail.map((row, idx) => (
            <div
              key={`detail-${idx}`}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: "5 0 0",
                  flexWrap: "wrap",
                  margin: "3px",
                  backgroundColor: "rgb(240,240,240)",
                }}
              >
                <div
                  className="col-md-5"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ flex: "1 0 0", margin: "auto" }}>
                    เครื่อง {row.mac}
                  </div>
                  <div style={{ flex: "1 0 0", margin: "auto" }}>
                    {row.dataDate}
                  </div>
                </div>
                <div
                  className="col-md-5"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ flex: "1 0 0", margin: "auto" }}>
                    {num(row.A)}|{row.B}|{row.C}|{row.D}
                  </div>
                  <div style={{ flex: "1 0 0", margin: "auto" }}>
                    {row.meter}
                  </div>
                </div>
                <div
                  className="col-md-2"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ flex: "1 0 0", margin: "auto" }}>
                    {row.npiece} ผืน/เครื่อง
                  </div>
                </div>
                <div className="col-12">
                  <div style={{ flex: "1 0 0", margin: "auto" }}>
                    {row.note}
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn"
                  style={{ padding: "10px" }}
                  onClick={() =>
                    handleClickDeleteData(
                      { mac: row.mac, detailID: row.detailID },
                      "/weav/delWeavingDetail",
                      "ลบรายการทอเครื่อง"
                    )
                  }
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WeavDataRow;
