import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { api } from "../../../../api";
import { withAuth } from "../../../../AuthContext";

const ExtdDetail = ({ auth, extrudeID, reload }) => {
  const [plastic, setPlastic] = useState([]);
  const [filament, setFilament] = useState([]);
  const [waste, setWaste] = useState([]);

  const handleClickDelete = async () => {
    let submitData = {
      extrudeID: extrudeID,
      plastic: plastic,
    };
    let postUrl = "/extd/extdDelete/";
    if (auth.level !== "admin") {
      submitData = {
        name: "Delete Extrude",
        query: postUrl,
        data: submitData,
        user: auth.user,
      };
      postUrl = "/request";
    }

    const res = await api.post(postUrl, submitData);
    if (res.data.success) {
      alert(res.data.result);
      reload();
    } else {
      alert(
        "ไม่สำเร็จ โปรดแจ้งข้อความนี้" + JSON.parse(res.data.error).sqlMessage
      );
    }
  };

  const fetchData = () => {
    api.get("/mnm/getExtrudeDetail/" + extrudeID).then((xx) => {
      setPlastic(xx.data.plastic);
      setFilament(xx.data.filament);
      setWaste(xx.data.waste);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const subStyle = {
    backgroundColor: "lightgrey",
    margin: "5px",
    borderRadius: "5px",
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "white",
        borderRadius: "5px",
      }}
    >
      <div className="col-md-6">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="col-12" style={subStyle}>
            <div className="text-center mg5">
              <b>พลาสติก</b>
            </div>
            {plastic.map((row) => (
              <div key={`plas-${row.plasticName}`} className="row">
                <div className="col">{row.plasticName}</div>
                <div className="col">
                  {row.amountKg} kg ({row.amountBag} ถุง)
                </div>
              </div>
            ))}
          </div>
          <div className="col-12" style={subStyle}>
            <div className="text-center mg5">
              <b>ใยเสีย</b>
            </div>
            {waste.map((row) => (
              <div key={`waste-${row.plasticName}`} className="row">
                <div className="col">{row.position}</div>
                <div className="col">{row.amount} kg</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="col-12" style={subStyle}>
            <div className="text-center mg5">
              <b>ใยที่ฉีด</b>
            </div>
            <div className="row">
              <div className="col-6">
                <div>หลอดปีก</div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col">เวลา</div>
                      <div className="col">ปริมาณ</div>
                    </div>
                    {filament.map((row, idx) => {
                      return row.type === "หลอดปีก" ? (
                        <div key={`t1-time-${idx}`} className="row">
                          <div className="col">
                            {row.H}:{("0" + row.M).slice(-2)}
                          </div>
                          <div className="col">{row.amount} kg</div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div>หลอดสมอ</div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col">เวลา</div>
                      <div className="col">ปริมาณ</div>
                    </div>
                    {filament.map((row, idx) => {
                      return row.type === "หลอดสมอ" ? (
                        <div key={`t1-time-${idx}`} className="row">
                          <div className="col">
                            {row.H}:{("0" + row.M).slice(-2)}
                          </div>
                          <div className="col">{row.amount} kg</div>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 text-center" style={{ margin: "10px" }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleClickDelete}
        >
          {auth.level === "admin" ? "ลบรายการฉีดใย" : "แจ้งลบรายการ"}
        </Button>
      </div>
    </div>
  );
};

export default withAuth(ExtdDetail);
