import React, { useState, useEffect } from "react";
import SearchProduct from "../../../../Components/Others/SearchProduct";

import Search from "@material-ui/icons/Search";

const ChooseProd = (props) => {
  const {
    search,
    meter,
    detail,
    prodAdding,
    setSearch,
    setMeter,
    setDetail,
    setProdAdding,
    listProd,
    mac,
    onSubmit,
  } = props;
  const [filtered, setFiltered] = useState(listProd);

  const [bar, setBar] = useState(0);

  useEffect(() => {
    const filtered = listProd
      .filter((aa) => {
        return aa.A.toString().indexOf(search[0]) !== -1;
      })
      .filter((aa) => {
        return aa.B.toString().indexOf(search[1]) !== -1;
      })
      .filter((aa) => {
        return aa.C.toString().indexOf(search[2]) !== -1;
      })
      .filter((aa) => {
        return aa.D.toString().indexOf(search[3]) !== -1;
      });
    setFiltered(filtered);
  }, [search, listProd]);

  const handleChangeSearch = (e, type) => {
    const newSearch = [...search];
    newSearch[type] = e.target.value;
    setSearch(newSearch);
  };

  const handleChoose = (prod) => {
    if (prodAdding.length === 0) {
      prod.maxpiece = Math.floor((mac.nspool + 1) / (prod.C + 1));
      prod.npiece = prod.maxpiece;
    } else {
      let ar = [];
      let totalPiece = 0;

      for (let i = 0; i < prodAdding.length; i++) {
        ar.push(prodAdding[i].npiece * prodAdding[i].C);
        totalPiece += parseInt(prodAdding[i].npiece);
        prodAdding[i].xx = Math.floor((mac.nspool + 1) / (prodAdding[i].C + 1));
      }

      prod.maxpiece = Math.floor(
        (mac.nspool - (arrSum(ar) + totalPiece)) / (prod.C + 1)
      );

      //   prod.maxpiece = 99; //Math.floor(xxxx)
      prod.npiece = prod.maxpiece;
      if (prod.maxpiece < 0) {
        prod.npiece = 0;
      }
    }
    setSearch([prod.A, prod.B, "", prod.D]);
    const newProdAdding = [...prodAdding, prod];
    setProdAdding(newProdAdding);
  };

  const handleClearSearch = () => {
    setSearch(["", "", "", ""]);
  };

  const handleClickDelete = (idx) => {
    const newProdAdding = [...prodAdding];
    delete newProdAdding[idx].maxpiece;
    delete newProdAdding[idx].npiece;
    newProdAdding.splice(idx, 1);
    setProdAdding(newProdAdding);
  };

  const handleOnPieceChange = (e, idx) => {
    const newProdAdding = [...prodAdding];
    if (isNaN(parseInt(e.target.value))) {
      newProdAdding[idx].npiece = 0;
    } else {
      newProdAdding[idx].npiece = parseInt(e.target.value);
    }
    let ar = [];
    let totalPiece = 0;

    for (let i = 0; i < prodAdding.length; i++) {
      if (i > idx) {
        prodAdding[i].maxpiece = Math.floor(
          (mac.nspool - (arrSum(ar) + totalPiece)) / (prodAdding[i].C + 1)
        );
      }
      ar.push(prodAdding[i].npiece * prodAdding[i].C);
      totalPiece += parseInt(prodAdding[i].npiece);
    }

    setProdAdding(newProdAdding);
  };

  const handleClickAmount = (op, idx) => {
    const newProdAdding = [...prodAdding];
    if (op === "+") {
      newProdAdding[idx].npiece += 1;
      let ar = [];
      let totalPiece = 0;
      for (let i = 0; i < prodAdding.length; i++) {
        if (i > idx) {
          prodAdding[i].maxpiece = Math.floor(
            (mac.nspool - (arrSum(ar) + totalPiece)) / (prodAdding[i].C + 1)
          );
        }
        ar.push(prodAdding[i].npiece * prodAdding[i].C);
        totalPiece += parseInt(prodAdding[i].npiece);
      }
    }
    if (op === "-") {
      if (newProdAdding[idx].npiece > 0) {
        newProdAdding[idx].npiece -= 1;

        let ar = [];
        let totalPiece = 0;

        for (let i = 0; i < prodAdding.length; i++) {
          if (i > idx) {
            prodAdding[i].maxpiece = Math.floor(
              (mac.nspool - (arrSum(ar) + totalPiece)) / (prodAdding[i].C + 1)
            );
          }
          ar.push(prodAdding[i].npiece * prodAdding[i].C);
          totalPiece += parseInt(prodAdding[i].npiece);
        }
      }
    }
    setProdAdding(newProdAdding);
  };

  const handleOnChangeMeter = (e) => {
    let value = parseInt(e.target.value);
    if (value < 0 || isNaN(value)) {
      value = 0;
    }
    setMeter(value);
  };
  const handleOnChangeDetail = (e) => {
    setDetail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(prodAdding);
    // clearData();
  };

  useEffect(() => {
    const newProdAdding = [...prodAdding];

    let ar = [];
    let totalPiece = 0;

    for (let i = 0; i < newProdAdding.length; i++) {
      ar.push(newProdAdding[i].npiece * newProdAdding[i].C);
      totalPiece += newProdAdding[i].npiece;
      newProdAdding[i].xx = Math.floor(
        (mac.nspool + 1) / (newProdAdding[i].C + 1)
      );
    }

    ar = arrSum(ar) + (totalPiece - 1);
    const bar = (ar / mac.nspool) * 100;
    setBar(bar < 0 ? 0 : bar);
  }, [prodAdding, mac.nspool]);

  const handleClickSearch = (item) => {
    const newSearch = item.split("|");
    setSearch(newSearch);
  };

  return (
    <div
      style={{
        height: "90%",
        width: "90%",
        margin: "auto",
        backgroundColor: "white",
      }}
    >
      <div className="text-center">
        <h1>เครื่อง {mac.mac}</h1>
      </div>
      {prodAdding.length !== 0 && (
        <form
          onSubmit={(e) => handleSubmit(e)}
          style={{
            backgroundColor: "#f2f2f2",
            padding: "10px",
            borderRadius: "3px",
            // border: "1px solid black",
          }}
        >
          <h3>รายการ</h3>
          {prodAdding.map((row, idx) => (
            <div
              key={`prodAdding-${row.prodID}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() => handleClickDelete(idx)}
                  style={{ padding: ".1875rem .375rem", margin: "3px" }}
                >
                  ลบ
                </button>
                {row.A} | {row.B} | {row.C} | {row.D}
              </div>

              <div>
                สูงสุด: {row.maxpiece}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <button
                    className="btn"
                    style={{ border: "1px solid rgb(206, 212, 218)" }}
                    type="button"
                    onClick={() => {
                      handleClickAmount("-", idx);
                    }}
                  >
                    -
                  </button>
                  <input
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: ".25rem",
                    }}
                    onChange={(e) => handleOnPieceChange(e, idx)}
                    value={row.npiece}
                  />
                  <button
                    className="btn"
                    style={{ border: "1px solid rgb(206, 212, 218)" }}
                    type="button"
                    onClick={() => {
                      handleClickAmount("+", idx);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div>
            Machine Utilization
            <div className="progress">
              <div
                className={`progress-bar ${bar > 100 ? "bg-danger" : ""}`}
                role="progressbar"
                style={{ width: `${bar}%` }}
                aria-valuenow="75"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div>
            มีเตอร์
            <input
              autoFocus
              className="form-control"
              value={meter}
              onChange={(e) => handleOnChangeMeter(e)}
            />
          </div>
          <div>
            รายละเอียดเพิ่มเติม
            <input
              className="form-control"
              value={detail}
              onChange={(e) => handleOnChangeDetail(e)}
            />
          </div>
          <button
            className="btn btn-block btn-secondary"
            type="submit"
            onClick={handleSubmit}
          >
            เพิ่มรายการทอของเครื่อง {mac.mac}
          </button>
        </form>
      )}
      <div></div>
      {mac.p1 && (
        <div style={{ padding: "10px" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <h4>รายการผลิตปัจจุบัน</h4> *กดเพื่อค้นหา
            โปรดกด'เพิ่ม'ด้วยหากต้องการเลือก
          </div>
          <SearchBtn data={mac.p1} onClick={handleClickSearch} />
          {mac.p2 && <SearchBtn data={mac.p2} onClick={handleClickSearch} />}
          {mac.p3 && <SearchBtn data={mac.p3} onClick={handleClickSearch} />}
        </div>
      )}
      <SearchProduct
        onChange={handleChangeSearch}
        onClick={handleClearSearch}
        value={search}
      />
      <div>
        {filtered.map((row, idx) => {
          return (
            <div
              key={`prodID-${row.prodID}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "5px",
                padding: "5px",
                borderRadius: "3px",
                backgroundColor: "rgb(240, 240, 240)",
                alignItems: "center",
              }}
            >
              <div>
                {row.A} | {row.B} | {row.C} | {row.D} {row.grade}
              </div>
              <div>
                <div
                  className={`btn btn-secondary ${
                    filtered[idx].npiece ? "disabled" : ""
                  }`}
                  onClick={() =>
                    filtered[idx].npiece ? "" : handleChoose(row)
                  }
                >
                  {filtered[idx].npiece ? "✓" : "เพิ่ม"}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChooseProd;

const SearchBtn = ({ data, onClick }) => {
  return (
    <button
      className="btn"
      style={{
        backgroundColor: "lightgray",
        border: "1px solid black",
        margin: "5px",
      }}
      onClick={() => onClick(data)}
    >
      <Search />
      {data}
    </button>
  );
};

const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);
