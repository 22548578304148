import React from "react";
import { Link } from "react-router-dom";

const MonthlyMenu = ({ match }) => {
  return (
    <div className="btn-toolbar mb-2 mb-md-0">
      <div className="btn-group mr-2">
        <Link
          to={`/management/monthly/day/${match.params.y}/${match.params.m}`}
          className="btn btn-sm btn-outline-secondary"
          id="btn1"
        >
          สรุปประจำวัน
        </Link>
        <Link
          to={`/management/monthly/product/${match.params.y}/${match.params.m}`}
          className="btn btn-sm btn-outline-secondary"
          id="btn2"
        >
          สรุปรายการผลิต
        </Link>
        <Link
          to={`/management/monthly/machine/${match.params.y}/${match.params.m}`}
          className="btn btn-sm btn-outline-secondary"
          id="btn3"
        >
          สรุปรายงานเครื่อง
        </Link>
      </div>
    </div>
  );
};

export default MonthlyMenu;
