import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ChooseMac from "./WeavAdd/ChooseMac";
import ChooseProd from "./WeavAdd/ChooseProd";
import { api } from "../../../api";

import Dialog from "@material-ui/core/Dialog";
import { withAuth } from "../../../AuthContext";
import { num } from "../../../Components/System/mosFn";

const WeavAdd = ({ auth }) => {
  const [date, setDate] = useState(new Date());
  const [mac, setMac] = useState("");
  const [weavingDetail, setWeavingDetail] = useState([]);
  const [addedMac, setAddedMac] = useState([]);

  const [listProd, setListProd] = useState([]);
  const [listMac, setListMac] = useState([]);

  const [open, setOpen] = useState(false);

  //chooseProd
  const [search, setSearch] = useState(["", "", "", ""]);
  const [meter, setMeter] = useState("");
  const [detail, setDetail] = useState("");
  const [prodAdding, setProdAdding] = useState([]);
  const clearDataAdding = () => {
    setSearch(["", "", "", ""]);
    setMeter("");
    setDetail("");
    setProdAdding([]);
    const newListProd = [...listProd];
    for (let i = 0; i < newListProd.length; i++) {
      delete newListProd[i].npiece;
      delete newListProd[i].maxpiece;
    }
    setListProd(newListProd);
  };

  const fetchData = async () => {
    await api
      .get("/weav/getForWeaving")
      .then((xx) => {
        setListProd(
          xx.data.prod.map((aa) => {
            aa.A = num(aa.A);
            aa.B =
              "" +
              aa.B +
              (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "");
            return aa;
          })
        );
        setListMac(xx.data.mac);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = () => {
    const weavingData = { reporter: auth.user, data_date: convertDate(date) };
    let newWeavingDetail = [...weavingDetail];
    newWeavingDetail = weavingDetail.map((row) => {
      row.date = convertDate(date);
      return row;
    });
    api
      .post("/weav/addWeaving", {
        data: weavingData,
        detail: newWeavingDetail,
      })
      .then((xx) => {
        xx.data.success
          ? alert(xx.data.message)
          : alert(
              "ผิดพลาด: " +
                JSON.parse(xx.data.error).sqlMessage +
                "\nโปรดแจ้งข้อความนี้"
            );

        clearData();
      });
  };

  const clearData = () => {
    setMac("");
    setWeavingDetail([]);
  };

  const convertDate = (date) => {
    return (
      date.getUTCFullYear() +
      "-" +
      ("0" + (date.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getUTCDate()).slice(-2)
    );
  };

  const handleChooseMac = (mac) => {
    const machine = listMac.find(function (machine) {
      return machine.mac === mac;
    });
    setMac(machine);
    setOpen(true);
  };

  const handleClickAdd = (prodAdding) => {
    if (meter === "" || meter === 0) {
      return alert("ใส่จำนวนมีเตอร์");
    }

    const newAddedMac = addedMac;
    newAddedMac[mac.mac] = true;
    setAddedMac(newAddedMac);

    const newWeavingDetail = [...weavingDetail];
    const newData = {
      // date: convertDate(date),
      mac: mac.mac,
      prod: prodAdding.map((row) => ({
        prodID: row.prodID,
        prodName: row.A + "|" + row.B + "|" + row.C + "|" + row.D,
        npiece: row.npiece,
      })),
      meter: meter,
      detail: detail,
    };
    newWeavingDetail.push(newData);
    setWeavingDetail(newWeavingDetail);
    //clear for next mac
    setOpen(false);
    clearDataAdding();
  };

  const handleClickDelete = (idx) => {
    const newAddedMac = addedMac;
    newAddedMac[weavingDetail[idx].mac] = false;
    setAddedMac(newAddedMac);

    const newWeavingDetail = [...weavingDetail];
    newWeavingDetail.splice(idx, 1);
    setWeavingDetail(newWeavingDetail);
  };

  return (
    <div>
      <div className="row">
        <label className="col-md-2 col-form-label">วันที่ทอ</label>
        <div className="col-md-4">
          <DatePicker
            className="form-control"
            dateFormat="dd/MM/yyyy"
            selected={date}
            onChange={(date) => {
              setDate(date);
            }}
          />
        </div>
      </div>
      <br />

      <div
        style={{
          backgroundColor: "rgb(197, 197, 197)",
          padding: "5px",
          borderRadius: "5px",
        }}
      >
        <ChooseMac chooseMac={handleChooseMac} addedMac={addedMac} />
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            clearDataAdding();
          }}
        >
          <ChooseProd
            search={search}
            meter={meter}
            detail={detail}
            prodAdding={prodAdding}
            setSearch={setSearch}
            setMeter={setMeter}
            setDetail={setDetail}
            setProdAdding={setProdAdding}
            listProd={listProd}
            mac={mac}
            onSubmit={handleClickAdd}
          />
        </Dialog>

        {/* )} */}
      </div>
      <hr />
      {weavingDetail.length > 0 && (
        <div
          style={{
            marginTop: "10px",
            padding: "3px",
            backgroundColor: "rgb(197, 197, 197)",
            borderRadius: "5px",
          }}
        >
          <h3 style={{ padding: "5px" }}>รายการเพิ่มมีเตอร์</h3>
          {weavingDetail.map((row, idx) => {
            return (
              <div
                key={`filtered-${idx}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px",
                  padding: "5px",
                  borderRadius: "5px",
                  backgroundColor: "rgb(240, 240, 240)",
                }}
                className="row"
              >
                <div
                  className="col-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "lightgrey",
                  }}
                >
                  <b>{row.mac}</b>
                </div>
                <div className="col">
                  {row.prod.map((row, idx) => (
                    <div className="row" key={`prodWeav-${idx}`}>
                      <div className="col">{row.prodName}</div>
                      <div className="col">{row.npiece}</div>
                    </div>
                  ))}
                </div>
                <div className="col-2">{row.meter}</div>

                <button
                  className={`col-2 btn-secondary ${
                    row.nmove ? "mdisabled" : ""
                  }`}
                  style={{ borderRadius: "10px" }}
                  onClick={() => handleClickDelete(idx)}
                >
                  ลบ
                </button>

                {row.detail && (
                  <div className="col-12">
                    <b>รายละเอียด: </b>
                    {row.detail}
                  </div>
                )}
              </div>
            );
          })}
          <button className="btn btn-block btn-danger" onClick={handleSubmit}>
            ยืนยันข้อมูลมีเตอร์
          </button>
        </div>
      )}
    </div>
  );
};

export default withAuth(WeavAdd);
