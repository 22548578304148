import React from "react";

const PlasticAdd = () => {
  return (
    <div style={{ border: "1px solid #ced4da", padding: "10px" }}>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label>ชื่อพลาสติก</label>
          <input type="text" className="form-control" id="inputName" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label>ชนิดเม็ด</label>
          <select className="form-control" id="inputType">
            <option>PA6</option>
            <option>PE</option>
            <option>อื่นๆ</option>
          </select>
        </div>
        <div className="form-group col-md-8">
          <label>น้ำหนักต่อถุง</label>
          <div className="input-group">
            <input type="number" className="form-control" id="inputWPB" />
            <div className="input-group-append">
              <span className="input-group-text">กิโลกรัม ต่อ ถุง</span>
            </div>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12">
          <label>รายละเอียด</label>
          <input type="text" className="form-control" id="inputDetail" />
        </div>
      </div>
      <div className="text-center">
        <button className="btn btn-success" disabled>
          ยืนยัน
        </button>
      </div>
    </div>
  );
};

export default PlasticAdd;
