import React, { useState, useEffect } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { api } from "../../../api";

const MnmOrderDetailTable = ({ prodID, time }) => {
  const [result, setResult] = useState([]);
  const fetchData = async () => {
    await api
      .post("/mnm/listProductsByIDAndMonth", [time.year, time.month, prodID])
      .then((xx) => {
        console.log(xx.data);
        setResult(xx.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <ReactTable
        data={result}
        minRows={0}
        showPagination={false}
        columns={[
          {
            Header: "ข้อมูล",
            columns: [
              {
                Header: "ชื่อลูกค้า",
                accessor: "custName",
              },
              {
                Header: "จังหวัด",
                // accessor: d => d.lastName
                accessor: "city",
              },
            ],
          },
          {
            Header: "รายละเอียดการขาย",
            columns: [
              {
                Header: "วันที่ขาย",
                accessor: "orderDate",
              },
              {
                Header: "จำนวน",
                accessor: "quantity",
              },
            ],
          },
        ]}
        className="-striped -highlight"
      />
    </div>
  );
};

export default MnmOrderDetailTable;
