import React, { useState } from "react";
import "../Extruding.css";

const AddWaste = ({ onSubmit }) => {
  const [position, setPosition] = useState("");
  const [amount, setAmount] = useState(0);
  const [isOtherPos, setOtherPos] = useState(true);

  const handleOnSubmit = () => {
    if (position === "") {
      return alert("เลือกตำแหน่งใยเสีย");
    }
    if (isNaN(amount) || amount === "0") {
      return alert("ใส่ปริมาณใยเสียเป็นตัวเลข");
    }
    const data = { position: position, amount: parseFloat(amount) };
    onSubmit(data);
    setPosition("");
    setAmount(0);
    setOtherPos(true);
  };

  const onChangeAmount = e => {
    setAmount(e.target.value);
  };
  const onChangePosition = e => {
    setPosition(e.target.value);
  };

  const onClickDrop = position => {
    setPosition(position);
    setOtherPos(position === "" ? true : false);
  };

  const round = num => {
    return Math.round(num * 100) / 100;
  };

  return (
    <div className="input-group mt-3 mb-3">
      <div className="input-group-prepend">
        <button
          type="button"
          id="wastedd"
          className="btn btn-outline-secondary dropdown-toggle"
          data-toggle="dropdown"
        >
          เลือกตำแหน่งใยเสีย
        </button>
        <div className="dropdown-menu">
          <div className="dropdown-item" onClick={() => onClickDrop("R2")}>
            R2
          </div>
          <div className="dropdown-item" onClick={() => onClickDrop("R3")}>
            R3
          </div>
          <div className="dropdown-item" onClick={() => onClickDrop("R4")}>
            R4
          </div>
          <div className="dropdown-item" onClick={() => onClickDrop("")}>
            อื่นๆ โปรดระบุ
          </div>
        </div>
      </div>
      <input
        className="col-2 form-control"
        type="text"
        placeholder="ตำแหน่ง"
        onChange={onChangePosition}
        value={position}
        disabled={!isOtherPos}
      />
      <input
        type="text"
        className={`form-control ${isNaN(amount) ? "wrong-input" : ""}`}
        placeholder="ปริมาณใยเสีย"
        onChange={onChangeAmount}
        value={amount}
      />
      <div className="input-group-append">
        <button
          className="btn btn-primary"
          onClick={handleOnSubmit}
          type="button"
        >
          เพิ่ม
        </button>
      </div>
    </div>
  );
};

export default AddWaste;
