import React from "react";
import "./Inventory.css";

const ChosenCust = ({ data }) => {
  return (
    <div style={{ backgroundColor: "rgb(240, 240, 240)" }}>
      <div className="form-row col">
        <div className="form-group col-md-8">
          <label>ชื่อลูกค้า</label>
          <div className="custdetail">{data.custName}</div>
        </div>
        <div className="form-group col-md-4">
          <label>เบอร์โทร</label>
          <div className="custdetail">{data.custTel} </div>
        </div>
      </div>
      <div className="form-row col-md">
        <div className="form-group col-md-6">
          <label>ที่อยู่</label>
          <div className="custdetail">{data.address} </div>
        </div>
        <div className="form-group col-md-4">
          <label>จังหวัด</label>
          <div className="custdetail">{data.city} </div>
        </div>
        <div className="form-group col-md-2">
          <label>รหัสไปรษณีย์</label>
          <div className="custdetail">{data.postalCode} </div>
        </div>
      </div>
    </div>
  );
};

export default ChosenCust;
