import React, { useState, useEffect } from "react";
import { api } from "../../api";
import { withAuth } from "../../AuthContext";
import CardName from "./Components/CardName";

const Card = ({ auth, value, reload }) => {
  const [minFT, setMinFT] = useState("");
  const [maxFT, setMaxFT] = useState("");
  const [minWH, setMinWH] = useState("");
  const [maxWH, setMaxWH] = useState("");

  const [editing, setEditing] = useState(false);
  const [weight, setWeight] = useState("");
  const [pieceOven, setPieceOven] = useState("");
  const [price, setPrice] = useState("");
  const [ft, setFt] = useState("");
  const [wh1, setWh1] = useState("");
  const [wh2, setWh2] = useState("");

  useEffect(() => {
    setWeight(value.weight);
    setPieceOven(value.piece_oven);
    setPrice(value.price);
    setEditing(false);
    setFt(value.inv_ft);
    setWh1(value.inv_wh - value.inv_wh2);
    setWh2(value.inv_wh2);
    setMinFT(value.min_ft);
    setMaxFT(value.max_ft);
    setMinWH(value.min_wh);
    setMaxWH(value.max_wh);
  }, [value]);

  const clickCard = () => {
    setEditing((prev) => !prev);
  };

  const onChangeWeight = (e) => {
    setWeight(e.target.value);
  };
  const onChangePieceOven = (e) => {
    setPieceOven(e.target.value);
  };
  const onChangePrice = (e) => {
    setPrice(e.target.value);
  };
  const onChangeFt = (e) => {
    setFt(e.target.value);
  };
  const onChangeInv1 = (e) => {
    setWh1(e.target.value);
  };
  const onChangeInv2 = (e) => {
    setWh2(e.target.value);
  };

  const flexCol = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  };
  const cardHead = {
    backgroundColor: "#e6e6e6",
  };

  const handleSubmitRange = (e) => {
    e.preventDefault();
    if (isNaN(minFT) || isNaN(maxFT) || isNaN(minWH) || isNaN(maxWH))
      return alert("กรอกข้อมูลเป็นตัวเลข");
    const rangeData = {
      minFT: minFT,
      maxFT: maxFT,
      minWH: minWH,
      maxWH: maxWH,
      prodID: value.prodID,
    };
    api.post("/inv/updateRange", rangeData).then((xx) => {
      if (xx.data.success) {
        alert(xx.data.result);
        reload();
      } else {
        alert(xx.data.error);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNaN(weight) || isNaN(pieceOven) || isNaN(price)) {
      return alert("กรอกข้อมูลเป็นตัวเลข");
    }
    const editData = {
      weight: weight,
      pieceOven: pieceOven,
      price: price,
      prodID: value.prodID,
    };
    if (auth.access["prodEdit"]) {
      api.post("/inv/updateProduct", editData).then((xx) => {
        if (xx.data.success) {
          alert(xx.data.result);
          reload();
        }
      });
    } else {
      const post = {
        name: "Edit Product",
        query: "/inv/updateProduct",
        data: editData,
        user: auth.user,
      };
      api.post("/request", post).then((xx) => {
        if (xx.data.success) {
          alert(xx.data.result);
        } else {
          alert(xx.data.error);
        }
      });
    }
  };

  const handleSubmitAmount = (e) => {
    e.preventDefault();
    if (isNaN(ft) || isNaN(wh1) || isNaN(wh2)) {
      return alert("กรอกข้อมูลเป็นตัวเลข");
    }
    const editData = {
      ft: parseInt(ft),
      wh1: parseInt(wh1),
      wh2: parseInt(wh2),
      prodID: value.prodID,
    };
    if (auth.access["prodEdit"]) {
      api.post("/inv/updateProductAmount", editData).then((xx) => {
        if (xx.data.success) {
          alert(xx.data.result);
          reload();
        } else {
          alert(xx.data.error);
        }
      });
    } else {
      const post = {
        name: "Edit Product Amount",
        query: "/inv/updateProductAmount",
        data: editData,
        user: auth.user,
      };
      api.post("/request", post).then((xx) => {
        if (xx.data.success) {
          alert(xx.data.result);
        } else {
          alert(xx.data.error);
        }
      });
    }
  };

  return (
    <div className="mcard">
      <div
        className="mcard-bodyclm"
        style={{ border: " 1px solid #ced4da", borderRadius: "5px" }}
      >
        <div className="mcard-bodyclm-row" onClick={clickCard} style={cardHead}>
          <CardName data={value} />
          <div>ราคา {value.price} ฿</div>
        </div>
        <div
          className="row"
          style={{ backgroundColor: "white", margin: "0px" }}
        >
          <div
            className={`col-sm text-center ${
              value.inv_ft < value.min_ft && "min"
            } ${value.inv_ft > value.max_ft && "max"}`}
            style={{ padding: "5px" }}
          >
            <div style={{ backgroundColor: "blue", color: "white" }}>
              โรงงาน
            </div>
            <div className="flex-spa">
              <div className="range">{value.min_ft}</div>
              <b>{value.inv_ft}</b>
              <div className="range">{value.max_ft}</div>
            </div>
          </div>
          <div
            className={`col-sm text-center ${
              value.inv_wh < value.min_wh && "min"
            } ${value.inv_wh > value.max_wh && "max"}`}
            style={{ padding: "5px" }}
          >
            <div style={{ backgroundColor: "green", color: "white" }}>
              โกดัง
            </div>
            <div className="flex-spa">
              <div className="range">{value.min_wh}</div>
              <b>{value.inv_wh}</b>
              <div className="range">{value.max_wh}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div>[ล่าง] {value.inv_wh2}</div>
              <div>[บน] {value.inv_wh - value.inv_wh2}</div>
            </div>
          </div>
        </div>
        {editing && (
          <div style={{ width: "100%" }}>
            <form
              // className="mcard-bodyclm-row"
              style={{
                backgroundColor: "#e6e6e6",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                padding: "5px",
              }}
              onSubmit={(e) => handleSubmitRange(e)}
            >
              <b>
                <u>ปรับ min-max</u>
              </b>

              <div className="row" style={{ margin: "0px" }}>
                <div
                  className="col-sm"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "lightgray",
                    borderRadius: "5px",
                    margin: "3px",
                    paddingBottom: "5px",
                  }}
                >
                  <div>
                    min โรงงาน
                    <input
                      className="form-control"
                      onChange={(e) => setMinFT(e.target.value)}
                      value={minFT}
                    />
                  </div>
                  <div>
                    max โรงงาน
                    <input
                      className="form-control"
                      onChange={(e) => setMaxFT(e.target.value)}
                      value={maxFT}
                    />
                  </div>
                </div>
                <div
                  className="col-sm"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "lightgray",
                    borderRadius: "5px",
                    margin: "3px",
                    paddingBottom: "5px",
                  }}
                >
                  <div>
                    min โกดัง
                    <input
                      className="form-control"
                      onChange={(e) => setMinWH(e.target.value)}
                      value={minWH}
                    />
                  </div>
                  <div>
                    max โกดัง
                    <input
                      className="form-control"
                      onChange={(e) => setMaxWH(e.target.value)}
                      value={maxWH}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="submit"
                  className="btn btn-secondary"
                  style={{ margin: "3px" }}
                >
                  ยืนยันแก้ไขข้อมูล
                </button>
              </div>
            </form>
            <div className="row" style={{ margin: "0px" }}>
              <form
                className="mcard-bodyclm-row col-md"
                style={{
                  backgroundColor: "#e6e6e6",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
                onSubmit={(e) => handleSubmit(e)}
              >
                <div style={{ width: "100%" }}>
                  <b>
                    <u>แก้ไขข้อมูล</u>
                  </b>
                  <div style={flexCol}>
                    <div
                      className="flexrow"
                      style={{
                        backgroundColor: "lightgray",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      <div>
                        น้ำหนัก
                        <input
                          className="form-control"
                          onChange={onChangeWeight}
                          value={weight}
                        />
                      </div>
                      <div>
                        อบ/เตา
                        <input
                          className="form-control"
                          onChange={onChangePieceOven}
                          value={pieceOven}
                        />
                      </div>
                      <div>
                        ราคา
                        <input
                          className="form-control"
                          onChange={onChangePrice}
                          value={price}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-secondary"
                      style={{ margin: "3px" }}
                    >
                      {auth.access["prodEdit"]
                        ? "ยืนยันแก้ไขข้อมูล"
                        : "แจ้งแก้ไขข้อมูล"}
                    </button>
                  </div>
                </div>
              </form>
              <form
                className="mcard-bodyclm-row col-md"
                style={{
                  backgroundColor: "#e6e6e6",
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
                onSubmit={(e) => handleSubmitAmount(e)}
              >
                <div style={{ width: "100%" }}>
                  <b>
                    <u>แก้ไขจำนวน</u>
                  </b>
                  <div style={flexCol}>
                    <div
                      className="flexrow"
                      style={{
                        backgroundColor: "lightgray",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      <div>
                        โรงงาน
                        <input
                          className="form-control"
                          onChange={onChangeFt}
                          value={ft}
                        />
                      </div>
                      <div>
                        โกดัง1
                        <input
                          className="form-control"
                          onChange={onChangeInv1}
                          value={wh1}
                        />
                      </div>
                      <div>
                        โกดัง2
                        <input
                          className="form-control"
                          onChange={onChangeInv2}
                          value={wh2}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-secondary"
                      style={{ margin: "3px" }}
                    >
                      {auth.access["prodEdit"]
                        ? "ยืนยันแก้ไขข้อมูล"
                        : "แจ้งแก้ไขข้อมูล"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withAuth(Card);
