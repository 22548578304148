import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MenuSub from "../../../Components/UI/MenuSub";
import InvSell from "./InvSell";
import InvSellHistory from "./InvSellHistory";

const InvSellRoute = ({ data, reload, match }) => {
  const link = [
    { name: "ขาย", to: "sell" },
    { name: "ประวัติขาย", to: "sell/history" },
  ];
  return (
    <div>
      <MenuSub match={match.path} link={link} />
      <Switch>
        <Route exact path={`/inventory/sell/`}>
          <InvSell data={data} reload={reload} />
        </Route>
        <Route path={`/inventory/sell/history`}>
          <InvSellHistory />
        </Route>
      </Switch>
    </div>
  );
};

export default InvSellRoute;
