import React from "react";

const CustData = ({ data, onClick }) => {
  return (
    <div className="cust">
      <div className="cust-body" style={{ flex: "0" }}>
        {data.custID}
      </div>
      <div className="cust-body" style={{ flex: "2 0" }}>
        {data.custName}
      </div>
      <div className="cust-body" style={{ flex: "2 0" }}>
        {data.address}
      </div>
      <div className="cust-body">{data.city}</div>
      <div className="cust-body-btn">
        <button
          className="btn btn-block btn-secondary"
          onClick={() => onClick(data)}
        >
          เลือก
        </button>
      </div>
    </div>
  );
};

export default CustData;
