import React, { useState, useEffect } from "react";
import { api } from "../../../api";

const InvBillSO = ({ match }) => {
  const { bill } = match.params;
  const [pdf, setpdf] = useState(false);
  const [blob, setBlob] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get(`/print/SO/${bill}`, { responseType: "arraybuffer" });
      setBlob(res.data);
    };
    fetchData();
  }, [bill]);

  useEffect(() => {
    if (blob) {
      var newblob = new Blob([blob], { type: "application/pdf" }),
        url = URL.createObjectURL(newblob);
      setpdf(url);
    }
  }, [blob]);

  return (
    <div style={{ flex: "1 0 0", height: "100%" }}>
      SellOrder New
      {/* {pdf && (
        <iframe
          id="pdfV"
          src={`${pdf}`}
          title="Bill"
          width="100%"
          height="800px"
        ></iframe>
      )} */}
      {pdf && (
        <object data={pdf} type="application/pdf" width="100%" height="1000px">
          <span>not compatible</span>
        </object>
      )}
    </div>
  );
};

export default InvBillSO;
