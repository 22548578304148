import React from "react";
import { useHistory, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HomeIcon from "@material-ui/icons/Home";

const Header = ({ name }) => {
  const history = useHistory();

  const middleStyle = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    flex: "1 0 0"
  };

  return (
    <div
      className="text-center"
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#b3b3b3",
        borderRadius: "20px",
        color: "black"
      }}
    >
      <div style={middleStyle}>
        <Button onClick={() => history.goBack()}>
          <ArrowBackIosIcon />
          BACK
        </Button>
      </div>
      <h2 style={{ flex: "5 0 0" }}>{name}</h2>
      <Link to={"/"} style={middleStyle}>
        <Button>
          <HomeIcon style={{ fill: "black" }} />
        </Button>
      </Link>
    </div>
  );
};

export default Header;
