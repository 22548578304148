import React from "react";
import { Link } from "react-router-dom";

const MonthlyMenu = ({ match, type }) => {
  return (
    <div className="btn-group">
      <Link
        to={`/management/monthly/${type}/${
          parseInt(match.params.m) === 1
            ? parseInt(match.params.y) - 1
            : match.params.y
        }/${
          parseInt(match.params.m) === 1 ? 12 : parseInt(match.params.m) - 1
        }`}
        className="btn btn-outline-info"
      >
        ←
      </Link>
      <Link
        to={`/management/monthly/sale/${match.params.y}/${match.params.m}`}
        className="btn btn-info"
      >
        การขาย
      </Link>
      <Link
        to={`/management/monthly/day/${match.params.y}/${match.params.m}`}
        className="btn btn-info"
      >
        การผลิต
      </Link>
      <Link
        to={`/management/monthly/extruding/${match.params.y}/${match.params.m}`}
        className="btn btn-info"
      >
        การฉีดใย
      </Link>

      <Link
        to={`/management/monthly/${type}/${
          parseInt(match.params.m) === 12
            ? parseInt(match.params.y) + 1
            : match.params.y
        }/${
          parseInt(match.params.m) === 12 ? 1 : parseInt(match.params.m) + 1
        }`}
        className="btn btn-outline-info"
      >
        →
      </Link>
    </div>
  );
};

export default MonthlyMenu;
