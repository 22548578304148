import React, { useState, useEffect } from "react";

import Card from "../Card";
import Cart from "../Cart";
import SearchProduct from "../../../Components/Others/SearchProduct";

import Dialog from "@material-ui/core/Dialog";

const InvSell = ({ data, reload }) => {
  const [search, setSearch] = useState(["", "", "", ""]);
  const [invI, setInvI] = useState(data);
  const [invF, setInvF] = useState(data);
  const [cart, setCart] = useState([]);
  const [isProdOpen, setProdOpen] = useState(false);

  useEffect(() => {
    // setInvI(data);
    // setInvF(data);
    const newData = data;
    setInvI(newData);
    setInvF(newData);

    return () => {
      const newData = [...data];
      for (let i = 0; i < newData.length; i++) {
        delete newData[i].amount;
      }
    };
  }, [data]);

  const handleOptionClick = (aa) => {
    const newCart = [...cart, aa];
    newCart[newCart.length - 1]["amount"] = 1;
    newCart[newCart.length - 1]["salePrice"] =
      newCart[newCart.length - 1].price;
    newCart[newCart.length - 1]["discount"] = 0;
    setCart(newCart);
  };

  const handleClickAmount = (op, idx) => {
    const newCart = [...cart];
    if (op === "+") {
      if (newCart[idx].amount < newCart[idx].inv_wh) {
        newCart[idx].amount += 1;
      }
    }
    if (op === "-") {
      if (newCart[idx].amount > 1) {
        newCart[idx].amount -= 1;
      }
    }

    setCart(newCart);
  };

  const handleOnAmountChange = (e, idx) => {
    const newCart = [...cart];
    if (!Number.isInteger(parseInt(e.target.value))) {
      newCart[idx].amount = 0;
    } else if (parseInt(e.target.value) > newCart[idx].inv_wh) {
      newCart[idx].amount = newCart[idx].inv_wh;
    } else {
      newCart[idx].amount = parseInt(e.target.value);
    }
    if (!Number.isInteger(parseInt(e.target.value))) {
      newCart[idx].amount = 0;
    }
    setCart(newCart);
  };

  const handleDelOnClick = (aa) => {
    const newCart = [...cart];
    delete newCart[aa].amount;
    newCart.splice(aa, 1);
    setCart(newCart);
  };

  const handleOnChangeD = (e, idx) => {
    const newCart = [...cart];
    if (!Number.isInteger(parseInt(e.target.value))) {
      newCart[idx].discount = 0;
      newCart[idx].salePrice = newCart[idx].price;
    } else {
      newCart[idx].discount = parseFloat(e.target.value);
      newCart[idx].salePrice =
        Math.round(
          100 * (newCart[idx].price * (1 - parseFloat(e.target.value) / 100))
        ) / 100;
    }

    setCart(newCart);
  };

  const handleOnChangeP = (e, idx) => {
    const newCart = [...cart];
    if (!Number.isInteger(parseInt(e.target.value))) {
      newCart[idx].salePrice = 0;
      newCart[idx].discount = 100;
    } else {
      newCart[idx].salePrice = e.target.value;
      newCart[idx].discount =
        Math.round(
          10000 *
            (100 - (100 * parseFloat(e.target.value)) / newCart[idx].price)
        ) / 10000;
    }

    setCart(newCart);
  };

  const handleOnClearClick = () => {
    setSearch(["", "", "", ""]);
  };

  const handleOnChange = (e, type) => {
    const newSearch = [...search];
    newSearch[type] = e.target.value;
    setSearch(newSearch);
  };

  const handleReload = () => {
    setCart([]);
    reload();
    setProdOpen(false);
  };

  useEffect(() => {
    const filtered = invI
      .filter((aa) => {
        return aa.A.toString().indexOf(search[0]) !== -1;
      })
      .filter((aa) => {
        return aa.B.toString().indexOf(search[1]) !== -1;
      })
      .filter((aa) => {
        return aa.C.toString().indexOf(search[2]) !== -1;
      })
      .filter((aa) => {
        return aa.D.toString().indexOf(search[3]) !== -1;
      });
    setInvF(filtered);
  }, [search, invI]);

  return (
    <div>
      {/* <button
        className={`btn btn-cart ${isProdOpen ? "opened" : ""}`}
        onClick={openCart}
      >
        <i className="fas fa-shopping-cart"> ตะกร้า</i>
      </button> */}
      {/* <Dialog
        onClose={() => setProdOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={isProdOpen}
        maxWidth="xl"
        // fullWidth
      >
        <Cart
          cart={cart}
          clickAmount={handleClickAmount}
          onChange={handleOnAmountChange}
          onChangeD={handleOnChangeD}
          onChangeP={handleOnChangeP}
          onClick={handleDelOnClick}
          onClose={openCart}
          reload={handleReload}
        />
      </Dialog> */}
      <Cart
        cart={cart}
        clickAmount={handleClickAmount}
        onChange={handleOnAmountChange}
        onChangeD={handleOnChangeD}
        onChangeP={handleOnChangeP}
        onClick={handleDelOnClick}
        reload={handleReload}
        onOpen={() => setProdOpen(true)}
      />
      <Dialog
        onClose={() => setProdOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={isProdOpen}
        maxWidth="xl"
        // fullWidth
      >
        <div className="modal-header">
          <h4 className="modal-title">ขายสินค้า</h4>
          <button
            onClick={() => setProdOpen(false)}
            type="button"
            className="close"
          >
            &times;
          </button>
        </div>

        <div className="modal-body">
          <SearchProduct
            onChange={handleOnChange}
            onClick={handleOnClearClick}
            value={search}
          />

          <div className="cardContainer ">
            <div className="mcard">
              <div
                className="mcard-body"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "1rem",
                }}
              >
                <div className="mcard-body-left">
                  <b>รายการ</b>
                </div>
                <div className="mcard-body-price text-center">
                  <b>จำนวน</b>
                </div>
                <div className="mcard-body-else">
                  <b></b>
                </div>
              </div>
            </div>
            {invF.map((data) => (
              <Card
                key={data.prodID}
                value={data}
                onClick={handleOptionClick}
              />
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default InvSell;
