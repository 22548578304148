import React, { useState } from "react";
import CardName from "../Components/CardName";

const InvBillDT = ({ data, detail }) => {
  return (
    <div className="flex flex1">
      <div>
        <div>ลูกค้า: {data.custName}</div>
        <div>
          ที่อยู่: {data.address} {data.city} {data.postalCode}
        </div>
        <div>เบอร์โทร: {data.custTel}</div>
      </div>
      <div>
        <b>รายการขาย</b>
        {detail.map((row, idx) => (
          <div className="flexrow flex-spa" key={`dt${idx}`}>
            <div>
              <CardName data={row} />
            </div>
            <div>{row.sale_price}บ./ชิ้น</div>
            <div>{row.quantity} ชิ้น</div>
            <div>{parseFloat(row.sale_price) * parseInt(row.quantity)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvBillDT;
