import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

//components
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import Inventory from "./Pages/Inventory/Inventory";
import ProtectedRoute from "./Components/System/ProtectedRoute";
import Management from "./Pages/Management/Management";
import Customer from "./Pages/Customer/Customer";
import Extruding from "./Pages/Department/Extruding/Extruding";
import Weaving from "./Pages/Department/Weaving/Weaving";
import Material from "./Pages/Material/Material";
import User from "./Pages/User/User";
import { AuthContext } from "./AuthContext";
import { api } from "./api";

function App() {
  const [auth, setAuth] = React.useState({
    level: 0,
    user: "",
    authenticating: true,
    isAuthenticated: false,
  });
  const fetchAuthState = async () => {
    const { status, data } = await api.get("/user/me");
    if (status === 200) {
      setAuth({
        authenticating: false,
        isAuthenticated: true,
        level: data.level,
        user: data.user,
        access: data.access,
      });
    } else {
      // console.info("status: ", status);
      setAuth((prev) => ({ ...prev, authenticating: false }));
    }
  };

  useEffect(() => {
    fetchAuthState();
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      {auth.authenticating ? (
        "กำลังเชื่อมต่อ"
      ) : (
        <Router>
          <div>
            <Route
              path="/login"
              render={(p) => <Login {...p} setAuth={setAuth} />}
            />
            <div style={{ paddingBottom: "58px" }}></div>
            <ProtectedRoute exact path="/" component={Home} />
            <ProtectedRoute path="/user" component={User} />
            <ProtectedRoute
              access="inv"
              path="/inventory"
              component={Inventory}
            />
            <ProtectedRoute
              access="cust"
              path="/customer"
              component={Customer}
            />
            <ProtectedRoute
              access="mnm"
              path="/management"
              component={Management}
            />
            <ProtectedRoute
              access="prodExtd"
              path="/dept/extruding"
              component={Extruding}
            />
            <ProtectedRoute
              access="prodWeav"
              path="/dept/weaving"
              component={Weaving}
            />
            <ProtectedRoute
              access="mat"
              path="/material"
              component={Material}
            />
          </div>
        </Router>
      )}
    </AuthContext.Provider>
  );
}

export default App;

console.info(process.env.REACT_APP_API_BASE_URL);
