import React, { useState, useEffect } from "react";
import ChooseProduct from "../../../Components/Others/ChooseProduct";
import DatePicker from "react-datepicker";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardName from "../Components/CardName";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { api } from "../../../api";
import { withAuth } from "../../../AuthContext";

const InvAdjustValue = ({ auth, data }) => {
  const [open, setOpen] = useState(false);
  const [adjustData, setAdjustData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [search, setSearch] = useState(["", "", "", ""]);
  const [filtered, setFiltered] = useState([]);
  const [remark, setRemark] = useState("");
  const [adjusting, setAdjusting] = useState([]);
  const [adjValue, setAdjValue] = useState([null, null, null]);
  const [editing, setEditing] = useState("new");
  const handleClickSubmit = () => {
    const submitData = {
      data: {
        remark: remark,
        submitter: auth.user,
      },
      detail: adjustData.map((row) => {
        return { prodID: row.data.prodID, value: row.value };
      }),
    };
    if (auth.level === "admin") {
      api.post("/inv/adjustAmount", submitData).then((xx) => {
        if (xx.data.success) {
          alert(xx.data.result);
        } else {
          alert(xx.data.error);
        }
        // reload();
      });
    } else {
      const post = {
        name: "Edit Product",
        query: "/inv/adjustAmount",
        data: submitData,
        user: auth.user,
      };
      api.post("/request", post).then((xx) => {
        alert(xx.data.result);
      });
    }
    setOpen(false);
    setAdjustData([]);
    setDialogOpen(false);
    setRemark("");
    setAdjusting([]);
    setAdjValue([null, null, null]);
    setEditing("new");
  };
  const handleOnChange = (e, type) => {
    const newSearch = [...search];
    newSearch[type] = e.target.value;
    setSearch(newSearch);
  };
  const handleClearSearch = () => {
    setSearch(["", "", "", ""]);
  };
  const handleAdd = (value) => {
    setDialogOpen(true);
    setAdjusting(value);
    setEditing("new");
  };
  const handleEdit = (value, adjValue, idx) => {
    setDialogOpen(true);
    setAdjusting(value);
    setAdjValue(adjValue);
    setEditing(idx);
  };
  const handleSubmitAdjust = (data, value) => {
    if (value[0] === null && value[1] === null && value[2] === null)
      return alert("ไม่มีการเปลี่ยนแปลง");
    const newData = [...adjustData];
    data.nmove = 1;
    if (editing === "new") {
      newData.push({ data: data, value: value });
    } else {
      newData[editing] = { data: data, value: value };
    }
    setAdjustData(newData);
    setDialogOpen(false);
  };
  const handleDelete = (idx) => {
    delete adjustData[idx].data.nmove;
    const newData = [...adjustData];
    newData.splice(idx, 1);
    setAdjustData(newData);
  };
  useEffect(() => {
    const newFiltered = data
      .filter((aa) => {
        return aa.A.toString().indexOf(search[0]) !== -1;
      })
      .filter((aa) => {
        return aa.B.toString().indexOf(search[1]) !== -1;
      })
      .filter((aa) => {
        return aa.C.toString().indexOf(search[2]) !== -1;
      })
      .filter((aa) => {
        return aa.D.toString().indexOf(search[3]) !== -1;
      });
    setFiltered(newFiltered);
  }, [search, data]);
  useEffect(() => {
    const newData = [...data];
    for (let i = 0; i < newData.length; i++) {
      delete newData[i].nmove;
    }
  }, [data]);
  return (
    <div>
      <AdjustDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={handleSubmitAdjust}
        data={adjusting}
        adjValue={adjValue}
        setAdjValue={setAdjValue}
      />
      <button
        className="btn btn-block btn-info"
        onClick={() => setOpen((prev) => !prev)}
      >
        จำนวนรายการสินค้า: {adjustData.length} รายการ
      </button>
      {open && (
        <div
          style={{
            padding: "10px",
            margin: "0px 5px 5px 5px",
            marginBottom: "10px",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            backgroundColor: "rgb(240, 240, 240)",
          }}
        >
          {adjustData.map((row, idx) => {
            return (
              <div
                key={`adjustData-${idx}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <CardName data={row.data} />
                </div>
                <div className="flex1">
                  {row.value[0] && (
                    <AdjustRow
                      name="โรงงาน"
                      prev={row.data.inv_ft}
                      data={row.value[0]}
                    />
                  )}
                  {row.value[1] && (
                    <AdjustRow
                      name="โกดัง1"
                      prev={row.data.inv_wh - row.data.inv_wh2}
                      data={row.value[1]}
                    />
                  )}
                  {row.value[2] && (
                    <AdjustRow
                      name="โกดัง2"
                      prev={row.data.inv_wh2}
                      data={row.value[2]}
                    />
                  )}
                </div>
                <div className="form-group">
                  <Button
                    className="mgh5"
                    variant="contained"
                    color="default"
                    startIcon={<EditIcon />}
                    onClick={() => handleEdit(row.data, row.value, idx)}
                  >
                    แก้ไข
                  </Button>
                  <Button
                    className="mgh5"
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDelete(idx)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            );
          })}
          <div>
            <TextField
              style={{ backgroundColor: "white", borderRadius: "5px" }}
              margin="dense"
              label="หมายเหตุ"
              type="text"
              InputProps={{ inputProps: { min: 0 } }}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              value={remark}
              fullWidth
            />
          </div>
          <button
            className="btn btn-block btn-success"
            onClick={handleClickSubmit}
          >
            {auth.level === "admin" ? "ยืนยัน" : "ส่งคำขอแก้ไข"}
          </button>
        </div>
      )}
      <ChooseProduct
        onChange={handleOnChange}
        onClearSearch={handleClearSearch}
        search={search}
        onClickAdd={handleAdd}
        filtered={filtered}
      />
    </div>
  );
};

const AdjustDialog = ({
  open,
  onClose,
  onSubmit,
  data,
  adjValue,
  setAdjValue,
}) => {
  const handleAdjust = (idx, value) => {
    const newValue = [...adjValue];
    newValue[idx] = value;
    setAdjValue(newValue);
  };
  const handleDelete = (idx) => {
    const newValue = [...adjValue];
    newValue[idx] = null;
    setAdjValue(newValue);
  };
  const clearData = () => {
    setAdjValue([null, null, null]);
  };
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">ปรับรายการ</DialogTitle>
      <DialogContent>
        <CardName data={data} />
        <div className="flexrow">
          <AdjustBlock
            name={"โรงงาน"}
            data={data.inv_ft}
            onSubmit={(v) => handleAdjust(0, v)}
            onDelete={() => handleDelete(0)}
            adjValue={adjValue[0]}
          />
          <AdjustBlock
            name={"โกดัง1"}
            data={data.inv_wh - data.inv_wh2}
            onSubmit={(v) => handleAdjust(1, v)}
            onDelete={() => handleDelete(1)}
            adjValue={adjValue[1]}
          />
          <AdjustBlock
            name={"โกดัง2"}
            data={data.inv_wh2}
            onSubmit={(v) => handleAdjust(2, v)}
            onDelete={() => handleDelete(2)}
            adjValue={adjValue[2]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          ยกเลิก
        </Button>
        <Button
          onClick={() => {
            onSubmit(data, adjValue);
            clearData();
          }}
          color="primary"
        >
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AdjustBlock = ({ data, name, onSubmit, adjValue, onDelete }) => {
  const [value, setValue] = useState("");
  const [choosed, setChoose] = useState(false);
  const [adj, setAdj] = useState(false);
  const [newValue, setNewValue] = useState(data);
  useEffect(() => {
    const add = (value) => {
      if (isNaN(value)) return data;
      return data + value;
    };
    const sub = (value) => {
      if (isNaN(value)) return data;
      return data - value;
    };
    const vv = parseInt(value);

    if (adj === "in") {
      setNewValue(add(vv));
    } else if (adj === "out") {
      setNewValue(sub(vv));
    }
  }, [data, adj, value]);
  const handleClick = (type) => {
    setChoose(true);
    setAdj(type);
  };
  const onClickSubmit = () => {
    setChoose(false);
    onSubmit([adj, value, newValue]);
  };
  const onClickBack = () => {
    setChoose(false);
    setValue("");
  };
  return (
    <form
      className="flex1 bd textcenter flexcol"
      onSubmit={(e) => {
        e.preventDefault();
        onClickSubmit();
      }}
    >
      {name}
      <DialogContentText style={{ backgroundColor: "white" }}>
        {data}
      </DialogContentText>
      {!choosed ? (
        !adjValue ? (
          <div>
            <button className="btn adj-btn" onClick={() => handleClick("in")}>
              เพิ่ม
            </button>
            <button className="btn adj-btn" onClick={() => handleClick("out")}>
              ลด
            </button>
          </div>
        ) : (
          <div>
            <div>
              ({adjValue[0] === "in" ? "+" : "-"}
              {adjValue[1]})
            </div>
            <div>{adjValue[2]}</div>
            <div style={{ color: "red" }} onClick={onDelete}>
              ยกเลิก
            </div>
          </div>
        )
      ) : (
        <div style={{ backgroundColor: "lightgrey" }}>
          {adj === "in"}
          <TextField
            style={{ backgroundColor: "white" }}
            autoFocus
            margin="dense"
            label="จำนวน"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            onChange={(e) => {
              const vv = parseInt(e.target.value);
              setValue(isNaN(vv) ? 0 : vv);
            }}
            value={value === 0 ? "" : value}
            // variant="filled"
          />
          <DialogContentText>{newValue}</DialogContentText>
          <button
            className="btn btn-success"
            style={{ width: "100%", borderRadius: "0px" }}
            onClick={onClickSubmit}
          >
            ยืนยัน
          </button>
          <div
            style={{ width: "100%", borderRadius: "0px", color: "black" }}
            onClick={onClickBack}
          >
            ยกเลิก
          </div>
        </div>
      )}
    </form>
  );
};
export default withAuth(InvAdjustValue);

const AdjustRow = ({ name, prev, data }) => {
  return (
    <div className="flexrow">
      <div className="flex1 textcenter">{name}</div>
      <div className="flex1 textcenter">
        [{prev + (data[0] === "in" ? "+" : "-") + data[1]}]
      </div>
      <div className="flex1 textcenter">{data[2]}</div>
    </div>
  );
};
