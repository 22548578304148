import React, { useState } from "react";
import ReceivedFilament from "../ExtdData/ReceivedFilament";
import Button from "@material-ui/core/Button";
import ExtdDetail from "../ExtdData/ExtdDetail";

const ExtdDataRow = ({ row, idx, reload }) => {
  const [open, setOpen] = useState(false);

  const round = num => {
    return Math.round(num * 100) / 100;
  };

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const filamentPercent = (row.totalFilament / row.totalPlastic) * 100 + "%";
  const WastePercent = (row.totalWaste / row.totalPlastic) * 100 + "%";

  return (
    <div
      key={`extd-${idx}`}
      className="row"
      style={{
        backgroundColor: "#e6e6e6",
        margin: "5px",
        padding: "5px",
        borderRadius: "5px"
      }}
    >
      <div className="col-9">
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div style={{ flex: "1 0 0", textAlign: "center" }}>
                No: {row.extrudeID}
              </div>
              <div style={{ flex: "2 0 0", textAlign: "center" }}>
                {row.extrudeDate}
              </div>
              <div style={{ flex: "1 0 0", textAlign: "center" }}>
                ใย: {row.extrudeSize}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <Button
                className="col"
                style={{
                  textAlign: "center",
                  margin: "3px",
                  backgroundColor: "#d9d9d9",
                  border: "1px solid #b3b3b3"
                }}
              >
                {`ใยที่ใช้: ${row.totalPlastic}`}
              </Button>
              <ReceivedFilament
                totalReceived={row.totalReceived}
                totalWaste={row.totalWaste}
                totalFilament={row.totalFilament}
              />
              <div className="col-12">
                <div
                  className="progress"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid grey"
                  }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: filamentPercent }}
                    aria-valuenow="15"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    className="progress-bar bg-danger"
                    role="progressbar"
                    style={{ width: WastePercent }}
                    aria-valuenow="20"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-3"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
          backgroundColor: "#8ebcde",
          borderRadius: "5px"
        }}
      >
        <div className="row">
          <div className="col-md-6" style={{ padding: "0 .75rem" }}>
            {round((row.totalReceived / row.totalPlastic) * 100)}%
          </div>
          <Button
            variant="contained"
            style={{
              border: "1px solid #4d95cb"
            }}
            onClick={handleClick}
          >
            ดูข้อมูล
          </Button>
        </div>
      </div>
      <div className="col-12">
        <div>
          {row.remark ? <b>หมายเหตุ </b> : ""}
          {row.remark}
        </div>
      </div>
      {open && (
        <div className="col-12" style={{ padding: "0px" }}>
          <ExtdDetail extrudeID={row.extrudeID} reload={reload} />
        </div>
      )}
    </div>
  );
};

export default ExtdDataRow;
