import React, { useState, useEffect } from "react";
import { api } from "../../api";

const MatPlastic = () => {
  const [plastic, setPlastic] = useState([]);

  const fetchData = async () => {
    const res = await api.get("/mat/listPlastic");
    setPlastic(res.data.result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <table className="table table-striped table-condensed">
          <thead className="thead-light">
            <tr>
              <th>ชื่อพลาสติก</th>
              <th>ชนิด</th>
              <th>จำนวนถุง</th>
              <th>น้ำหนัก</th>
            </tr>
          </thead>
          <tbody>
            {plastic.map((row, idx) => (
              <tr key={`plas-${row.plasticID}`}>
                <td>{row.plasticName}</td>
                <td>{row.plasticType}</td>
                <td>{row.amountBag}</td>
                <td>{row.amountKg}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatPlastic;
