import React, { useState } from "react";
import { Link } from "react-router-dom";

import Collapse from "@material-ui/core/Collapse";

const PatchNote = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="tb">
      <Collapse in={open} collapsedHeight={100}>
        <h4>Patch note: </h4>
        [11/12/64] อัพเดทการย้ายสินค้ากลับคลัง1 <br />
        [18/11/64] อัพเดท ui/ux การขาย <br />
        [8/7/64] เพิ่มดูน้ำหนักในค้นประวัติการขายเป็นช่วง <br />
        [10/6/64] เพิ่มค้นประวัติการขายเป็นช่วง <br />
        [9/1/64] เพิ่มรายงานมีเตอร์แยกเครื่อง <br />
        [30/10/63] เพิ่มวิเคราะห์การขาย <br />
        [27/19/63] เพิ่มการดูยอดขายย้อนหลัง <br />
        [14/10/63] เพิ่มการดูจำนวนทอจากมีเตอร์แบบระบุวัน <br />
        [30/9/63] แก้ไขปุ่มตัวเลือกในแต่ละหน้า <br />
        [29/9/63] เพิ่ม cookie (เปิดใหม่แล้วไม่ต้อง login ใหม่) <br />
        [12/9/63] UI แผนกทอ <br />
        [9/9/63] เครื่องมือวิเคราะห์การขาย <br />
        [8/9/63] ระบบเปลี่ยนรหัสผ่านแบบเข้ารหัส{" "}
        <Link to="/user/changepassword">เปลี่ยนรหัสผ่าน</Link>
        <br />
        [15/8/63] เพิ่มการค้นหารายชื่อลูกค้า
        <br />
        [7/6/63] แก้บัคเพิ่มลูกค้าหน้าขาย
        <br />
        [1/6/63] ปรับปรุงหน้าขาย, แก้ปัญหาการแสดงผลเมนูบน, ย่อ patch note
        <br />
        [16/5/63] ปรับปรุงหน้าเพิ่มมิเตอร์ทอ
        <br />
        [10/5/63] รายการกำลังผลิต <Link to="/dept/weaving">รายการทอ</Link> ,
        แก้บัคหลายการแสดงผล
        <br />
        [9/5/63] ปรับปรุงหน้าแรกและแก้ไขบัค menu ด้านบน
        <br />
        [7/5/63] อัพเดทระบบ max-min, ดาวน์โหลด xlsx
        <br />
        [4/5/63] อัพเดทระบบปรับจำนวนสินค้า{" "}
        <Link to="/inventory/Adjust">ปรับสินค้า</Link>
        <br />
        [18/2/63] อัพเดท interface ลูกค้า ฟังก์ชั่นแก้ไข+เพิ่ม+ดูประวัติซื้อ
      </Collapse>
      <button
        style={{ backgroundColor: "lightgray", margin: "auto", padding: "0" }}
        className="btn btn-block"
        onClick={() => setOpen((prev) => !prev)}
      >
        กดเพื่อขยาย/ย่อ
      </button>
    </div>
  );
};

export default PatchNote;
