import React from "react";
import { Route, Link } from "react-router-dom";
import "./InvBill.css";
import InvBillSO from "./InvBillSO";
import InvBillIV from "./InvBillIV";
import InvBillRE from "./InvBillRE";
import InvBillDT from "./InvBillDT";
import { useState, useEffect } from "react";
import { api } from "../../../api";

const InvBill = ({ match }) => {
  const { bill } = match.params;
  const [data, setData] = useState([]);
  const [detail, setDetail] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const resData = await api.post("/inv/listOrder", {
        orderID: bill,
      });
      const resDetail = await api.post("/inv/listOrderDetail", {
        orderID: bill,
      });
      setData(resData.data[0]); //setState
      setDetail(resDetail.data); //setState
    };

    fetchData();
  }, [bill]);
  return (
    <div className="page">
      {/* menu */}
      <div className="menu">
        <Link to={`${match.url}`} className="link">
          <button className="m-btn">รายละเอียด</button>
        </Link>
        <Link to={`${match.url}/SO`} className="link">
          <button className="m-btn">ใบสั่งขาย</button>
        </Link>
        <Link to={`${match.url}/IV`} className="link">
          <button className="m-btn">ใบส่งสินค้า</button>
        </Link>
        <Link to={`${match.url}/RE`} className="link">
          <button className="m-btn">ใบเสร็จ</button>
        </Link>
      </div>
      <div className="cont">
        <Route
          exact
          path={`${match.path}`}
          component={() => <InvBillDT data={data} detail={detail} />}
        />
        <Route
          path={`${match.path}/SO`}
          component={() => (
            <InvBillSO data={data} detail={detail} match={match} />
          )}
        />
        <Route
          path={`${match.path}/IV`}
          component={() => <InvBillIV data={data} detail={detail} />}
        />
        <Route
          path={`${match.path}/RE`}
          component={() => <InvBillRE data={data} detail={detail} />}
        />
      </div>
    </div>
  );
};

export default InvBill;
