import React from "react";

const SearchBar = ({ onClick, onChange, value }) => {
  return (
    <div
      className="text-center"
      style={{
        backgroundColor: "lightgrey",
        padding: "5px",
        borderRadius: "3px"
      }}
    >
      <h4>ค้นหาสินค้า</h4>
      <div className="input-group">
        {/* <div className="input-group-prepend">
          <span className="input-group-text">ค้นหา</span>
        </div> */}
        <input
          className="form-control"
          onChange={e => onChange(e, 0)}
          placeholder="ขนาดใย"
          value={value[0]}
          pattern="\d*"
        />
        <input
          className="form-control"
          onChange={e => onChange(e, 1)}
          placeholder="ขนาดตา"
          value={value[1]}
          pattern="\d*"
        />
        <input
          className="form-control"
          onChange={e => onChange(e, 2)}
          placeholder="จำนวนตา"
          value={value[2]}
          pattern="\d*"
        />
        <input
          className="form-control"
          onChange={e => onChange(e, 3)}
          placeholder="ความยาว"
          value={value[3]}
          pattern="\d*"
        />
        <div className="input-group-append">
          <button className="btn btn-secondary " onClick={onClick}>
            ล้างข้อมูล
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
