import React, { useState } from "react";
import { api } from "../../../api";

const CustAdding = ({ afterSubmit, onClickCancel }) => {
  const [custName, setCustName] = useState("");
  const [custTel, setCustTel] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [detail, setDetail] = useState("");
  const changeAddress = (e) => {
    setAddress(e.target.value);
  };
  const changeCity = (e) => {
    setCity(e.target.value);
  };
  const changeCustName = (e) => {
    setCustName(e.target.value);
  };
  const changeCustTel = (e) => {
    setCustTel(e.target.value);
  };
  const changePostalCode = (e) => {
    setPostalCode(e.target.value);
  };
  const changeLat = (e) => {
    setLat(e.target.value);
  };
  const changeLng = (e) => {
    setLng(e.target.value);
  };
  const changeDetail = (e) => {
    setDetail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const custData = {
      custName: custName,
      address: address,
      city: city,
      postalCode: postalCode,
      custTel: custTel,
      lat: lat,
      lng: lng,
    };
    api.post("/cust/newCust", custData).then((xx) => {
      if (xx.data.success) {
        alert(xx.data.result);
        clearData();
        afterSubmit(xx.data.custID, custData);
      } else alert(JSON.parse(xx.data.error).sqlMessage);
    });
  };
  const clearData = () => {
    setCustName("");
    setCustTel("");
    setAddress("");
    setCity("");
    setPostalCode("");
    setLat("");
    setLng("");
    setDetail("");
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      style={{
        backgroundColor: "rgb(240, 240, 240)",
        padding: "5px",
        // marginBottom: "10px",
      }}
    >
      <div className="form-row">
        <div className="form-group col-md">
          ชื่อลูกค้า
          <input
            type="text"
            className="form-control"
            onChange={(e) => changeCustName(e)}
            value={custName}
            required
          />
        </div>
        <div className="form-group col-md-4">
          เบอร์โทร
          <input
            type="text"
            className="form-control"
            onChange={(e) => changeCustTel(e)}
            value={custTel}
          />
        </div>
      </div>
      <div className="form-row">
        ที่อยู่
        <textarea
          className="form-control"
          rows="2"
          onChange={(e) => changeAddress(e)}
          value={address}
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-5">
          จังหวัด{" "}
          <input
            className="form-control"
            onChange={(e) => changeCity(e)}
            value={city}
          />
        </div>
        <div className="form-group col-md-3">
          รหัสไปรษณีย์{" "}
          <input
            className="form-control"
            onChange={(e) => changePostalCode(e)}
            value={postalCode}
          />
        </div>
        <div className="form-group col-md">
          ตำแหน่ง
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="ละติจูด"
              onChange={(e) => changeLat(e)}
              value={lat}
            />
            <input
              type="text"
              className="form-control"
              placeholder="ลองจิจูด"
              onChange={(e) => changeLng(e)}
              value={lng}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md">
          รายละเอียดเพิ่มเติม
          <input
            type="text"
            className="form-control"
            onChange={(e) => changeDetail(e)}
            value={detail}
          />
        </div>
      </div>
      <div className="form-row" style={{ justifyContent: "center" }}>
        <button
          type="button"
          onClick={onClickCancel}
          className="btn btn-danger"
        >
          ยกเลิก
        </button>
        <button type="submit" className="btn btn-success">
          ยืนยันเพิ่มข้อมูลลูกค้า
        </button>
      </div>
    </form>
  );
};

export default CustAdding;
