import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import InvImportTable from "./Table/InvImportTable";
import { api } from "../../api";
import ImportDataRow from "./InvImports/ImportDataRow";

const InvImports = () => {
  const { source } = useParams();
  const [imports, setImports] = useState([]);
  const fetchDataFT = async () => {
    await api
      .get("/inv/listImportFT")
      .then((xx) => {
        setImports(xx.data);
      })
      .catch((err) => console.error(err));
  };
  const fetchDataFTWH1 = async () => {
    await api
      .get("/inv/listImportFTWH1")
      .then((xx) => {
        setImports(xx.data);
      })
      .catch((err) => console.error(err));
  };
  const fetchDataWH1WH2 = async () => {
    await api
      .get("/inv/listImportWH1WH2")
      .then((xx) => {
        setImports(xx.data);
      })
      .catch((err) => console.error(err));
  };
  const fetchDataWH2WH1 = async () => {
    await api
      .get("/inv/listImportWH2WH1")
      .then((xx) => {
        console.log(xx.data);
        setImports(xx.data);
      })
      .catch((err) => console.error(err));
  };
  const fetchData = () => {
    if (source === "inFT") {
      fetchDataFT();
    } else if (source === "FTWH1") {
      fetchDataFTWH1();
    } else if (source === "WH1WH2") {
      fetchDataWH1WH2();
    } else if (source === "WH2WH1") {
      fetchDataWH2WH1();
    }
  };

  useEffect(() => {
    fetchData();
  }, [source]);

  return (
    <div>
      <main className="container">
        <div
          style={{
            backgroundColor: "rgb(197, 197, 197)",
            padding: "5px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ flex: "1 0 0", textAlign: "center" }}>หมายเลข</div>
            <div style={{ flex: "2 0 0", textAlign: "center" }}>
              วันที่ส่งข้อมูล
            </div>
            <div style={{ flex: "1 0 0", textAlign: "center" }}>
              ผู้ลงข้อมูล
            </div>
            <div style={{ flex: "3 0 0", textAlign: "center" }}>ตัวเลือก</div>
          </div>
          <InFTData data={imports} source={source} />
        </div>
      </main>
    </div>
  );
};

const InFTData = ({ data, source }) => {
  const handleClickDeleteData = () => {};
  return (
    <div>
      {data.map((row, idx) => (
        <ImportDataRow
          key={`data-${source}-${idx}`}
          data={row}
          handleClickDeleteData={handleClickDeleteData}
          source={source}
        />
      ))}
    </div>
  );
};
export default InvImports;
