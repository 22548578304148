import React, { useState, useEffect } from "react";

import Header from "../../Components/Header/Header";
import { api } from "../../api";

import Dehaze from "@material-ui/icons/Dehaze";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";

import { Link } from "react-router-dom";

const CustMain = ({ match }) => {
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");

  const fetchData = async () => {
    const res = await api.get("/cust/listCust/");
    setData(
      res.data.data.map((aa) => {
        if (aa.city === null) aa.city = "";
        return aa;
      })
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const newFiltered = data
      .filter((aa) => {
        return aa.custName.indexOf(name) !== -1;
      })
      .filter((aa) => {
        return aa.city.indexOf(city) !== -1;
      });
    setFiltered(newFiltered);
  }, [name, city, data]);

  const handleSubmit = async (newData) => {
    const res = await api.post("/cust/newCust", newData);
    if (res.data.success) {
      alert(res.data.result);
      fetchData();
      setOpen(false);
    } else {
      alert(res.data.error);
    }
  };

  return (
    <div>
      <button
        className="btn btn-secondary flexrow"
        onClick={() => setOpen(true)}
        style={{ margin: "5px" }}
      >
        <AddIcon />
        <div className="center">เพิ่มลูกค้า</div>
      </button>
      <div
        className="text-center"
        style={{
          backgroundColor: "lightgrey",
          padding: "5px",
          borderRadius: "3px",
        }}
      >
        <h4>ค้นหาลูกค้า</h4>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">ค้นหา</span>
          </div>
          <input
            className="form-control"
            onChange={(e) => setName(e.target.value)}
            placeholder="ชื่อ"
            value={name}
          />
          <input
            className="form-control"
            onChange={(e) => setCity(e.target.value)}
            placeholder="จังหวัด"
            value={city}
          />
          <button
            className="btn btn-secondary"
            onClick={() => {
              setName("");
              setCity("");
            }}
          >
            ล้างข้อมูล
          </button>
        </div>
      </div>
      <main className="tb">
        <CustDialog
          title={"เพิ่ม"}
          open={open}
          onClose={() => setOpen(false)}
          data={data}
          onSubmit={handleSubmit}
        />
        <div className="table-head">
          <div className="textcenter flex1">รหัสลูกค้า</div>
          <div className="textcenter flex3">ชื่อลูกค้า</div>
          <div className="textcenter flex1">จังหวัด</div>
          <div className="textcenter foption"></div>
        </div>

        {filtered.map((row, idx) => (
          <CustomerData
            data={row}
            key={`custData-${idx}`}
            fetchData={fetchData}
          />
        ))}
      </main>
    </div>
  );
};

const CustomerData = ({ data, fetchData }) => {
  const [open, setOpen] = useState(false);
  const [openSub, setOpenSub] = useState(false);

  const handleSubmit = async (newData) => {
    const res = await api.post("/cust/updateCust", newData);
    if (res.data.success) {
      alert(res.data.result);
      fetchData();
      setOpen(false);
    } else {
      alert(res.data.error);
    }
  };

  return (
    <div className="table-body">
      <div className="flexrow">
        <div className="flex1 center">{data.custID}</div>
        <div className="flex3 mg">{data.custName}</div>
        <div className="flex1 center">{data.city}</div>
        <button
          className="btn btn-secondary foption cc"
          onClick={() => setOpenSub((prev) => !prev)}
        >
          <Dehaze />
        </button>
      </div>
      {openSub && (
        <div className="table-sub">
          <div className="flex1">ที่อยู่: {data.address}</div>
          {data.custTel && (
            <div className="flex1">เบอร์โทร: {data.custTel}</div>
          )}
          <div className="flexrow">
            {data.lat && data.lng ? (
              <button className="btn btn-secondary flex1">เปิดแผนที่</button>
            ) : (
              ""
            )}
            <button
              className="btn btn-secondary flex1"
              onClick={() => setOpen(true)}
            >
              ดูข้อมูล/แก้ไข
            </button>
            <CustDialog
              title={"แก้ไข"}
              open={open}
              onClose={() => setOpen(false)}
              data={data}
              onSubmit={handleSubmit}
            />
            <Link
              className="btn btn-secondary flex1"
              to={`/customer/${data.custID}`}
            >
              ประวัติซื้อ
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

function CustDialog(props) {
  const { onSubmit, onClose, open, data, title } = props;
  const [custName, changeCustName] = useState(
    data.custName ? data.custName : ""
  );
  const [custType, changeCustType] = useState(1);
  const [creditDays, changeCreditDays] = useState(0);
  const [creditAmount, changeCreditAmount] = useState(0);
  const [custTel, changeCustTel] = useState(data.custTel ? data.custTel : "");
  const [address, changeAddress] = useState(data.address ? data.address : "");
  const [city, changeCity] = useState(data.city ? data.city : "");
  const [postalCode, changePostalCode] = useState(
    data.postalCode ? data.postalCode : ""
  );
  const [lat, changeLat] = useState(data.lat ? data.lat : "");
  const [lng, changeLng] = useState(data.lng ? data.lng : "");
  const [detail, changeDetail] = useState(data.detail ? data.detail : "");

  const submitData = () => {
    const { custID } = data;
    const newData = {
      custName,
      custTel,
      address,
      city,
      postalCode,
      lat,
      lng,
      detail,
      custID,
    };
    onSubmit(newData);
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{title}ข้อมูลลูกค้า</DialogTitle>
      <div
        // onSubmit={e => handleSubmit(e.targetvalue)}
        style={{
          backgroundColor: "rgb(240, 240, 240)",
          padding: "20px",
          marginBottom: "10px",
        }}
      >
        <div className="form-row"></div>
        <div className="form-group col-md">
          ประเภทลูกค้า
          <select
            className="form-control"
            value={custType}
            onChange={(e) => changeCustType(e.target.value)}
          >
            <option value="1">บุคคลธรรมดา</option>
            <option value="2">นิติบุคคล</option>
          </select>
        </div>
        <div className="form-row">
          <div className="form-inline col-md">
            เครดิต:{" "}
            <input
              className="form-control col-xs-2"
              value={creditDays}
              onChange={(e) => changeCreditDays(e.target.value)}
            />{" "}
            วัน
          </div>
          <div className="form-inline col-md">
            วงเงิน{" "}
            <input
              className="form-control col-xs-2"
              value={creditAmount}
              onChange={(e) => changeCreditAmount(e.target.value)}
            />{" "}
            บาท
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md">
            ชื่อลูกค้า
            <input
              type="text"
              className="form-control"
              onChange={(e) => changeCustName(e.target.value)}
              value={custName}
              required
            />
          </div>
          <div className="form-group col-md-4">
            เบอร์โทร
            <input
              type="text"
              className="form-control"
              onChange={(e) => changeCustTel(e.target.value)}
              value={custTel}
            />
          </div>
        </div>
        <div className="form-row">
          ที่อยู่
          <textarea
            className="form-control"
            rows="2"
            onChange={(e) => changeAddress(e.target.value)}
            value={address}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-5">
            จังหวัด{" "}
            <input
              className="form-control"
              onChange={(e) => changeCity(e.target.value)}
              value={city}
            />
          </div>
          <div className="form-group col-md-3">
            รหัสไปรษณีย์{" "}
            <input
              className="form-control"
              onChange={(e) => changePostalCode(e.target.value)}
              value={postalCode}
            />
          </div>
          <div className="form-group col-md">
            ตำแหน่ง
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="ละติจูด"
                onChange={(e) => changeLat(e.target.value)}
                value={lat}
              />
              <input
                type="text"
                className="form-control"
                placeholder="ลองจิจูด"
                onChange={(e) => changeLng(e.target.value)}
                value={lng}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md">
            รายละเอียดเพิ่มเติม
            <input
              type="text"
              className="form-control"
              onChange={(e) => changeDetail(e.target.value)}
              value={detail}
            />
          </div>
        </div>
        <div className="form-row" style={{ justifyContent: "center" }}>
          <button type="button" onClick={onClose} className="btn btn-danger">
            ยกเลิก
          </button>
          <button className="btn btn-success" onClick={submitData}>
            ยืนยัน{title}ข้อมูลลูกค้า
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default CustMain;
