import React from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../api";
import { withAuth } from "../../AuthContext";

const UserBar = ({ auth }) => {
  const history = useHistory();
  return auth.isAuthenticated ? (
    <div>
      <div
        className="mr-sm-2"
        style={{
          display: "inline-block",
          padding: "5px",
          margin: "5px",
          verticalAlign: "middle",
          color: "lightgrey",
        }}
      >
        ผู้ใช้งาน:{" "}
        <Link
          to="/user"
          style={{ textDecoration: "none" }}
          className={`role ${
            auth.level === "admin" ? "role-admin" : "role-user"
          }`}
        >
          {auth.user}
        </Link>
        {/* <span
          onClick={() =>
            // alert("to user page")
          }
          className={`role ${
            auth.level === "admin" ? "role-admin" : "role-user"
          }`}
        >
          {auth.user}
        </span> */}
      </div>
      <button
        className="btn btn-outline-light my-2 my-sm-0"
        onClick={async () => {
          await api.post('/user/logout');
          history.push("/")
        }}
      >
        ออกจากระบบ
      </button>
    </div>
  ) : (
    ""
  );
};

export default withAuth(UserBar);
