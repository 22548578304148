import React, { useState, useEffect } from "react";
import { api } from "../../../../api";
import SaleGraph from "../Graph/SaleGraph";

const MnmSaleTool = () => {
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [name, setName] = useState("");

  const fetchData = async () => {
    const res = await api.get("/cust/listCustByValue/");
    setData(res.data.data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const newFiltered = data.filter((aa) => {
      return aa.custName.indexOf(name) !== -1;
    });
    setFiltered(newFiltered);
  }, [name, data]);

  return (
    <div>
      <div
        className="text-center"
        style={{
          backgroundColor: "lightgrey",
          padding: "5px",
          borderRadius: "3px",
        }}
      >
        <h4>ค้นหาลูกค้า</h4>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">ค้นหา</span>
          </div>
          <input
            className="form-control"
            onChange={(e) => setName(e.target.value)}
            placeholder="ชื่อ"
            value={name}
          />
          <button className="btn btn-secondary" onClick={() => setName("")}>
            ล้างข้อมูล
          </button>
        </div>
      </div>
      <main className="tb">
        <div className="table-head">
          <div className="textcenter flex1">อันดับ</div>
          <div className="textcenter flex3">ชื่อลูกค้า</div>
          <div className="center foption">ตัวเลือก</div>
        </div>

        {filtered.map((row, idx) => (
          <CustomerData
            data={row}
            no={idx + 1}
            key={`custData-${idx}`}
            fetchData={fetchData}
          />
        ))}
      </main>
    </div>
  );
};

const CustomerData = ({ data, no }) => {
  const [sale, setSale] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const fetchSale = async () => {
    const res = await api.post("/cust/listCustValue/", { custID: data.custID });
    // console.log(res.data.data);
    setSale(res.data.data);
  };
  const handleClickOpen = () => {
    setOpenSub(!openSub);
    if (!openSub) fetchSale();
  };
  return (
    <div className="table-body">
      <div className="flexrow">
        <div className="flex1 center">{no}</div>
        <div className="flex3 mg">{data.custName}</div>
        <div className="btn btn-secondary foption" onClick={handleClickOpen}>
          ดูเพิ่มเติม
        </div>
      </div>
      {openSub && (
        <div className="table-sub">
          {sale && (
            <div>
              <SaleGraph data={sale} />
              {sale.map((row) => (
                <div>
                  {`${row.M}/${row.Y}`}:{" "}
                  <b>
                    {row.value.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </b> บาท
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default MnmSaleTool;
