import React, { useState, useEffect } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { api } from "../../../api";
import { num } from "../../../Components/System/mosFn";

const MnmOrderDetailTable = ({ orderID }) => {
  const [result, setResult] = useState([]);
  const fetchData = async () => {
    await api
      .post("/mnm/getOrderInfoAndDetail", [orderID])
      .then((xx) => {
        setResult(
          xx.data.orders.map((aa) => {
            aa.product =
              num(aa.A) +
              "|" +
              aa.B +
              "|" +
              (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "") +
              aa.C +
              "|" +
              aa.D;
            return aa;
          })
        );
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <ReactTable
        data={result}
        minRows={0}
        showPagination={false}
        columns={[
          {
            Header: "ข้อมูล",
            columns: [
              {
                Header: "รหัสสินค้า",
                accessor: "prodID",
              },
              {
                Header: "สินค้า",
                // accessor: d => d.lastName
                accessor: "product",
              },
            ],
          },
          {
            Header: "รายละเอียดการขาย",
            columns: [
              {
                Header: "จำนวน",
                accessor: "quantity",
              },
              {
                Header: "ราคา/ผืน",
                accessor: "sale_price",
              },
              //   {
              //     Header: "ส่วนลด",
              //     id: "discount",
              //     accessor: "d => l(d.plasticw)"
              //   },
              {
                Header: "ราคารวม",
                id: "sumPrice",
                accessor: (d) => num(d.quantity * d.sale_price),
              },
            ],
          },
        ]}
        className="-striped -highlight"
      />
    </div>
  );
};

export default MnmOrderDetailTable;

//orderInfo
