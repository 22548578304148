import React, { useState } from "react";
import { Link } from "react-router-dom";

import User from "../System/UserBar";
import { withAuth } from "../../AuthContext";

const MenuBar = (props) => {
  const { auth } = props;
  const [open, setOpen] = useState(false);
  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <Link to="/">
        <div className="navbar-brand">THAI OCEAN FISHNET</div>
      </Link>
      <div
        className="navbar-toggler"
        type="button"
        onClick={() => setOpen((prev) => !prev)}
      >
        <span className="navbar-toggler-icon" />
      </div>

      <div className={`collapse navbar-collapse ${open && "show"}`}>
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link to="/" className="nav-link" href="/">
              <div onClick={closeMenu}>
                หน้าหลัก <span className="sr-only">(current)</span>
              </div>
            </Link>
          </li>
          {auth.access["inv"] === 1 && (
            <li className="nav-item">
              <Link to="/inventory" className="nav-link">
                <div onClick={closeMenu}>สินค้า</div>
              </Link>
            </li>
          )}
          {auth.access["cust"] === 1 && (
            <li className="nav-item">
              <Link to="/customer" className="nav-link" href="/customers">
                <div onClick={closeMenu}>ลูกค้า</div>
              </Link>
            </li>
          )}
          {auth.access["prod"] === 1 && (
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/"
                id="dropdownDept"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                ผ่ายผลิต
              </Link>
              <div className="dropdown-menu" aria-labelledby="dropdownDept">
                <Link className="dropdown-item" to="/dept/extruding">
                  <div onClick={closeMenu}>แผนกฉีดใย</div>
                </Link>
                <Link className="dropdown-item" to="/dept/weaving">
                  <div onClick={closeMenu}>แผนกทอ</div>
                </Link>
              </div>
            </li>
          )}
          {auth.access["mat"] === 1 && (
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="/"
                id="dropdownMat"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                วัตถุดิบ
              </Link>
              <div className="dropdown-menu" aria-labelledby="dropdownMat">
                <Link className="dropdown-item" to="/material/plastic">
                  <div onClick={closeMenu}>เม็ดพลาสติก</div>
                </Link>
                <Link className="dropdown-item" to="/material/spool">
                  <div onClick={closeMenu}>อีแปะ</div>
                </Link>
                <Link className="dropdown-item" to="/material/other">
                  <div onClick={closeMenu}>อื่นๆ</div>
                </Link>
              </div>
            </li>
          )}
          {auth.access["mnm"] === 1 && (
            <li className="nav-item">
              <Link to="/management" className="nav-link">
                <div onClick={closeMenu}>รายงาน</div>
              </Link>
            </li>
          )}
        </ul>
        <form className="form-inline my-2 my-lg-0">
          <User />
        </form>
      </div>
    </nav>
  );
};

export default withAuth(MenuBar);
