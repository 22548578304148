import React, { useState, useEffect } from "react";
import { api } from "../../api";
import { withAuth } from "../../AuthContext";

const UserMain = ({auth}) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await api.post(`/user/getUserDetail`, {
        username: auth.user,
      });
      setData(res.data.map((row) => new Date(row.timelogin).toString()));
    };
    fetchData();
  }, []);
  return (
    <div>
      <h3>
        ประวัติการเข้าสู่ระบบ <small>3 ครั้งล่าสุด</small>
      </h3>

      {data && data.map((row, idx) => <div key={`time-${idx}`}>{row}</div>)}
    </div>
  );
};

export default withAuth(UserMain);
