import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";

import "./Login.css";
import { api } from "../../api";

const Login = (props, { getUser, history }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  let { from } = props.location.state || { from: { pathname: "/" } };
  const handleChangeUserName = (e) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  async function handleClickLogin(e) {
    e.preventDefault();
    setLoading(true);
    const res = await api.post(
      "/user/login",
      {
        username: username,
        password: password,
      },
      {
        validateStatus: () => true,
      }
    );
    if (res.status === 200) {
      alert("Login สำเร็จ");
      props.setAuth({
        isAuthenticated: true,
        level: res.data.level,
        user: res.data.username,
        access: res.data.access,
      });
      props.history.push(from.pathname);
    } else {
      alert("Login ไม่สำเร็จ");
    }
    setLoading(false);
  }

  return (
    <div className="text-center mbody">
      <form
        className="form-signin"
        style={{ width: "100%" }}
        onSubmit={(e) => handleClickLogin(e)}
      >
        <img
          className="mb-4"
          src="/android-chrome-192x192.png"
          alt=""
          width="192"
          height="192"
        />
        <h1 className="h3 mb-3 font-weight-normal">เข้าสู่ระบบ</h1>
        <TextField
          style={{
            marginTop: "0",
            backgroundColor: "white",
          }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="ชื่อผู้ใช้"
          name="username"
          value={username}
          onChange={handleChangeUserName}
          autoFocus
        />
        <TextField
          style={{ marginTop: "0", backgroundColor: "white" }}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="รหัสผ่าน"
          type="password"
          value={password}
          onChange={handleChangePassword}
        />
        <button
          className="btn btn-info"
          disabled={loading}
          style={{ backgroundColor: "rgb(0,114,141)", width: "100%" }}
          onClick={handleClickLogin}
        >
          เข้าสู่ระบบ
        </button>
        <p className="mt-5 mb-3 text-muted">&copy; Thai Ocean Fishnet</p>
      </form>
    </div>
  );
};

export default Login;
