import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import InvMain from "./InvMain";
import InvSell from "./InvSell/InvSell";
import InvMove from "./InvMove";
import InvImports from "./InvImports";
import "./Inventory.css";
import Header from "../../Components/Header/Header";
import { api } from "../../api";
import InvAdjustValue from "./InvAdjust/InvAdjustValue";
import InvAdjustHistory from "./InvAdjust/InvAdjustHistory";
import InvSellRoute from "./InvSell/InvSellRoute";
import InvBill from "./InvBill/InvBill";
import CheckAccess from "../../Components/System/CheckAccess";
import Menu from "../../Components/UI/Menu";
import MenuSub from "../../Components/UI/MenuSub";
import { num } from "../../Components/System/mosFn";

const Inventory = ({ match }) => {
  const [data, setData] = useState([]);

  const reload = () => {
    console.log("reload ...");
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await api.get("/inv/listInv");
    setData(
      res.data.result.map((aa) => {
        aa.A = num(aa.A);
        aa.B =
          "" +
          aa.B +
          (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "");
        return aa;
      })
    );
  };

  const link = [
    { name: "รายการสินค้า", to: "" },
    { name: "ขายสินค้า", to: "Sell", auth: "invOrder" },
    { name: "เคลื่อนย้ายสินค้า", to: "Transport", auth: "invMove" },
    { name: "ประวัติการย้ายสินค้า", to: "Import" },
    { name: "ปรับรายการสินค้า", to: "Adjust", auth: "invEdit" },
  ];

  return (
    <div>
      <div className="container">
        <Header name="สินค้า" />
        <Menu match={match.path} link={link} />
        <Switch>
          <Route exact path={match.path}>
            <InvMain data={data} reload={reload} />
          </Route>

          <Route path={`${match.path}/sell`}>
            <CheckAccess access="invOrder" />
            <InvSellRoute
              data={data}
              reload={reload}
              InvSell={InvSell}
              match={match}
            />
          </Route>

          <Route path={`${match.path}/transport`}>
            <InvMove data={data} reload={reload} />
          </Route>

          <Route path={`${match.path}/import`} component={ImportRoute} />

          <Route path={`${match.path}/adjust/`}>
            <CheckAccess access="invEdit" />
            <InvAdjustValue data={data} reload={reload} />
          </Route>
          <Route
            path={`${match.path}/adjust/history`}
            component={InvAdjustHistory}
          />

          <Route path={`${match.path}/bill/:bill`} component={InvBill} />
        </Switch>
      </div>
    </div>
  );
};

const ImportRoute = ({ match }) => {
  const link = [
    { name: "สินค้าเข้าโรงงาน", to: "inFT" },
    { name: "สินค้าโรงงานไปโกดัง1", to: "FTWH1" },
    { name: "สินค้าโกดัง1ไปโกดัง2", to: "WH1WH2" },
    { name: "สินค้าโกดัง2ไปโกดัง1", to: "WH2WH1" },
  ];
  return (
    <div>
      <MenuSub match={match.path} link={link} />
      <Route exact path={`${match.path}/`} />
      <Route path={`${match.path}/:source`} component={InvImports} />
    </div>
  );
};

export default Inventory;
