import React, { useState, useEffect } from "react";
import Header from "../../../Components/Header/Header";
import { api } from "../../../api";
import { Line } from "react-chartjs-2";
import SellHistoryChart from "../Dashboard/SellHistoryChart";

const MnmSaleSummary = () => {
  const [sale, setSale] = useState([]);
  const [year, setYear] = useState([]);
  const fetchData = async () => {
    const res = await api.get("/mnm/getSaleSummary");
    const { result } = res.data;

    let year = [];
    let data = [];

    for (let i = 0; i < result.length; i++) {
      if (!year.includes(result[i].year)) {
        year.push(result[i].year);
        data[year.length - 1] = [];
      }
      for (let j = 0; j < year.length; j++) {
        if (result[i].year === year[j]) {
          data[j][result[i].month - 1] = result[i].sale;
        }
      }
    }
    setYear(year);
    setSale(data);
  };

  const color = [
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
    "rgb(255, 206, 86)",
    "rgb(75, 192, 192)",
    "rgb(153, 102, 255)",
    "rgb(255, 159, 64)",
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
    "rgb(255, 206, 86)",
    "rgb(75, 192, 192)",
    "rgb(153, 102, 255)",
    "rgb(255, 159, 64)",
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
    "rgb(255, 206, 86)",
    "rgb(75, 192, 192)",
    "rgb(153, 102, 255)",
    "rgb(255, 159, 64)",
  ];

  const chartData = {
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    datasets: [
      {
        label: year[0],
        datasetKeyProvider: "a",
        data: sale[0],
        lineTension: 0,
        // backgroundColor: color[0],
        backgroundColor: "transparent",
        borderColor: color[0],
        borderWidth: 2,
        pointBackgroundColor: color[0],
      },
      {
        label: year[1],
        datasetKeyProvider: "b",
        data: sale[1],
        lineTension: 0,
        // backgroundColor: color[1],
        backgroundColor: "transparent",
        borderColor: color[1],
        borderWidth: 2,
        pointBackgroundColor: color[1],
      },
    ],
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header name="ประวัติการขายย้อนหลัง" />
      <div>
        {/* <Line data={chartData} /> */}
        <SellHistoryChart />
      </div>
    </div>
  );
};

export default MnmSaleSummary;
