import React, { useState, useEffect } from "react";
import CardData from "../../Inventory/CardData";
import SearchProduct from "../../../Components/Others/SearchProduct";
import Header from "../../../Components/Header/Header";
import { api } from "../../../api";
import { num } from "../../../Components/System/mosFn";

const MnmInv = () => {
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState(["", "", "", ""]);
  const fetchData = async () => {
    await api
      .get("/get/listInv")
      .then((xx) => {
        setData(
          xx.data.result.map((aa) => {
            aa.A = num(aa.A);
            aa.B =
              "" +
              aa.B +
              (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "");
            return aa;
          })
        );
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const newFiltered = data
      .filter((aa) => {
        return aa.A.toString().indexOf(search[0]) !== -1;
      })
      .filter((aa) => {
        return aa.B.toString().indexOf(search[1]) !== -1;
      })
      .filter((aa) => {
        return aa.C.toString().indexOf(search[2]) !== -1;
      })
      .filter((aa) => {
        return aa.D.toString().indexOf(search[3]) !== -1;
      });
    setFiltered(newFiltered);
  }, [search, data]);

  const handleOnChange = (e, type) => {
    const newSearch = [...search];
    newSearch[type] = e.target.value;
    setSearch(newSearch);
  };
  const handleClearSearch = () => {
    setSearch(["", "", "", ""]);
  };
  const reload = () => {
    fetchData();
  };

  return (
    <div>
      <Header name="สินค้าในคลัง" />
      <div className="cardContainer">
        <SearchProduct
          onChange={handleOnChange}
          onClick={handleClearSearch}
          value={search}
        />
        {filtered.map((row, idx) => (
          <CardData value={row} key={`cardd-${idx}`} reload={reload} />
        ))}
      </div>
    </div>
  );
};

export default MnmInv;
