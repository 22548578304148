import React from "react";
import { Route } from "react-router-dom";

import CustMain from "./CustMain";
import CustData from "./CustData";
import Header from "../../Components/Header/Header";

const Customer = ({ match }) => {
  return (
    <div className="container">
      <Header name="ลูกค้า" />
      <div>
        <Route exact path={`${match.path}`} component={CustMain} />
        <Route path={`${match.path}/:custID`} component={CustData} />
      </div>
    </div>
  );
};

export default Customer;
