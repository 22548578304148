import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// const [open, setOpen] = React.useState(false);
// const handleClose = () => {
//     setOpen(false);
//   };

const EditReason = ({ open, onClose, onSubmit, editData, onChange }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        ยืนยันแจ้ง{editData.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ใส่เหตุผลในการแจ้ง{editData.name}
          <br />
          ข้อมูล {JSON.stringify(editData.submitData)}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="เหตุผล"
          type="text"
          onChange={onChange}
          value={editData.reason}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          ยกเลิก
        </Button>
        <Button onClick={onSubmit} color="primary">
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditReason;
