import React, { useState, useEffect } from "react";

import MonthlyMenu from "./Menu/MonthlyMenu";
import SaleMenu from "./Menu/SaleMenu";
import MnmOrdersTable from "./Table/MnmOrdersTable";
import { api } from "../../api";
import { num } from "../../Components/System/mosFn";

const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);

const MnmOrders = ({ match }) => {
  const { m, y } = match.params;
  const [result, setResult] = useState([]);
  const [totalSold, setTotalSold] = useState("");
  const fetchData = async () => {
    const res = await api.post("/mnm/listOrdersByMonth", [y, m]);
    setResult(res.data);
    setTotalSold(num(arrSum(res.data.map((row) => row.totalprice))));
  };

  const reload = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [y, m]);

  return (
    <div>
      <div className="text-center">
        <MonthlyMenu match={match} type={"orders"} />
      </div>
      <main role="main" className="container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
          <h2>สรุปบิลขาย</h2>
          <SaleMenu match={match} />
        </div>
        <h4>
          ข้อมูลของ เดือน {m} ปี {y}
        </h4>
        ยอดขายรวม: <b>{totalSold}</b> บาท
      </main>
      <main className="container">
        <MnmOrdersTable data={result} reload={reload} />
      </main>
    </div>
  );
};

export default MnmOrders;
