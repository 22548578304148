import React, { useState, useEffect } from "react";
import { api } from "../../api";

import PlasticImport from "./MatPlastic/PlasticImport";
import PlasticAdd from "./MatPlastic/PlasticAdd";

import AddIcon from "@material-ui/icons/Add";
import CallReceivedIcon from "@material-ui/icons/CallReceived";

const MatPlasticImport = () => {
  const [plastic, setPlastic] = useState([]);
  const [adding, setAdding] = useState(false);
  const [importing, setImporting] = useState(false);
  const [importData, setImportData] = useState([]);

  const handleClickAdd = (idx) => {
    const newData = [...importData];
    plastic[idx].amount = 1;
    newData.push(plastic[idx]);
    setImportData(newData);
    setImporting(true);
    setAdding(false);
  };

  const handleClickDel = (idx) => {
    const newData = [...importData];
    delete newData[idx].amount;
    newData.splice(idx, 1);
    setImportData(newData);
  };

  const reload = () => {
    fetchData();
    const newData = [...importData];
    for (let i = 0; i < newData.length; i++) {
      delete newData[i].amount;
    }
    newData.splice(0, newData.length);
    setImportData(newData);
  };

  const handleOpenAdd = () => {
    setAdding((prev) => !prev);
    setImporting(false);
  };

  const handleOpenImport = () => {
    setImporting((prev) => !prev);
    setAdding(false);
  };

  const handleClickAmount = (op, idx) => {
    const newImportData = [...importData];
    if (op === "+") {
      newImportData[idx].amount += 1;
    }
    if (op === "-") {
      if (newImportData[idx].amount > 0) {
        newImportData[idx].amount -= 1;
      }
    }
    setImportData(newImportData);
  };

  const handleChangeAmount = (e, idx) => {
    const newImportData = [...importData];
    const value = parseInt(e.target.value);
    newImportData[idx].amount = !isNaN(value) ? value : 0;
    setImportData(newImportData);
  };

  const fetchData = async () => {
    const res = await api.get("/mat/listPlastic");
    setPlastic(res.data.result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <button className="btn btn-secondary" onClick={handleOpenAdd}>
        <AddIcon /> เพิ่มพลาสติกใหม่
      </button>
      <button className="btn btn-secondary" onClick={handleOpenImport}>
        <CallReceivedIcon /> นำเม็ดเข้าโรงงาน
      </button>

      {/* <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<CallReceivedIcon />}
        onClick={handleOpenImport}
      >
        นำเม็ดเข้าโรงงาน
      </Button> */}

      {adding && <PlasticAdd />}
      {importing && (
        <PlasticImport
          data={importData}
          onClickDel={handleClickDel}
          onClickAmount={handleClickAmount}
          onChangeAmount={handleChangeAmount}
          reload={reload}
        />
      )}
      <div>
        <table className="table table-striped table-condensed">
          <thead className="thead-light">
            <tr>
              <th>ชื่อพลาสติก</th>
              <th>ชนิด</th>
              <th>จำนวนถุง</th>
              <th>น้ำหนัก</th>
              <th>เพิ่ม</th>
            </tr>
          </thead>
          <tbody>
            {plastic.map((row, idx) => (
              <tr key={`plas-${row.plasticID}`}>
                <td>{row.plasticName}</td>
                <td>{row.plasticType}</td>
                <td>{row.amountBag}</td>
                <td>{row.amountKg}</td>
                <td>
                  <button
                    className="btn btn-secondary"
                    style={{
                      // backgroundColor: "lightgray",
                      borderRadius: "50%",
                    }}
                    disabled={row.amount > 0 ? true : false}
                    onClick={() => handleClickAdd(idx)}
                  >
                    <AddIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MatPlasticImport;
