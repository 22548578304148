import React, { useState, useEffect } from "react";
import { api } from "../../../../api";
import CardName from "../../../Inventory/Components/CardName";
import DatePicker from "react-datepicker";

const MnmToolProd = ({ match }) => {
  const [datei, setDatei] = useState(new Date());
  const [datef, setDatef] = useState(new Date());
  const [prod, setProd] = useState(false);
  const [producing, setProducing] = useState("");
  const [data, setData] = useState([]);
  // const [chooseOpen, setChooseOpen] = useState(false);
  // const [mac, setMac] = useState([]);
  const { prodID } = match.params;

  // const handleClickMac = () => {
  //   setChooseOpen(true);
  // };

  const handleSearch = async () => {
    // console.log(convertDate(datei), convertDate(datef), mac)
    const res = await api.post("/mnm/setProduction", {
      i: convertDate(datei),
      j: convertDate(datef),
      prodID: prodID,
    });
    if (res) setData(res.data.result);
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.post("/mnm/getProducing", { prodID: prodID });
      if (res) {
        setProd(res.data.info);
        if (res.data.producing) {
          setProducing(`กำลังผลิตอยู่ในเครื่อง ${res.data.mac.toString()}`);
        } else {
          setProducing("- ไม่ได้ผลิตในขณะนี้ -");
        }
      }
    };
    fetchData();
  }, [prodID]);

  return (
    <div className="container">
      <h3 className="text-center">การผลิต</h3>
      {producing && (
        <div
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          สินค้า:
          <CardName data={prod} />
          <div
            className="center"
            style={{ background: "lightgray", margin: "3px" }}
          >
            {producing}
          </div>
        </div>
      )}
      <div
        className="flexrow"
        style={{
          margin: "5px",
          backgroundColor: "lightgray",
          padding: "3px",
          alignItems: "center",
        }}
      >
        <div className="flex1 text-center">ตั้งแต่</div>
        <DatePicker
          className="form-control"
          dateFormat="dd/MM/yyyy"
          selected={datei}
          onChange={(date) => {
            setDatei(date);
          }}
        />
        <div className="flex1 text-center">ถึง</div>
        <DatePicker
          className="form-control"
          dateFormat="dd/MM/yyyy"
          selected={datef}
          onChange={(date) => {
            setDatef(date);
          }}
        />
        {/* <button className="btn btn-outline-secondary" onClick={handleClickMac}>
          {!mac.length ? "ทุกเครื่อง" : mac.toString()}
        </button> */}
        <div
          className="flex3 btn btn-secondary"
          style={{ margin: "5px" }}
          onClick={handleSearch}
        >
          ค้นหา
        </div>
      </div>
      <div className='flexrow'>
        {data.length>0 &&
          data.map((row) => (
            <div key={row.mac} className='flexrow border flex1'>
              <b style={{margin:'5px'}}>{row.mac}</b>
              <div style={{margin:'5px'}}>{row.piece} ผืน</div>
            </div>
          ))}
      </div>
      {/* <ChooseMacDialog
        open={chooseOpen}
        onClose={() => setChooseOpen(false)}
        onSubmit={(choosed) => {
          setChooseOpen(false);
          setMac(choosed);
        }}
      /> */}
    </div>
  );
};

// const ChooseMacDialog = ({ open, onClose, onSubmit }) => {
//   const [mac, setMac] = useState(false);
//   // const [choose, setChoose] = useState([]);
//   let choose = [];
//   const onClickBtn = (clicked, mac) => {
//     switch (clicked) {
//       case true:
//         choose.splice(choose.indexOf(mac), 1);
//         break;
//       case false:
//         choose.push(mac);
//         choose.sort();
//         break;
//       default:
//         console.log("error: no mac choosing state");
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       const res = await api.get("/mnm/listMacName");
//       if (res) {
//         setMac(res.data.mac);
//       }
//     };
//     fetchData();
//   }, []);

//   return (
//     <Dialog open={open} onClose={onClose}>
//       <form
//         onSubmit={(e) => e.preventDefault()}
//         style={{
//           backgroundColor: "white",
//           padding: "20px",
//           borderRadius: "5px",
//         }}
//       >
//         <h4>เลือกเครื่อง</h4>
//         <div className="flexrow" style={{ flexWrap: "wrap" }}>
//           {mac.length > 0 &&
//             mac.map((row) => (
//               <MacBtn
//                 key={row}
//                 className="center"
//                 mac={row}
//                 onClick={onClickBtn}
//               />
//             ))}
//         </div>
//         <div style={{ display: "flex" }}>
//           <button
//             type="submit"
//             style={{
//               border: "1px solid black",
//               margin: "5px",
//               borderRadius: "3px",
//             }}
//             className="flex1"
//             onClick={() => onSubmit(choose)}
//           >
//             ยืนยัน
//           </button>
//         </div>
//       </form>
//     </Dialog>
//   );
// };

// const MacBtn = ({ mac, onClick }) => {
//   const [clicked, setClicked] = useState(false);
//   return (
//     <button
//       key={mac}
//       className={`center ${clicked && "btn-secondary"}`}
//       onClick={() => {
//         onClick(clicked, mac);
//         setClicked((prev) => !prev);
//       }}
//       style={{
//         // backgroundColor: "lightgray",
//         border: "1px solid black",
//         borderRadius: "5px",
//         padding: "5px",
//         margin: "5px",
//         width: "50px",
//       }}
//     >
//       {mac}
//     </button>
//   );
// };

const convertDate = (date) => {
  return (
    date.getUTCFullYear() +
    "-" +
    ("0" + (date.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getUTCDate()).slice(-2)
  );
};

export default MnmToolProd;
