import React from "react";
import { num } from "../../../Components/System/mosFn";

const CardName = ({ data }) => {
  return (
    <div style={{ display: "flex", flexWrap: "nowrap" }}>
      <b>{num(data.A)}</b> | <b> {data.B} </b>| <b>{data.C}</b> |{" "}
      <b>{data.D}</b>
      <b className={`${data.grade === "A" ? "cblue" : "cgreen"} grade`}>
        {data.grade}
      </b>
    </div>
  );
};

export default CardName;
