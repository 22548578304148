import React, { useState } from "react";
import { api } from "../../api";
import { withAuth } from "../../AuthContext";

const UserChangePwd = ({auth}) => {
  const [oldPwd, changeOldPwd] = useState("");
  const [newPwd, changeNewPwd] = useState("");
  const [reNewPwd, changeReNewPwd] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPwd === reNewPwd) {
      const res = await api.post("/user/changePwd", {
        oldPwd,
        newPwd,
        username: auth.user,
      });
      if (res.data.success) {
        changeOldPwd("");
        changeNewPwd("");
        changeReNewPwd("");
        alert(res.data.message);
      } else {
        alert(res.data.error);
      }
    } else {
      alert("รหัสผ่านไม่ตรงกันทั้งสองรอบ");
    }
  };
  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      style={{ maxWidth: "330px", margin: "auto" }}
    >
      <h3 style={{ padding: "15px" }}>ชื่อผู้ใช้ {auth.user}</h3>

      <div className="form-group">
        <label>รหัสผ่านเดิม</label>
        <input
          className="form-control"
          placeholder="ใส่รหัสผ่านเดิม"
          type="password"
          onChange={(e) => changeOldPwd(e.target.value)}
          value={oldPwd}
        />
      </div>

      <div className="form-group">
        <label>รหัสผ่านใหม่</label>
        <input
          className="form-control"
          placeholder="ใส่รหัสผ่านใหม่"
          type="password"
          onChange={(e) => changeNewPwd(e.target.value)}
          value={newPwd}
        />
        <small className="form-text text-muted">
          รหัสผ่านจะถูกเข้ารหัสไว้ ไม่มีใครสามารถดูรหัสผ่านได้
        </small>
      </div>

      <div className="form-group">
        <label>ยืนยันรหัสผ่านใหม่</label>
        <input
          className="form-control"
          placeholder="ใส่รหัสผ่านใหม่อีกครั้ง"
          type="password"
          onChange={(e) => changeReNewPwd(e.target.value)}
          value={reNewPwd}
        />
      </div>
      <button onClick={handleSubmit} type="submit" className="btn btn-primary">
        ยืนยัน
      </button>
      <br />
    </form>
  );
};

export default withAuth(UserChangePwd);
