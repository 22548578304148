import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import InvImportDetailTable from "./InvImportDetailTable";
import { api } from "../../../api";
import { withAuth } from "../../../AuthContext";

const InvImportTable = ({ auth, data, fetchData, reload, source }) => {
  console.log(data);
  const handleOnClickDelete = importID => {
    if (auth.level === "admin") {
      api.post(`/inv/delete/${source}`, { importID: importID }).then(xx => {
        alert(xx.data.success === true ? xx.data.result : xx.data.error);
        fetchData();
        reload();
      });
    } else {
      const post = {
        name: `Delete Import ${source}`,
        query: `/inv/delete/${source}`,
        data: { importID: importID },
        user: auth.user
      };
      const res = api.post("/request", post);
      if (res.data.success) {
        alert(res.data);
      }
    }
  };

  const columns = [
    {
      Header: "ข้อมูลการนำเข้า",
      columns: [
        {
          Header: "หมายเลข",
          accessor: "importID"
        },
        {
          Header: "วันที่",
          accessor: "importDate"
        }
      ]
    },
    {
      Header: "รายละเอียด",
      columns: [
        {
          Header: "ผู้นำเข้า",
          accessor: "submitter"
        }
      ]
    },
    {
      Header: "เพิ่มเติม",
      columns: [
        {
          Header: "ตัวเลือก",
          id: "test",
          accessor: d => (
            <button
              className="btn btn-secondary"
              onClick={() => handleOnClickDelete(d.importID)}
            >
              {auth.level === "admin" ? "ลบบิล" : "แจ้งลบบิล"}
            </button>
          )
        }
      ]
    }
  ];
  return (
    <div>
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={20}
        className="-striped -highlight"
        SubComponent={row => {
          return (
            <div style={{ padding: "5px" }}>
              <InvImportDetailTable
                importID={row.original.importID}
                source={source}
              />
            </div>
          );
        }}
      />
    </div>
  );
};

export default withAuth(InvImportTable);
