import React, { useEffect } from "react";
import { num } from "../../Components/System/mosFn";

const CartFooter = ({
  sumPrice,
  onChange,
  percent,
  onClickSell,
  totalPrice,
}) => {
  return (
    <div className="cartFooter">
      <div>
        <div>ราคาคำนวณ {num(sumPrice)} บาท</div>
        <div>ราคาขาย</div>
        <input
          className="form-control"
          onChange={(e) => onChange(e)}
          pattern="\d*"
          value={totalPrice}
        />
      </div>
      <div className="submit-zone text-center">
        <div>ส่วนลดเฉลี่ย</div>
        <div>
          <b>{percent}%</b>
        </div>
        <button
          type="button"
          onClick={onClickSell}
          className="btn btn-danger footer-btn"
        >
          ยืนยัน
        </button>
      </div>
    </div>
  );
};

export default CartFooter;
