import React from "react";

const AddPlastic = ({ onSubmit, plasticData, setPlastic }) => {
  const round = num => {
    return Math.round(num * 100) / 100;
  };

  const changeAmountBag = (e, idx) => {
    const newPlastic = [...plasticData];
    if (parseFloat(e.target.value) >= newPlastic[idx].amountBag) {
      newPlastic[idx].nBag = newPlastic[idx].amountBag;
    } else if (
      parseInt(e.target.value) <= 0 ||
      Number.isNaN(parseFloat(e.target.value))
    ) {
      newPlastic[idx].nBag = 0;
    } else {
      newPlastic[idx].nBag = parseFloat(e.target.value);
    }
    newPlastic[idx].nKg = round(
      newPlastic[idx].nBag * newPlastic[idx].plasticWeight
    );
    setPlastic(newPlastic);
  };
  const changeAmountKg = (e, idx) => {
    const newPlastic = [...plasticData];

    if (
      parseFloat(e.target.value) >=
      newPlastic[idx].amountBag * newPlastic[idx].plasticWeight
    ) {
      newPlastic[idx].nKg = round(
        newPlastic[idx].amountBag * newPlastic[idx].plasticWeight
      );
    } else if (
      parseInt(e.target.value) <= 0 ||
      Number.isNaN(parseFloat(e.target.value))
    ) {
      newPlastic[idx].nKg = 0;
    } else {
      newPlastic[idx].nKg = round(parseFloat(e.target.value));
    }
    newPlastic[idx].nBag = newPlastic[idx].nKg / newPlastic[idx].plasticWeight;
    setPlastic(newPlastic);
  };
  const clickUp = idx => {
    const newPlastic = [...plasticData];
    if (newPlastic[idx].nBag === newPlastic[idx].amountBag) {
      return;
    }
    newPlastic[idx].nBag += 1;
    newPlastic[idx].nKg = round(
      newPlastic[idx].nBag * newPlastic[idx].plasticWeight
    );
    setPlastic(newPlastic);
  };
  const clickDn = idx => {
    const newPlastic = [...plasticData];
    if (newPlastic[idx].nBag === 0) {
      return;
    }
    newPlastic[idx].nBag -= 1;
    newPlastic[idx].nKg = round(
      newPlastic[idx].nBag * newPlastic[idx].plasticWeight
    );
    setPlastic(newPlastic);
  };

  return (
    <div style={{ backgroundColor: "rgb(240,240,240)" }}>
      <div className="modal-body">
        <h4>
          {plasticData.map((data, idx) => (
            <div
              className="alert alert-info"
              role="alert"
              key={`platic-${idx}`}
            >
              <div className="row">
                <div className="col-12">{data.plasticName}</div>
              </div>
              <div className="row">
                <div className="input-group col-6">
                  <div className="input-group-prepend">
                    <button
                      className="btn btn-success"
                      onClick={() => clickDn(idx)}
                    >
                      -
                    </button>
                  </div>
                  <input
                    onChange={e => changeAmountBag(e, idx)}
                    value={data.nBag}
                    type="text"
                    className="form-control"
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-success"
                      onClick={() => clickUp(idx)}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="input-group col-6">
                  <input
                    value={data.nKg}
                    onChange={e => changeAmountKg(e, idx)}
                    type="text"
                    className="form-control"
                    placeholder="น้ำหนัก"
                  />
                  <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2">
                      kg
                    </span>
                  </div>
                </div>
              </div>
              คงเหลือ: {data.amountBag} ถุง
            </div>
          ))}
        </h4>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-success" onClick={onSubmit}>
          ยืนยัน
        </button>
      </div>
    </div>
  );
};

export default AddPlastic;
