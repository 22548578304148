import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { api } from "../../../api";
import CardName from "../Components/CardName";
import { num } from "../../../Components/System/mosFn";

const ImportDataRow = ({ data, handleClickDeleteData, source }) => {
  const [detail, setDetail] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const handleClickMore = () => {
    if (detail.length === 0) {
      fetchData();
    }
    setOpen((prev) => !prev);
  };
  const fetchData = async () => {
    const res = await api.post(`/inv/getImport${source}Detail`, [
      data.importID,
    ]);
    setDetail(
      res.data.map((aa) => {
        aa.A = num(aa.A);
        aa.B =
          "" +
          aa.B +
          (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "");
        return aa;
      })
    );
  };
  useEffect(() => {
    setOpen(false);
  }, [data]);
  return (
    <div
      style={{
        backgroundColor: "rgb(220,220,220)",
        margin: "5px",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: "1 0 0", margin: "auto", textAlign: "center" }}>
          {data.importID}
        </div>
        <div style={{ flex: "2 0 0", margin: "auto", textAlign: "center" }}>
          {data.importDate}
        </div>
        <div style={{ flex: "1 0 0", margin: "auto", textAlign: "center" }}>
          {data.submitter}
        </div>
        <div style={{ flex: "3 0 0", margin: "5px", display: "flex" }}>
          <button
            className="btn btn-secondary"
            style={{ flex: "1 0 0" }}
            onClick={handleClickMore}
          >
            ดูเพิ่มเติม
          </button>
          <button
            disabled
            className="btn"
            onClick={() =>
              handleClickDeleteData(
                { weavingID: data.weavingID },
                "/weav/delWeavingData",
                "ลบรายการทอ"
              )
            }
          >
            <DeleteIcon />
          </button>
        </div>
      </div>
      {isOpen && detail.length > 0 && (
        <div
          style={{ margin: "5px", backgroundColor: "white", padding: "5px" }}
        >
          {detail.map((row, idx) => (
            <div
              key={`detail-${idx}`}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: "5 0 0",
                  flexWrap: "wrap",
                  margin: "3px",
                  backgroundColor: "rgb(240,240,240)",
                }}
              >
                <div
                  className="col-md-6"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ flex: "1 0 0", margin: "auto" }}>
                    <CardName data={row} />
                  </div>
                </div>
                <div
                  className="col-md-6"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div style={{ flex: "1 0 0", margin: "auto" }}>
                    จำนวน: {row.quantity}
                  </div>
                </div>
              </div>
              <div>
                <button
                  disabled
                  className="btn"
                  style={{ padding: "10px" }}
                  onClick={() =>
                    handleClickDeleteData(
                      { mac: row.mac, detailID: row.detailID },
                      "/weav/delWeavingDetail",
                      "ลบรายการทอเครื่อง"
                    )
                  }
                >
                  <DeleteIcon />
                </button>
              </div>
            </div>
          ))}
          <div className="col-12">{data.remark}</div>
        </div>
      )}
    </div>
  );
};

export default ImportDataRow;
