import React, { useState, useEffect } from "react";
import { api } from "../../../api";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import { num } from "../../../Components/System/mosFn";
import CardName from "../Components/CardName";

const InvSellHistory = () => {
  const [orders, setOrders] = useState([]);
  const [ttprice, setTtprice] = useState(0);
  const [nweight, setNweight] = useState(0);
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [mode, setMode] = useState(1);
  const [list, setList] = useState([]);

  const handleSearch = async () => {
    const res = await api.get(
      `/inv/listOrdersBtw/${convertDate(dateStart)}/${convertDate(dateEnd)}`
    );
    const res2 = await api.get(
      `/inv/listOrdersWeightBtw/${convertDate(dateStart)}/${convertDate(
        dateEnd
      )}`
    );
    setOrders(res.data);
    setList(
      res2.data.map((row) => {
        const ttweight = row.weight * row.amount;
        row.ttweight = ttweight;
        return row;
      })
    );
    const ttprice = res.data.reduce((total, b) => total + b.totalprice, 0);
    setTtprice(ttprice);
    const nweight = res2.data.reduce((total, b) => total + b.ttweight, 0);
    setNweight(nweight);
  };

  return (
    <div>
      <div
        className="flexrow cc"
        style={{
          backgroundColor: "lightgray",
          borderRadius: "5px",
          padding: "3px",
        }}
      >
        <div className="flex2 flexrow cc">
          <div className="nw">วันที่</div>
          <ReactDatePicker
            className="form-control"
            selected={dateStart}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setDateStart(date);
            }}
          />
        </div>
        <div className="flex2 flexrow cc">
          <div className="nw">ถึงวันที่</div>
          <ReactDatePicker
            className="form-control"
            selected={dateEnd}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setDateEnd(date);
            }}
          />
        </div>
        <div className="flex1 btn btn-secondary" onClick={handleSearch}>
          ค้นหา
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div className="btn-menu" onClick={() => setMode(1)}>
          บิลขาย
        </div>
        <div className="btn-menu" onClick={() => setMode(2)}>
          น้ำหนัก
        </div>
      </div>
      {mode === 1 ? (
        <>
          <div
            style={{
              backgroundColor: "lightgray",
              borderRadius: "5px",
              padding: "3px",
              // border: "1px solid black",
              margin: "1px",
            }}
          >
            <div className="text-center">ยอดรวม {num(ttprice)} บาท</div>
          </div>
          <div
            style={{
              backgroundColor: "rgb(197, 197, 197)",
              padding: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: "1 0 0", textAlign: "center" }}>หมายเลข</div>
              <div style={{ flex: "2 0 0", textAlign: "center" }}>
                วันที่ส่งข้อมูล
              </div>
              <div style={{ flex: "1 0 0", textAlign: "center" }}>
                ผู้ลงข้อมูล
              </div>
              {/* <div style={{ flex: "3 0 0", textAlign: "center" }}>ตัวเลือก</div> */}
            </div>
            {orders.length > 0 &&
              orders.map((row, idx) => <OrderData key={idx} data={row} />)}
          </div>
        </>
      ) : (
        <div>
          <div
            style={{
              backgroundColor: "lightgray",
              borderRadius: "5px",
              padding: "3px",
              // border: "1px solid black",
              margin: "1px",
            }}
          >
            <div className="text-center">
              น้ำหนักรวม {num(nweight)} กิโลกรัม
            </div>
          </div>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">สินค้า</th>
                <th scope="col">น้ำหนัก</th>
                <th scope="col">จำนวน</th>
                <th scope="col">น้ำหนักรวม</th>
              </tr>
            </thead>
            <tbody>
              {list.map((row, idx) => (
                <ListData key={idx} data={row} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const OrderData = ({ data, source }) => {
  const handleClickDeleteData = () => {};
  return (
    <div>
      <OrderDataRow
        data={data}
        handleClickDeleteData={handleClickDeleteData}
        source={source}
      />
    </div>
  );
};

const ListData = ({ data }) => {
  return (
    <tr>
      <th>
        <CardName data={data} />
      </th>
      <td>{data.weight} กก./ชิ้น</td>
      <td>{data.amount} ชิ้น</td>
      <td>{num(data.ttweight)} กก.</td>
    </tr>
  );
};

const OrderDataRow = ({ data, clickDeleteData }) => {
  const [detail, setDetail] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const handleClickMore = () => {
    fetchDetail();
    setOpen((prev) => !prev);
  };
  const fetchDetail = async () => {
    const res = await api.post("/inv/listOrderDetail", {
      orderID: data.orderID,
    });
    setDetail(
      res.data.map((aa) => {
        aa.product =
          num(aa.A) +
          "|" +
          aa.B +
          "|" +
          (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "") +
          aa.C +
          "|" +
          aa.D;
        return aa;
      })
    );
  };
  return (
    <div
      style={{
        backgroundColor: "rgb(220,220,220)",
        margin: "5px",
        borderRadius: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{ flex: "4 0 0", display: "flex", flexDirection: "column" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: "1 0 0", margin: "auto", textAlign: "center" }}>
              {data.orderID}
            </div>
            <div style={{ flex: "2 0 0", margin: "auto", textAlign: "center" }}>
              {data.orderDate}
            </div>
            <div style={{ flex: "1 0 0", margin: "auto", textAlign: "center" }}>
              {data.submitter}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0 10px",
              justifyContent: "space-around",
            }}
          >
            <div>ลูกค้า: {data.custName}</div>
            <div>ราคารวม: {data.totalprice}</div>
          </div>
        </div>
        <div
          style={{
            flex: "3 0 0",
            margin: "5px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div
            className="btn-mini"
            // style={{ flex: "1 0 0" }}
            onClick={handleClickMore}
          >
            ดูเพิ่มเติม
          </div>
          <Link
            to={`/inventory/bill/${data.orderID}`}
            className="btn-mini"
            style={{ textDecoration: "none", color: "black" }}
            // style={{ flex: "1 0 0" }}
          >
            ดูบิล
          </Link>
          <button disabled className="btn" onClick={clickDeleteData}>
            <DeleteIcon />
          </button>
        </div>
      </div>
      {isOpen && detail.length > 0 && <Detail data={data} detail={detail} />}
    </div>
  );
};

const Detail = ({ detail, data }) => {
  return (
    <div style={{ margin: "5px", backgroundColor: "white", padding: "5px" }}>
      {detail.map((row, idx) => (
        <div
          key={`detail-${idx}`}
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: "5 0 0",
              flexWrap: "wrap",
              margin: "3px",
              backgroundColor: "rgb(240,240,240)",
            }}
          >
            <div
              className="col-md-6"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div style={{ flex: "1 0 0", margin: "auto" }}>
                สินค้า: {row.product}
              </div>
            </div>
            <div
              className="col-md-6"
              style={{ display: "flex", flexDirection: "row" }}
            >
              <div style={{ flex: "1 0 0", margin: "auto" }}>
                จำนวน: {row.quantity}
              </div>
            </div>
          </div>
          <div>
            <button disabled className="btn" style={{ padding: "10px" }}>
              <DeleteIcon />
            </button>
          </div>
        </div>
      ))}
      <div className="col-12">{data.remark}</div>
    </div>
  );
};
export default InvSellHistory;

const convertDate = (date) => {
  return (
    date.getUTCFullYear() +
    "-" +
    ("0" + (date.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getUTCDate()).slice(-2)
  );
};
