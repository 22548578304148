import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

const ReceivedFilament = ({ totalReceived, totalFilament, totalWaste }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Tooltip
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      open={open}
      title={`ใยดี:${totalFilament} ใยเสีย:${totalWaste}`}
      className="col"
    >
      <Button
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
          margin: "3px",
          backgroundColor: "#d9d9d9",
          border: "1px solid #b3b3b3"
        }}
        onClick={handleTooltipOpen}
      >
        {`ใยที่ได้: ${totalReceived}`}
      </Button>
    </Tooltip>
  );
};

export default ReceivedFilament;
