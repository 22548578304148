import React, { useState, useEffect } from "react";
import { api } from "../../api";

import Dehaze from "@material-ui/icons/Dehaze";
import CardName from "../Inventory/Components/CardName";

const CustData = ({ match }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await api.get(`/cust/getSellHistory${match.url}`);
      setData(res.data.data);
    };
    fetchData();
  }, [match.url]);
  return (
    <main className="container tb">
      <div className="table-head">
        <div className="textcenter flex1">หมายเลขการซื้อ</div>
        <div className="textcenter flex1">วันที่เพิ่มรายการขาย</div>
        <div className="textcenter flex1">ราคารวม</div>
        <div className="textcenter flex1">ตัวเลือก</div>
      </div>

      {data.map((row, idx) => (
        <SellHistory data={row} key={`sellHistory-${idx}`} />
      ))}
    </main>
  );
};

const SellHistory = ({ data }) => {
  const [detail, setDetail] = useState([]);
  const [open, setOpen] = useState(false);
  const fetchData = async () => {
    const res = await api.get(`/cust/orderdetail/${data.orderID}`);
    setDetail(res.data.data);
  };
  return (
    <div className="table-body">
      <div className="flexrow">
        <div className="flex1 center">{data.orderID}</div>
        <div className="flex1 center">{data.submitted_date}</div>
        <div className="flex1 center">{data.totalprice}</div>
        <div
          className="flex1 center btn-shadow"
          style={{
            backgroundColor: "lightgray",
            borderRadius: "10px",
            margin: "2px",
          }}
          onClick={() => {
            setOpen((prev) => !prev);
            fetchData();
          }}
        >
          <Dehaze />
        </div>
      </div>
      {open &&
        detail.map((row, idx) => (
          <div className="table-sub" key={`saledetail-${idx}`}>
            <div className="flexrow">
              <div className="flex2 flexrow">
                <CardName data={row} />
              </div>
              <div className="flex1">จำนวน: {row.quantity}</div>
              <div className="flex1">ราคา/ชิ้น: {row.sale_price}</div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CustData;
