import React from "react";
import CardName from "./Components/CardName";

const Card = ({ value, onClick }) => {
  return (
    <div className="mcard">
      <div className="mcard-body">
        <div
          style={{
            flex: "5 0 0",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            margin: "5px",
          }}
        >
          <div className="mcard-body-left">
            <CardName data={value} />
            <div>ราคา {value.price} ฿</div>
          </div>

          <div
            className="mcard-body-price"
            style={{ padding: "5px", margin: "auto" }}
          >
            <div className="text-center" style={{ flex: "1 0 0 " }}>
              <b>{value.inv_wh}</b>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flex: "3 0 0",
              }}
            >
              <div style={{ flex: "1 0 0", whiteSpace: "nowrap" }}>
                [ล่าง] <b>{value.inv_wh2}</b>
              </div>
              <div style={{ flex: "1 0 0", whiteSpace: "nowrap" }}>
                [บน] <b>{value.inv_wh - value.inv_wh2}</b>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mcard-body-else"
          style={{ padding: "5px", margin: "auto" }}
        >
          <button
            className={`btn btn-block ${
              value.amount ? "btn-added disabled" : "btn-norm"
            }`}
            onClick={() => {
              if (value.amount) {
                return;
              }
              onClick(value);
            }}
          >
            <b>{value.amount ? "✓" : "เพิ่ม"}</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
