import React from "react";

import MonthlyMenu from "./Menu/MonthlyMenu";
import ExtdData from "../Department/Extruding/ExtdData";

const MnmDay = ({ match }) => {
  return (
    <div>
      <div className="text-center">
        <MonthlyMenu match={match} type={"extruding"} />
      </div>

      <h4>
        ข้อมูลของ เดือน {match.params.m} ปี {match.params.y}
      </h4>
      <ExtdData y={match.params.y} m={match.params.m} />
    </div>
  );
};

export default MnmDay;
