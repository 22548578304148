import React from "react";
import { Line } from "react-chartjs-2";

const ProdSaleGraph = ({ data, inv_ft, inv_wh }) => {
  const gdate = data.map((aa) => {
    return aa.M + "/" + aa.Y;
  });

  const gsale = data.map((aa) => {
    return aa.sale;
  });

  const ginv_wh = data.map((aa) => {
    return inv_wh;
  });

  const ginv_all = data.map((aa) => {
    return inv_ft + inv_wh;
  });

  const chartData = {
    labels: gdate,
    datasets: [
      {
        label: "จำนวนขาย",
        data: gsale,
        lineTension: 0,
        backgroundColor: "transparent",
        borderColor: "#007bff",
        borderWidth: 4,
        pointBackgroundColor: "#007bff",
      },
      {
        label: "สินค้าในโกดัง",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgb(25, 130, 53)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgb(25, 130, 53)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgb(25, 130, 53)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: ginv_wh,
      },
      {
        label: "สินค้ารวม",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: ginv_all,
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };
  return <Line data={chartData} options={options} />;
};

export default ProdSaleGraph;
