import React, { useState, useEffect } from "react";

import ReactTable from "react-table";
import "react-table/react-table.css";
import { api } from "../../../api";
import { num } from "../../../Components/System/mosFn";

const InvImportDetailTable = ({ importID, source }) => {
  const [result, setResult] = useState([]);
  const fetchData = async () => {
    const res = await api.post(`/inv/getImport${source}Detail`, [importID]);
    setResult(
      res.data.map((aa) => {
        aa.product =
          num(aa.A) +
          "|" +
          aa.B +
          "|" +
          (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "") +
          aa.C +
          "|" +
          aa.D;
        return aa;
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <ReactTable
        data={result}
        minRows={0}
        showPagination={false}
        columns={[
          {
            Header: "ข้อมูล",
            columns: [
              {
                Header: "รหัสสินค้า",
                accessor: "prodID",
              },
              {
                Header: "สินค้า",
                // accessor: d => d.lastName
                accessor: "product",
              },
            ],
          },
          {
            Header: "รายละเอียดการขาย",
            columns: [
              {
                Header: "จำนวน",
                accessor: "quantity",
              },
            ],
          },
        ]}
        className="-striped -highlight"
      />
    </div>
  );
};

export default InvImportDetailTable;

//orderInfo
