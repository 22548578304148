import React, { useState, useEffect } from "react";
import MonthlyMenu from "./Menu/MonthlyMenu";
import ProductionMenu from "./Menu/ProductionMenu";
import { api } from "../../api";
import MnmMacGraph from "./Table/MnmMacGraph";
import { num } from "../../Components/System/mosFn";

const i = (v) =>
  v.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
const MnmMachine = ({ match }) => {
  const { m, y } = match.params;
  const [mac, setMac] = useState([]);
  const [data, setData] = useState([]);
  const [choosing, setChoosing] = useState(false);
  const fetchData = async () => {
    const res = await api.get(`/mnm/machine/${y}/${m}`);
    setMac(res.data.result);
  };
  const fetchMacData = async (mc) => {
    const res = await api.get(`/mnm/macprod/${mc}/${y}/${m}`);
    setData(res.data.result);
  };
  const sumMeter = () => {
    return data.reduce((sum, d) => {
      return sum + d.meter;
    }, 0);
  };
  const avgMeter = () => {
    return (
      data.reduce((sum, d) => {
        return sum + d.meter;
      }, 0) / data.length
    );
  };

  useEffect(() => {
    fetchData();
    setChoosing(false);
    setData([]);
  }, [m, y]);

  return (
    <div>
      <div className="text-center">
        <MonthlyMenu match={match} type={"machine"} />
      </div>
      <main role="main" className="container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
          <h2>สรุปรายงานเครื่อง</h2>
          <ProductionMenu match={match} />
        </div>

        <h4>
          ข้อมูลของ เดือน {m} ปี {y}
        </h4>
      </main>
      <div className="flexrow" style={{ flexWrap: "wrap" }}>
        {mac &&
          mac.map((row) => (
            <button
              key={`btnA-${row.mac}`}
              className={`btn btn-secondary ${
                choosing && choosing !== row.mac ? "mdisabled" : ""
              }`}
              onClick={() => {
                if (choosing === row.mac) {
                  setChoosing(false);
                  setData([]);
                  return;
                }
                setChoosing(row.mac);
                fetchMacData(row.mac);
              }}
              style={{ margin: "5px auto" }}
            >
              {row.mac}
            </button>
          ))}
      </div>
      {data.length === 0 ? (
        <table className="table table-striped table-condensed">
          <thead>
            <tr>
              <th>เครื่อง</th>
              <th>มีเตอร์</th>
              <th>จำนวนผืน</th>
              <th>ตัวเลือก</th>
            </tr>
          </thead>
          <tbody>
            {mac.map((row, idx) => (
              <tr key={`mnmmac-${idx}`}>
                <td>{row.mac}</td>
                <td>{row.TotalMeter}</td>
                <td>{num(row.piece)}</td>
                <td>
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      if (choosing === row.mac) {
                        setChoosing(false);
                        setData([]);
                        return;
                      }
                      setChoosing(row.mac);
                      fetchMacData(row.mac);
                    }}
                  >
                    ดูเพิ่มเติม
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div>
          <h4>
            เครื่อง {choosing}: รวม {i(sumMeter())} มีเตอร์, เฉลี่ย{" "}
            {i(avgMeter())} มีเตอร์/วัน
          </h4>
          <MnmMacGraph data={data} />
          <table className="table table-striped table-condensed">
            <thead>
              <tr>
                <th>เครื่อง</th>
                <th>มีเตอร์</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, idx) => (
                <tr key={`macdate-${idx}`}>
                  <td>{row.date}</td>
                  <td>{row.meter}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MnmMachine;
