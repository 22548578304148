import React, { useState, useEffect } from "react";
import { api } from "../../api";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map((item) => ({ [item[keyField]]: item })));

const MatPlasticTrans = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await api.get("/mat/listPlasticChangeMonth");
    if (res.data.success) {
      setData(res.data.result);
    } else {
      alert("ไม่สามารถโหลดข้อมูล");
    }
  };
  return (
    <div className="tb">
      <div className="table-head">
        <div className="flex1">วันที่</div>
        <div className="flex3">รายละเอียด</div>
        <div className="flex1 aright">เพิ่ม</div>
        <div className="flex1 aright">ลด</div>
        <div className="flex1 acenter">ตัวเลือก</div>
      </div>

      {data.map((row, idx) => (
        <div className="table-body flexrow" key={`plasimport-${idx}`}>
          <div className="flex1">{row.data_date}</div>
          <div
            className="flex3"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div>{row.des}</div>
            <div>รายละเอียด</div>
          </div>
          <div className="flex1 aright">{row.din}</div>
          <div className="flex1 aright">{row.dout}</div>
          <button
            className="flex1 btn"
            style={{ padding: "0px", background: "lightgray" }}
          >
            <ExpandMoreIcon />
          </button>
        </div>
      ))}
    </div>
  );
};

export default MatPlasticTrans;
