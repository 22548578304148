import React, { useState, useEffect } from "react";
import { api } from "../../../../api";
import CardName from "../../../Inventory/Components/CardName";

import ProdSaleGraph from "../Graph/ProdSaleGraph";

const MnmToolSale = ({ match }) => {
  const [data, setData] = useState(false);
  const [prod, setProd] = useState(false);
  const { prodID } = match.params;

  useEffect(() => {
    const fetchData = async () => {
      const res = await api.post("/mnm/getProdSaleMonth", { prodID: prodID });
      setData(res.data.amount);
      setProd(res.data.info);
    };
    fetchData();
  }, [prodID]);

  return (
    <div className="container">
      <h3 className="text-center" style={{ background: "lightgray" }}>
        การขาย
      </h3>
      {prod && (
        <div
          style={{
            border: "1px solid black",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          สินค้า:
          <CardName data={prod} />
          <div
            className="flexrow"
            style={{ background: "lightgray", margin: "3px" }}
          >
            <div className="flex1 center">สินค้าในโรงงาน: {prod.inv_ft}</div>
            <div className="flex1 center">สินค้าในโกดัง: {prod.inv_wh}</div>
            <div className="flex1 center">
              <b>จำนวนสินค้ารวม: {prod.inv_ft + prod.inv_wh}</b>
            </div>
          </div>
        </div>
      )}
      {data && prod && (
        <ProdSaleGraph data={data} inv_ft={prod.inv_ft} inv_wh={prod.inv_wh} />
      )}
      <main className="tb">
        <div className="table-head">
          <div className="textcenter flex1">เดือน</div>
          <div className="textcenter flex1">ปี</div>
          <div className="textcenter flex2">จำนวน</div>
          <div className="textcenter foption"></div>
        </div>
        {data &&
          data.map((row) => (
            <ProdMonth
              row={row}
              prodID={prodID}
              key={`${prodID}m${row.M}y${row.Y}`}
            />
          ))}
      </main>
    </div>
  );
};

const ProdMonth = ({ row, prodID }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  return (
    <div className="table-body">
      <div className="flexrow">
        <div className="flex1 center">{row.M}</div>
        <div className="flex1 center">{row.Y}</div>
        <div className="flex2 center">{row.sale}</div>
        <button className="btn btn-secondary foption cc" onClick={handleOpen}>
          ดู
        </button>
      </div>
      {open && (
        <div className="flexrow">
          <div>test</div>
        </div>
      )}
    </div>
  );
};

export default MnmToolSale;
