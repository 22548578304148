import React, { useState } from "react";
import { api } from "../../../api";

import GetAppIcon from "@material-ui/icons/GetApp";

const ProdAdding = ({ reload }) => {
  const [isOpenAdd, setOpenAdd] = useState(false);

  const [A, setA] = useState("");
  const [B, setB] = useState("");
  const [unit, setUnit] = useState(0);
  const [B2, setB2] = useState("");
  const [C, setC] = useState("");
  const [D, setD] = useState("");
  const [grade, setGrade] = useState("A");
  const [weight, setWeight] = useState("");
  const [pieceOven, setPieceOven] = useState("");
  const [price, setPrice] = useState("");
  const [downloading, setDownloading] = useState(false);

  const onClickOpenAdd = () => {
    setOpenAdd((prev) => !prev);
  };

  const onChangeA = (e) => {
    setA(e.target.value);
  };
  const onChangeB = (e) => {
    setB(e.target.value);
  };
  const onChangeB2 = (e) => {
    setB2(e.target.value);
  };
  const onChangeC = (e) => {
    setC(
      isNaN(e.target.value) || e.target.value === ""
        ? ""
        : parseInt(e.target.value)
    );
  };
  const onChangeD = (e) => {
    setD(e.target.value);
  };

  const onClickUnit = () => {
    if (unit === 0) {
      setUnit(1);
    } else {
      setUnit(0);
    }
  };

  const onChangeGrade = (e) => {
    setGrade(e.target.value);
  };
  const onChangeWeight = (e) => {
    setWeight(e.target.value);
  };
  const onChangePieceOven = (e) => {
    setPieceOven(e.target.value);
  };
  const onChangePrice = (e) => {
    setPrice(e.target.value);
  };

  const onSubmitAdd = (e) => {
    e.preventDefault();
    if (!(A < 1)) {
      return alert("ตรวจสอบขนาดใย เช่น 0.25");
    }
    if (isNaN(B)) {
      return alert("ตรวจสอบขนาดตา ตัวเลข");
    }
    if (C / 1 !== C) {
      return alert("ตรวจสอบจำนวนตา");
    }
    if (isNaN(D)) {
      return alert("ตรวจสอบความยาว ตัวเลข");
    }
    if (isNaN(weight) || isNaN(pieceOven) || isNaN(price)) {
      return alert("กรอกข้อมูลเป็นตัวเลข");
    }
    const addData = {
      A: A,
      B: B,
      unitB: unit,
      B2: unit === 0 ? 0 : B2,
      C: C,
      D: D,
      grade: grade,
      weight: weight,
      pieceOven: pieceOven,
      price: price,
    };
    api.post("/inv/newProduct", addData).then((xx) => {
      alert(xx.data);
      reload();
      setOpenAdd(false);
      setA("");
      setB("");
      setUnit(0);
      setB2("");
      setC("");
      setD("");
      setGrade("A");
      setWeight("");
      setPieceOven("");
      setPrice("");
    });
  };

  const handleDownload = () => {
    const d = new Date();
    const dd = d.getDate();
    const mm = d.getMonth() + 1;
    const DD = dd < 10 ? "0" + dd : dd;
    const MM = mm < 10 ? "0" + mm : mm;
    const YY = d.getFullYear() - 1957;
    setDownloading(true);
    api
      .get("/inv/download/stock", { responseType: "arraybuffer" })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `stock${"" + YY + MM + DD}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      });
  };

  const flexRow = { display: "flex", flexDirection: "row" };
  const flexCol = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  };

  return (
    <div
      style={{ marginBottom: "5px", display: "flex", justifyContent: "center" }}
    >
      <button
        className="btn btn-secondary"
        onClick={onClickOpenAdd}
        style={{ marginRight: "5px" }}
      >
        เพิ่มรายการสินค้า
      </button>{" "}
      <button
        className="btn btn-secondary"
        onClick={handleDownload}
        disabled={downloading}
      >
        <GetAppIcon fontSize="small" />
        ดาวน์โหลด
      </button>
      {/* <a href="https://www.thesitewizard.com/" target="_blank">thesitewizard.com</a> */}
      {isOpenAdd && (
        <form
          style={{ backgroundColor: "#e6e6e6", padding: "10px" }}
          onSubmit={(e) => onSubmitAdd(e)}
        >
          <div className="input-group">
            <input
              className="form-control"
              placeholder="ขนาดใย"
              onChange={onChangeA}
              required
            />
            <input
              className="form-control"
              placeholder="ขนาดตา"
              onChange={onChangeB}
              required
            />
            {unit === 1 && (
              <input
                className="form-control"
                placeholder="(ส่วน)"
                onChange={onChangeB2}
              />
            )}
            <button
              type="button"
              className="btn btn-light"
              onClick={onClickUnit}
            >
              {unit === 0 ? "cm" : '"'}
            </button>

            <input
              className="form-control"
              placeholder="จำนวนตา"
              onChange={onChangeC}
              value={C}
              required
            />
            <input
              className="form-control"
              placeholder="ความยาว"
              onChange={onChangeD}
              required
            />
          </div>
          <div style={flexCol}>
            <div style={flexRow}>
              <div>
                เกรด
                <input
                  className="form-control"
                  onChange={onChangeGrade}
                  value={grade}
                  required
                />
              </div>
              <div>
                น้ำหนัก
                <input
                  className="form-control"
                  onChange={onChangeWeight}
                  value={weight}
                  required
                />
              </div>
              <div>
                อบ/เตา
                <input
                  className="form-control"
                  onChange={onChangePieceOven}
                  value={pieceOven}
                  required
                />
              </div>
              <div>
                ราคา
                <input
                  className="form-control"
                  onChange={onChangePrice}
                  value={price}
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-secondary"
              style={{ margin: "3px" }}
            >
              ยืนยันเพิ่มข้อมูล
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ProdAdding;
