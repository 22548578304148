import React from "react";
import { Link } from "react-router-dom";
import { withAuth } from "../../AuthContext";

const MenuSub = ({ auth, match, link }) => {
  return (
    <div
      className="btn-group"
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "3px 10px",
      }}
    >
      {link.map((row, idx) => {
        if (row.auth && auth.access[row.auth] !== 1) return "";
        return (
          <Link
            to={`${match}/${row.to}`}
            className="btn"
            style={{
              backgroundColor: "lightgray",
              border: "1px solid black",
            }}
            key={`link-${idx}`}
          >
            {row.name}
          </Link>
        );
      })}
    </div>
  );
};

export default withAuth(MenuSub);
