import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { num } from "../../../Components/System/mosFn";

const MnmDayTable = ({ data }) => {
  const weekday = [
    "อาทิตย์",
    "จันทร์",
    "อังคาร",
    "พุธ",
    "พฤหัส",
    "ศุกร์",
    "เสาร์",
  ];
  data = data.map((aa) => {
    const d = new Date(aa.DataDate);
    aa.weekday = weekday[d.getDay()];
    return aa;
  });

  return (
    <div>
      <ReactTable
        data={data}
        minRows={0}
        showPagination={false}
        pageSize={35}
        columns={[
          {
            Header: "ข้อมูลประจำวัน",
            columns: [
              {
                Header: "วันที่",
                accessor: "DataDate",
              },
              {
                Header: "วัน",
                accessor: "weekday",
              },
            ],
          },
          {
            Header: "ข้อมูล",
            columns: [
              {
                Header: "มีเตอร์",
                accessor: "TotalMeter",
              },
              {
                Header: "จำนวนผืน",
                id: "piece",
                accessor: (d) => num(d.piece),
              },
              {
                Header: "น้ำหนักใย",
                id: "plasticw",
                accessor: (d) => num(d.plasticw),
              },
            ],
          },
          {
            Header: "มูลค่าประเมิน",
            columns: [
              {
                Header: "มูลค่า(หั่งเช้ง)",
                id: "totalValue",
                accessor: (d) => num(d.totalvalue),
              },
              {
                Header: "มูลค่า(75%)",
                id: "totalValue75",
                accessor: (d) => num(d.totalvalue * 0.75),
              },
            ],
          },
        ]}
        className="-striped -highlight"
      />
    </div>
  );
};

export default MnmDayTable;
