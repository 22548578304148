import React, { useEffect } from "react";
import { loadCSS } from "fg-loadcss";
import { Link } from "react-router-dom";
import PatchNote from "./PatchNote";
import { withAuth } from "../../AuthContext";

const Home = ({ auth }) => {
  useEffect(() => {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.11.2/css/all.css",
      document.querySelector("#font-awesome-css")
    );
  }, []);
  return (
    <div>
      <main role="main" className="container">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
          <h2>
            <i className="fas fa-home"></i> หน้าหลัก
          </h2>
        </div>
        {auth.level !== "manager" && <PatchNote />}

        <h3> บริหาร </h3>
        <div className="flexrow">
          <Link
            to="/management/prodTool"
            className="btn flex1 mg5"
            style={{ backgroundColor: "gold" }}
          >
            <i className="fas fa-chart-line fa-lg"></i>
            <hr />
            วิเคราะห์การผลิต
          </Link>
          <Link to="/dept/weaving" className="btn btn-info flex1 mg5">
            <i className="fas fa-list-alt fa-lg"></i>
            <hr />
            รายการทอ
          </Link>
          <Link to="/inventory" className="btn btn-info flex1 mg5">
            <i className="fas fa-boxes fa-lg"></i>
            <hr />
            สินค้าในคลัง
          </Link>
        </div>
        <div className="flexrow">
          <Link to="/management" className="btn btn-info flex1 mg5">
            <i className="fas fa-copy fa-lg"></i>
            <hr />
            รายงานการผลิต
          </Link>
          <Link
            to="/inventory/importFT"
            className="btn btn-secondary flex1 mg5"
          >
            <i className="fas fa-file-invoice fa-lg"></i>
            <hr />
            การเคลื่อนไหวสินค้า
          </Link>
          <Link
            to="/dept/weaving/report"
            className="btn btn-secondary flex1 mg5"
          >
            <i className="fas fa-file-invoice fa-lg"></i>
            <hr />
            รายงานการทอ
          </Link>
          <Link
            to="/dept/extruding/data"
            className="btn btn-secondary flex1 mg5"
          >
            <i className="fas fa-file-invoice fa-lg"></i>
            <hr />
            ข้อมูลฉีดใย
          </Link>
        </div>
        <hr />

        {auth.level !== "manager" && (
          <span>
            <h3> ฝ่ายขาย </h3>
            <div className="row">
              <div className="col-4 d-flex align-items-stretch">
                <Link to="/inventory" className="btn btn-info btn-block">
                  <i className="fas fa-boxes fa-lg"></i>
                  <hr />
                  สินค้าในโกดัง
                </Link>
              </div>
              <div className="col-4 d-flex align-items-stretch">
                <Link
                  to="/inventory/transport"
                  className="btn btn-info btn-block"
                >
                  <i className="fas fa-exchange-alt fa-lg"></i>
                  <hr />
                  เคลื่อนย้ายสินค้า
                </Link>
              </div>
              <div className="col-4 d-flex align-items-stretch">
                <Link
                  to="/inventory/importFT"
                  className="btn btn-dark btn-block"
                >
                  <i className="fas fa-file-invoice fa-lg"></i>
                  <hr />
                  ประวัติการนำเข้า
                </Link>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-4 d-flex align-items-stretch">
                <Link to="/inventory/sell" className="btn btn-info btn-block">
                  <i className="fas fa-shopping-cart fa-lg"></i>
                  <hr />
                  ขายสินค้า
                </Link>
              </div>
              <div className="col-4 d-flex align-items-stretch">
                <Link
                  to="/inventory/sell/history"
                  className="btn btn-dark btn-block"
                >
                  <i className="fas fa-file-invoice fa-lg"></i>
                  <hr />
                  บิลขาย
                </Link>
              </div>
            </div>

            <br />

            <div className="row d-flex align-items-stretch">
              <div className="col-4">
                <Link to="/customer" className="btn btn-info btn-block">
                  <i className="fas fa-users fa-lg"></i>
                  <hr />
                  ข้อมูลลูกค้า
                </Link>
              </div>
            </div>

            <hr />

            <h3> ฝ่ายผลิต </h3>
            <div className="row">
              <div className="col-4 d-flex align-items-stretch">
                <Link to="/dept/weaving" className="btn btn-info btn-block">
                  <i className="fab fa-gitter fa-lg"></i>
                  <hr />
                  แผนกทอ
                </Link>
              </div>
              <div className="col-4 d-flex align-items-stretch">
                <Link
                  to="/dept/weaving/add"
                  className="btn btn-success btn-block"
                >
                  <i className="fas fa-list-alt fa-lg"></i>
                  <hr />
                  เพิ่มมีเตอร์
                </Link>
              </div>
              <div className="col-4 d-flex align-items-stretch">
                <Link
                  to="/dept/weaving/report"
                  className="btn btn-dark btn-block"
                >
                  <i className="fas fa-file-invoice fa-lg"></i>
                  <hr />
                  ประวัติเพิ่มมีเตอร์
                </Link>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-4 d-flex align-items-stretch">
                <Link to="/dept/extruding" className="btn btn-info btn-block">
                  <i className="fas fa-grip-lines-vertical fa-lg"></i>
                  <hr />
                  แผนกฉีดใย
                </Link>
              </div>
              <div className="col-4 d-flex align-items-stretch">
                <Link
                  to="/dept/extruding/data"
                  className="btn btn-dark btn-block"
                >
                  <i className="fas fa-file-invoice fa-lg"></i>
                  <hr />
                  ข้อมูลฉีดใย
                </Link>
              </div>
            </div>

            <hr />

            <h3> วัตถุดิบ </h3>
            <div className="row">
              <div className="col-4 d-flex align-items-stretch">
                <Link to="/material/plastic" className="btn btn-info btn-block">
                  <i className="fas fa-braille fa-lg"></i>
                  <hr />
                  เม็ดพลาสติก
                </Link>
              </div>
              <div className="col-4 d-flex align-items-stretch">
                <Link
                  to="/material/plastic/transfer"
                  className="btn btn-dark btn-block"
                >
                  <i className="fas fa-file-invoice fa-lg"></i>
                  <hr />
                  ประวัติเพิ่มเม็ด
                </Link>
              </div>
            </div>

            <hr />

            <h3> สรุปผล </h3>
            <div className="row">
              <div className="col-4 d-flex align-items-stretch">
                <Link to="/management" className="btn btn-info btn-block">
                  <i className="fas fa-copy fa-lg"></i>
                  <hr />
                  รายงาน
                </Link>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-4 d-flex align-items-stretch">
                <Link to="/employees" className="btn btn-info btn-block">
                  <i className="fas fa-id-card fa-lg"></i>
                  <hr />
                  พนักงาน
                </Link>
              </div>
            </div>
          </span>
        )}
      </main>
    </div>
  );
};

export default withAuth(Home);
