import React, { useEffect, useState } from "react";
import { api } from "../../../api";

import WeavDataRow from "./WeavRpt/WeavDataRow";
import EditReason from "../../../Components/Others/EditReason";
import { withAuth } from "../../../AuthContext";

const WeavReport = ({ auth }) => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    await api.get("/weav/getWeavData/").then((xx) => {
      if (!xx.data.success) return alert(xx.data.error);
      setData(xx.data.result);
    });
  };

  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(
    { submitData: "" },
    { postUrl: "" },
    { name: "" },
    { reason: "" }
  );
  const handleChange = (e) => {
    const newEditData = editData;
    newEditData.reason = e.target.value;
    setEditData(newEditData);
  };
  const handleClickDeleteData = async (data, postUrl, name) => {
    setEditData({ submitData: data, postUrl: postUrl, name: name });
    setOpen(true);
  };

  const handleSubmit = async () => {
    setOpen(false);
    let submitData = editData.submitData;
    let postUrl = editData.postUrl;
    if (auth.level !== "admin") {
      submitData = {
        name: editData.name,
        query: editData.postUrl,
        data: editData.submitData,
        user: auth.user,
        reason: editData.reason,
      };
      postUrl = "/request";
    }
    const res = await api.post(postUrl, submitData);
    if (res.data.success) {
      alert(res.data.result);
      fetchData();
    } else {
      alert(
        "ไม่สำเร็จ โปรดแจ้งข้อความนี้" + JSON.parse(res.data.error).sqlMessage
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgb(197, 197, 197)",
        padding: "5px",
      }}
    >
      {open && (
        <EditReason
          open={open}
          onClose={handleClose}
          onSubmit={handleSubmit}
          editData={editData}
          onChange={handleChange}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: "1 0 0", textAlign: "center" }}>หมายเลข</div>
        
        <div style={{ flex: "2 0 0", textAlign: "center" }}>
          วันที่ทอ
        </div>
        <div style={{ flex: "2 0 0", textAlign: "center" }}>
          วันที่ลงข้อมูล
        </div>
        <div style={{ flex: "1 0 0", textAlign: "center" }}>ผู้ลงข้อมูล</div>
        <div style={{ flex: "3 0 0", textAlign: "center" }}>ตัวเลือก</div>
      </div>
      {data.map((row, idx) => (
        <WeavDataRow
          key={`weavData-${idx}`}
          data={row}
          handleClickDeleteData={handleClickDeleteData}
          source={"inFT"}
        />
      ))}
    </div>
  );
};

export default withAuth(WeavReport);
