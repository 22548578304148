import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import MnmOrderDetailTable from "./MnmOrderDetailTable";
import { api } from "../../../api";
import { withAuth } from "../../../AuthContext";

const MnmOrdersTable = ({ auth, data, reload }) => {
  const handleOnClickDelete = async orderID => {
    let submitData = { orderID: orderID };
    let postUrl = "/inv/sellDelete";
    if (auth.level !== "admin") {
      submitData = {
        name: "Delete Order",
        query: "/inv/sellDelete",
        data: { orderID: orderID },
        user: auth.user
      };
      postUrl = "/request";
    }
    const res = await api.post(postUrl, submitData);
    if (res.data.success) {
      alert(res.data.result);
      reload();
    } else {
      alert(
        "ไม่สำเร็จ โปรดแจ้งข้อความนี้" + JSON.parse(res.data.error).sqlMessage
      );
    }
  };

  const columns = [
    {
      Header: "ข้อมูลคำสั่งซื้อ",
      columns: [
        {
          Header: "หมายเลข",
          accessor: "orderID"
        },
        {
          Header: "วันที่",
          accessor: "orderDate"
        }
      ]
    },
    {
      Header: "รายละเอียด",
      columns: [
        {
          Header: "ชื่อผู้ซื้อ",
          accessor: "custName"
        },
        {
          Header: "ราคารวม",
          accessor: "totalprice"
        }
      ]
    },
    {
      Header: "เพิ่มเติม",
      columns: [
        {
          Header: "ตัวเลือก",
          id: "test",
          accessor: d => (
            <button
              className="btn btn-secondary"
              onClick={() => handleOnClickDelete(d.orderID)}
            >
              {auth.level === "admin" ? "ลบบิล" : "แจ้งลบบิล"}
            </button>
          )
        }
      ]
    }
  ];
  return (
    <div>
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={20}
        className="-striped -highlight"
        SubComponent={row => {
          return (
            <div style={{ padding: "5px" }}>
              <MnmOrderDetailTable orderID={row.original.orderID} />
            </div>
          );
        }}
      />
    </div>
  );
};

export default withAuth(MnmOrdersTable);
