import React from "react";
import { Route } from "react-router-dom";
import ExtdMain from "./ExtdMain";
import ExtdData from "./ExtdData";
import Header from "../../../Components/Header/Header";
import ProtectedRoute from "../../../Components/System/ProtectedRoute";
import Menu from "../../../Components/UI/Menu";

const Extruding = ({ match }) => {
  const link = [
    { name: "เพิ่มรายการฉีดใย", to: "add", auth: "prodExtdAdd" },
    { name: "ดูรายการฉีดใย", to: "data" },
  ];
  return (
    <div className="container">
      <Header name="แผนกฉีดใย" />
      <Menu match={match.path} link={link} />
      <ProtectedRoute
        access="prodExtdAdd"
        exact
        path={`${match.path}/Add`}
        component={ExtdMain}
      />
      <Route exact path={`${match.path}/data`} component={ExtdData} />
    </div>
  );
};

export default Extruding;
