import React from "react";

import SearchProduct from "./SearchProduct";
import CardName from "../../Pages/Inventory/Components/CardName";

const ChooseProduct = ({
  onChange,
  onClearSearch,
  search,
  onClickAdd,
  filtered
}) => {
  return (
    <div>
      <SearchProduct
        onChange={onChange}
        onClick={onClearSearch}
        value={search}
      />
      <div
        style={{
          display: "block",
          width: "100%",
          overflow: "scroll",
          backgroundColor: "lightgrey"
        }}
      >
        {filtered.map((row, idx) => {
          return (
            <div
              key={`filtered-${idx}`}
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "5px",
                padding: "5px",
                borderRadius: "5px",
                backgroundColor: "rgb(240, 240, 240)"
              }}
            >
              <div className="col-10">
                <div>
                  <CardName data={row} />
                </div>
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <div className="amount-bg">โรงงาน: {row.inv_ft}</div>
                  <div className="amount-bg">
                    โกดัง[1]: {row.inv_wh - row.inv_wh2}
                  </div>
                  <div className="amount-bg">โกดัง[2]: {row.inv_wh2}</div>
                </div>
              </div>
              <button
                className={`btn col-2 btn-secondary ${
                  row.nmove ? "mdisabled" : ""
                }`}
                style={{ borderRadius: "10px" }}
                onClick={() => onClickAdd(row)}
              >
                {filtered[idx].nmove ? "✓" : "เพิ่ม"}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChooseProduct;
