import React from "react";
import { Route, Redirect } from "react-router-dom";
import { withAuth } from "../../AuthContext";

const CheckAccess = ({ access, auth }) => {
  return auth.access[access] === 1 ? (
    ""
  ) : (
    <Redirect
      to={{
        pathname: "/",
        // state: { from: props.location },
      }}
    />
  );
};

export default withAuth(CheckAccess);
