import React, { useState } from "react";
import DatePicker from "react-datepicker";

import DeleteIcon from "@material-ui/icons/Delete";
import { api } from "../../../api";
import { withAuth } from "../../../AuthContext";

const PlasticImport = ({
  auth,
  data,
  onClickDel,
  onClickAmount,
  onChangeAmount,
  reload,
}) => {
  const [orderedDate, setOrderedDate] = useState(new Date());
  const [receivedDate, setReceivedDate] = useState(new Date());
  const [detail, setDetail] = useState("");

  const handleChangeDetail = (e) => {
    setDetail(e.target.value);
  };

  const handleSubmit = async () => {
    const submitData = {
      orderedDate: convertDate(orderedDate),
      receivedDate: convertDate(receivedDate),
      data: data,
      detail: detail,
      user: auth.user,
    };
    const res = await api.post("/mat/importPlastic", submitData);
    if (res.data.success) {
      alert(res.data.result);
      reload();
      return clearData();
    } else {
      return alert(res.data.error);
    }
  };

  const clearData = () => {
    setOrderedDate(new Date());
    setReceivedDate(new Date());
    setDetail("");
  };

  const convertDate = (date) => {
    return (
      date.getUTCFullYear() +
      "-" +
      ("0" + (date.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getUTCDate()).slice(-2)
    );
  };

  return (
    <div
      style={{
        border: "1px solid black",
        padding: "10px",
        margin: "0 10px 10px 10px",
      }}
    >
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">วันที่สั่งซื้อ</label>
        <div className="col">
          <DatePicker
            className="form-control"
            selected={orderedDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setOrderedDate(date);
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">วันที่รับเม็ด</label>
        <div className="col">
          <DatePicker
            className="form-control"
            selected={receivedDate}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => {
              setReceivedDate(date);
            }}
          />
        </div>
      </div>
      <table className="table table-striped table-condensed" id="add-list">
        <thead className="thead-light">
          <tr>
            <th>เม็ดพลาสติก</th>
            <th>จำนวนถุง</th>
            <th>น้ำหนักรวม</th>
            <th>ตัวเลือก</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, idx) => (
            <tr key={`importing-${idx}`}>
              <th>{row.plasticName}</th>
              <th className="row">
                <div
                  className="cart-amount-btn"
                  style={{ backgroundColor: "lightgrey" }}
                  onClick={() => {
                    onClickAmount("-", idx);
                  }}
                >
                  −
                </div>
                <input
                  className="cart-amount-input text-center"
                  value={row.amount}
                  onChange={(e) => onChangeAmount(e, idx)}
                  style={{ width: "50px", backgroundColor: "white" }}
                />
                <div
                  className="cart-amount-btn"
                  style={{ backgroundColor: "lightgrey" }}
                  onClick={() => {
                    onClickAmount("+", idx);
                  }}
                >
                  +
                </div>
              </th>
              <th>{row.amount * row.plasticWeight}</th>
              <th>
                <button className="btn" onClick={() => onClickDel(idx)}>
                  <DeleteIcon fontSize="small" />
                </button>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="form-group">
        <label>รายละเอียดเพิ่มเติม:</label>
        <textarea
          className="form-control"
          rows="4"
          value={detail}
          onChange={handleChangeDetail}
        ></textarea>
      </div>
      <div className="text-center">
        <button className="btn btn-success" onClick={handleSubmit}>
          เพิ่มเม็ดเข้าสต็อค
        </button>
      </div>
    </div>
  );
};

export default withAuth(PlasticImport);
