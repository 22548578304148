import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import MnmSaleDetailTable from "./MnmSaleDetailTable";

const MnmSaleTable = ({ data, time }) => {
  const columns = [
    {
      Header: "ข้อมูลคำสั่งซื้อ",
      columns: [
        {
          Header: "รหัสสินค้า",
          accessor: "prodID"
        },
        {
          Header: "สินค้า",
          accessor: "product"
        }
      ]
    },
    {
      Header: "รายละเอียด",
      columns: [
        {
          Header: "จำนวน",
          accessor: "totalq"
        }
      ]
    }
    // ,{
    //   Header: "เพิ่มเติม",
    //   columns: [
    //     {
    //       Header: "ตัวเลือก",
    //       id: "test",
    //       accessor: d => <button className="btn btn-secondary">แก้ไขบิล</button>
    //     }
    //   ]
    // }
  ];
  return (
    <div>
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={20}
        className="-striped -highlight"
        SubComponent={row => {
          return (
            <div style={{ padding: "5px" }}>
              <MnmSaleDetailTable prodID={row.original.prodID} time={time} />
            </div>
          );
        }}
      />
    </div>
  );
};

export default MnmSaleTable;
