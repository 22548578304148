import React, { useState, useEffect } from "react";

const AddFilament = ({ onSubmit, type }) => {
  const [HR, setHR] = useState(0);
  const [M, setM] = useState(0);
  const [pcs, setPcs] = useState(0);
  const [W, setW] = useState(0);
  const [TW, setTW] = useState(0);
  const [TF, setTF] = useState(0);

  const handleOnSubmit = e => {
    if (isNaN(TF)) {
      e.preventDefault();
      return alert("ตรวจสอบตัวเลข");
    }
    const data = { HR: HR, M: M, pcs: pcs, W: W, TW: TW, TF: TF };
    onSubmit(data);
    e.preventDefault();
  };

  const round = num => {
    return Math.round(num * 100) / 100;
  };

  useEffect(() => {
    if (type === 1) {
      setW(0.32);
    } else if (type === 2) {
      setW(0.22);
    }
  }, [type]);

  const onChangePcs = e => {
    const value = parseInt(e.target.value);
    setPcs(isNaN(value) ? 0 : value);
  };

  const onChangeW = e => {
    setW(e.target.value);
    // const value = round(parseFloat(e.target.value));
    // setW(isNaN(value) ? 0 : value);
  };

  const onChangeTW = e => {
    setTW(e.target.value);
    // const value = round(parseFloat(e.target.value));
    // setTW(isNaN(value) ? 0 : value);
  };

  const onChangeHR = e => {
    setHR(e.target.value);
  };
  const onChangeM = e => {
    setM(e.target.value);
  };

  const hr = [];
  for (let i = 0; i < 24; i++) {
    hr.push(i);
  }
  const m = [];
  for (let i = 0; i < 60; i++) {
    m.push(i);
  }

  useEffect(() => {
    const value = round(TW - pcs * W);
    setTF(value);
  }, [TW, pcs, W]);

  return (
    <form
      style={{ backgroundColor: "rgb(240,240,240)" }}
      onSubmit={handleOnSubmit}
    >
      <div className="modal-body">
        <h3 className="text-center">
          เพิ่มข้อมูล <b>{type === 1 ? "หลอดปีก" : "หลอดสมอ"}</b>
        </h3>
        <div className="form-row">
          <div className="col-md-4">
            <label>เวลา</label>
            <div className="input-group form-group">
              <select className="form-control" onChange={onChangeHR}>
                {hr.map(row => (
                  <option key={`hr-${row}`} value={row}>
                    {row}
                  </option>
                ))}
              </select>
              <select className="form-control" onChange={onChangeM}>
                {m.map(row => (
                  <option key={`m-${row}`} value={row}>
                    {row}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form-group col-md-2">
            <label>จำนวนหลอด</label>
            <input
              type="number"
              className="form-control"
              value={pcs}
              onChange={onChangePcs}
            />
          </div>
          <div className="form-group col-md-6">
            <label>น้ำหนักหลอด (กรัม)</label>
            <input
              type="text"
              className="form-control"
              value={W}
              onChange={onChangeW}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>
              <strong>น้ำหนักรวมหลอด (กรัม)</strong>
            </label>
            <input
              type="text"
              className="form-control"
              value={TW}
              onChange={onChangeTW}
            />
          </div>
          <div className="form-group col-md-6">
            <label>
              <strong>น้ำหนักใย (กรัม)</strong>
            </label>
            <input type="text" className="form-control" value={TF} disabled />
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button
          type="submit"
          className="btn btn-success"
          onClick={handleOnSubmit}
        >
          ยืนยัน
        </button>
      </div>
    </form>
  );
};

export default AddFilament;
