import React, { useState } from "react";
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Header from "../../Components/Header/Header";
import { withAuth } from "../../AuthContext";

const MnmMain = ({ match, auth }) => {
  const d = new Date();
  const [year, setYear] = useState(d.getFullYear());
  const [month, setMonth] = useState(d.getMonth() + 1);

  function up(to) {
    if (to === "month") {
      month === 12 ? setMonth(1) : setMonth(month + 1);
    } else {
      setYear(year + 1);
    }
  }
  function dn(to) {
    if (to === "month") {
      month === 1 ? setMonth(12) : setMonth(month - 1);
    } else {
      setYear(year - 1);
    }
  }

  return (
    <div className="container">
      <Header name="รายงาน" />
      <br />
      <div className="row">
        <div className="col-md-6 text-center">
          <h2>รายงานประจำเดือน</h2>
          <hr />
          <div className="row">
            <div className="col-6">
              <h4 style={{ textAlign: "center" }}>ปี</h4>
              <div className="btn-group-vertical btn-block">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => up("year")}
                >
                  <ExpandLessIcon />
                </button>
                <button className="btn btn-secondary" disabled>
                  {year}
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => dn("year")}
                >
                  <ExpandMoreIcon />
                </button>
              </div>
            </div>
            <div className="col-6">
              <h4 style={{ textAlign: "center" }}>เดือน</h4>
              <div className="btn-group-vertical btn-block">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => up("month")}
                >
                  <ExpandLessIcon />
                </button>
                <button className="btn btn-secondary" id="month" disabled>
                  {month}
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => dn("month")}
                >
                  <ExpandMoreIcon />
                </button>
              </div>
            </div>
          </div>
          <br />

          {auth.access["mnmMkt"] === 1 && (
            <span>
              <div style={{ alignItems: "center" }}>
                <h4>การขาย</h4>
              </div>
              <div className="row">
                <div className="col btn-group">
                  <Link
                    to={`${match.path}/monthly/sale/${year}/${month}`}
                    className="btn btn-lg btn-outline-info"
                  >
                    รายการขาย
                  </Link>
                  <Link
                    to={`${match.path}/monthly/orders/${year}/${month}`}
                    className="btn btn-lg btn-outline-info"
                  >
                    บิลขาย
                  </Link>
                </div>
              </div>
              <br />
            </span>
          )}

          {auth.access["mnmProd"] === 1 && (
            <span>
              <div style={{ alignItems: "center" }}>
                <h4>การผลิต</h4>
              </div>
              <div className="row">
                <div className="btn-group col-12">
                  <Link
                    to={`${match.path}/monthly/day/${year}/${month}`}
                    className="btn btn-lg btn-outline-info"
                  >
                    สรุปประจำวัน
                  </Link>
                  <Link
                    to={`${match.path}/monthly/product/${year}/${month}`}
                    className="btn btn-lg btn-outline-info"
                  >
                    สรุปรายการผลิต
                  </Link>
                  <Link
                    to={`${match.path}/monthly/machine/${year}/${month}`}
                    className="btn btn-lg btn-outline-info"
                  >
                    สรุปรายงานเครื่อง
                  </Link>
                </div>

                <div className="col-12 btn-group">
                  <Link
                    to={`${match.path}/monthly/extruding/${year}/${month}`}
                    className="btn btn-lg btn-outline-info"
                  >
                    รายงานฉีดใย
                  </Link>
                </div>
              </div>
            </span>
          )}

          <br />
        </div>

        <div className="col-md-6 text-center">
          <h2>สรุปบริษัท</h2>
          <hr />

          <div className="row">
            <div className="col-12">
              <Link
                to={`${match.path}/summary/inventory`}
                className="btn btn-block btn-lg btn-outline-info"
              >
                สินค้าในคลัง
              </Link>
            </div>
            {auth.access["mnmMkt"] === 1 && (
              <div className="col-12">
                <Link
                  to={`${match.path}/summary/sale`}
                  className="btn btn-block btn-lg btn-outline-info"
                >
                  ยอดขายย้อนหลัง
                </Link>
              </div>
            )}
          </div>

          <br />
          {auth.access["mnm"] === 1 && (
            <div>
              <h2>เครื่องมือ</h2>
              <hr />

              <div className="row">
                {auth.access["mnmReq"] === 1 && (
                  <div className="col-12">
                    <Link
                      to={`${match.path}/request`}
                      className="btn btn-block btn-lg btn-outline-info"
                    >
                      คำขอแก้ไข
                    </Link>
                  </div>
                )}
                <div className="col-12">
                  <Link
                    to={`${match.path}/prodTool`}
                    className="btn btn-block btn-lg btn-outline-info"
                  >
                    วิเคราะห์การผลิต
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to={`${match.path}/saleTool`}
                    className="btn btn-block btn-lg btn-outline-info"
                  >
                    วิเคราะห์การขาย
                  </Link>
                </div>
                <div className="col-12">
                  <Link
                    to={`${match.path}/dashboard`}
                    className="btn btn-block btn-lg btn-outline-info"
                  >
                    Dashboard
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withAuth(MnmMain);
