import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import Fab from "@material-ui/core/Fab";

import AddPlastic from "./ExtdMain/AddPlastic";
import AddFilament from "./ExtdMain/AddFilament";
import AddWaste from "./ExtdMain/AddWaste";
import { api } from "../../../api";

const ExtdMain = () => {
  const [isAddingPlas, setAddingPlas] = useState(true);
  const [isAddingFila, setAddingFila] = useState(false);
  const [filaType, setFilaType] = useState(false);
  const [selectedSize, setSelectedSize] = useState(false);
  const [plastic, setPlastic] = useState([]);
  const [type1, setType1] = useState([]);
  const [type2, setType2] = useState([]);
  const [waste, setWaste] = useState([]);
  const [sumWaste, setSumWaste] = useState(0);
  const [extdDate, setExtdDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");

  const clearData = () => {
    fetchData();
    setAddingPlas(true);
    setAddingFila(false);
    setFilaType(false);
    setSelectedSize(false);
    setPlastic([]);
    setType1([]);
    setType2([]);
    setWaste([]);
    setSumWaste([]);
    setExtdDate(new Date());
    setRemarks("");
  };

  const clickAddPlas = () => {
    setAddingPlas((prev) => !prev);
  };

  const clickAddFila = (type) => {
    setAddingFila(true);
    setFilaType(type);
  };

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const onSubmitPlastic = () => {
    setAddingPlas(false);
  };

  const onSubmitWaste = (data) => {
    const newWaste = [...waste];
    newWaste.push(data);
    setWaste(newWaste);
  };

  const onSubmitFilament = (data) => {
    if (filaType === 1) {
      const newType1 = [...type1];
      newType1.push(data);
      setType1(newType1);
    } else if (filaType === 2) {
      const newType2 = [...type2];
      newType2.push(data);
      setType2(newType2);
    } else alert("error: Filament type incorrect");
    setAddingFila(false);
  };

  const updatePlastic = (value) => {
    setPlastic(value);
  };

  const handleSubmitData = async () => {
    if (!selectedSize) {
      return alert("เลือกขนาดใย");
    }
    if (isAddingPlas) {
      return alert("ยืนยันพลาสติกที่ใช้");
    }

    const detailData = {
      extrudeDate: convertDate(extdDate),
      extrudeSize: selectedSize,
      totalPlastic: plastic.map((row) => row.nKg).reduce(sum),
      totalFilament: round(
        (type1.length !== 0 ? type1.map((row) => row.TF).reduce(sum) : 0) +
          (type2.length !== 0 ? type2.map((row) => row.TF).reduce(sum) : 0)
      ),
      totalWaste: round(
        waste.length !== 0 ? waste.map((row) => row.amount).reduce(sum) : 0
      ),
      remark: remarks,
    };

    const res = await api.post("/extd/addData", {
      detail: detailData,
      plasticData: plastic,
      type1: type1,
      type2: type2,
      wasteData: waste,
    });
    if (res.data.success) {
      alert(res.data.message);
      clearData();
    } else {
      alert(
        "ไม่สำเร็จ โปรดแจ้งข้อความนี้" + JSON.parse(res.data.error).sqlMessage
      );
    }
  };

  const clickDeleteFilament = (type, idx) => {
    if (type === 1) {
      const newType1 = [...type1];
      newType1.splice(idx, 1);
      setType1(newType1);
    } else {
      const newType2 = [...type2];
      newType2.splice(idx, 1);
      setType2(newType2);
    }
  };

  const clickDeleteWaste = (idx) => {
    const newWaste = [...waste];
    newWaste.splice(idx, 1);
    setWaste(newWaste);
  };

  const changeRemarks = (e) => {
    setRemarks(e.target.value);
  };

  const fetchData = () => {
    api.get("/extd/getPlastic").then((xx) => {
      const data = xx.data.plastic;
      for (let i = 0; i < data.length; i++) {
        data[i].nBag = 0;
        data[i].nKg = 0;
      }
      setPlastic(data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let sumWaste = 0;
    for (let i = 0; i < waste.length; i++) {
      sumWaste += waste[i].amount;
    }
    setSumWaste(round(sumWaste));
  }, [waste]);

  const round = (num) => {
    return Math.round(num * 100) / 100;
  };

  const convertDate = (date) => {
    return (
      date.getUTCFullYear() +
      "-" +
      ("0" + (date.getUTCMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getUTCDate()).slice(-2)
    );
  };

  const sum = (accumulator, currentValue) => accumulator + currentValue;

  return (
    <div>
      <div className="form-group row">
        <div className="col-md-6">
          <div className="row">
            <label className="col col-form-label">วันที่ฉีดใย</label>
            <div className="col-sm-8">
              <DatePicker
                className="form-control"
                selected={extdDate}
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  setExtdDate(date);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <label className="col col-form-label">ขนาดใย</label>
            <div className="col-sm-8">
              <select
                className="form-control"
                value={selectedSize}
                onChange={handleSizeChange}
              >
                <option value="0">เลือกขนาดใย</option>
                <option value="0.20">0.20</option>
                <option value="0.25">0.25</option>
                <option value="0.28">0.28</option>
                <option value="0.30">0.30</option>
                <option value="0.35">0.35</option>
                <option value="0.40">0.40</option>
                <option value="0.50">0.50</option>
                <option value="0.60">0.60</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <button
        type="button"
        className="btn btn-info btn-lg btn-block"
        onClick={clickAddPlas}
      >
        เลือกเม็ดพลาสติก
      </button>
      {isAddingPlas ? (
        <AddPlastic
          onSubmit={onSubmitPlastic}
          setPlastic={updatePlastic}
          plasticData={plastic}
        />
      ) : (
        <div style={{ backgroundColor: "rgb(240,240,240)" }}>
          {plastic.map((row, idx) => (
            <div
              key={`selectedPlas-${idx}`}
              style={{
                display: "flex",
                justifyContent: "space-around",
                padding: "10px",
              }}
            >
              <div style={{ width: "30%" }}>
                <b>{row.plasticName}</b>
              </div>
              <div style={{ width: "30%" }}>
                <b>จำนวน</b> {row.nBag} ถุง
              </div>
              <div style={{ width: "30%" }}>
                <b>ปริมาณ</b> {row.nKg} kg
              </div>
            </div>
          ))}
        </div>
      )}

      <br />
      <div className="row">
        <div className="col-6">
          <button
            type="button"
            className={`btn btn-info btn-block ${
              filaType === 1 || !isAddingFila ? "" : "disabled"
            }`}
            onClick={() => clickAddFila(1)}
          >
            <i className="fas fa-plus-circle fa-lg"></i>
            <br />
            หลอดปีก
          </button>
        </div>
        <div className="col-6">
          <button
            type="button"
            className={`btn btn-info btn-block ${
              filaType === 2 || !isAddingFila ? "" : "disabled"
            }`}
            onClick={() => clickAddFila(2)}
          >
            <i className="fas fa-plus-circle fa-lg"></i>
            <br />
            หลอดสมอ
          </button>
        </div>
      </div>
      {isAddingFila && (
        <AddFilament onSubmit={onSubmitFilament} type={filaType} />
      )}
      <br />

      <h4 style={{ textAlign: "center" }}>หลอดปีก</h4>

      <table className="table table-striped table-condensed" id="type1-list">
        <thead>
          <tr>
            <th>เวลา</th>
            <th>จำนวนหลอด</th>
            <th>น้ำหนักหลอด</th>
            <th>น้ำหนักรวมหลอด</th>
            <th>น้ำหนักใย</th>
            <th>ตัวเลือก</th>
          </tr>
        </thead>
        <tbody>
          {type1.length === 0 ? (
            <tr>
              <td colSpan="6">ไม่มีข้อมูล</td>
            </tr>
          ) : (
            type1.map((row, idx) => {
              return (
                <tr key={`t1-list-${idx}`}>
                  <td>
                    {row.HR}:{row.M < 10 ? 0 + row.M : row.M}
                  </td>
                  <td>{row.pcs}</td>
                  <td>{row.W}</td>
                  <td>{row.TW}</td>
                  <td>{row.TF}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      onClick={() => clickDeleteFilament(1, idx)}
                    >
                      ลบ
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <hr />

      <h4 style={{ textAlign: "center" }}>หลอดสมอ</h4>
      <table className="table table-striped table-condensed" id="type2-list">
        <thead>
          <tr>
            <th>เวลา</th>
            <th>จำนวนหลอด</th>
            <th>น้ำหนักหลอด</th>
            <th>น้ำหนักรวมหลอด</th>
            <th>น้ำหนักใย</th>
            <th>ตัวเลือก</th>
          </tr>
        </thead>
        <tbody>
          {type2.length === 0 ? (
            <tr>
              <td colSpan="6">ไม่มีข้อมูล</td>
            </tr>
          ) : (
            type2.map((row, idx) => {
              return (
                <tr key={`t2-list-${idx}`}>
                  <td>
                    {row.HR}:{row.M < 10 ? 0 + row.M : row.M}
                  </td>
                  <td>{row.pcs}</td>
                  <td>{row.W}</td>
                  <td>{row.TW}</td>
                  <td>{row.TF}</td>
                  <td>
                    <button
                      className="btn btn-secondary"
                      onClick={() => clickDeleteFilament(2, idx)}
                    >
                      ลบ
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      <h4 style={{ textAlign: "center" }}>ข้อมูลใยเสีย</h4>
      <AddWaste onSubmit={onSubmitWaste} />

      {waste.length === 0 ? (
        <div></div>
      ) : (
        <div className="alert alert-success" role="alert">
          {waste.map((row, idx) => (
            <div key={`waste-${idx}`} style={{ padding: "5px" }}>
              <div
                className="btn btn-sm btn-success"
                style={{ marginRight: "10px" }}
              >
                <strong onClick={() => clickDeleteWaste(idx)}>ลบ</strong>
              </div>
              ตำแหน่ง: {row.position} ปริมาณใยเสีย: {row.amount} kg
            </div>
          ))}
          <hr />
          <b>ปริมาณใยเสียรวม: {sumWaste} kg</b>
        </div>
      )}

      <div className="form-group">
        <label>รายละเอียดเพิ่มเติม:</label>
        <textarea
          className="form-control"
          rows="5"
          onChange={changeRemarks}
          value={remarks}
        ></textarea>
      </div>

      <button
        type="button"
        className="btn btn-primary btn-lg btn-block"
        onClick={handleSubmitData}
      >
        ยืนยันส่งข้อมูลการฉีดใย
      </button>
    </div>
  );
};

export default ExtdMain;
