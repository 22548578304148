import React from "react";
import { Route } from "react-router-dom";

import MnmMain from "./MnmMain";
import MnmDay from "./MnmDay";
import MnmProduct from "./MnmProduct";
import MnmMachine from "./MnmMachine";
import MnmOrders from "./MnmOrders";
import MnmSale from "./MnmSale";
import MnmInv from "./Summary/MnmInv";
import MnmSaleSummary from "./Summary/MnmSaleSummary";
import MnmRequest from "./MnmRequest";
import MnmExtruding from "./MnmExtruding";
import Header from "../../Components/Header/Header";
import MnmProdTool from "./MnmTool/ProdTool/MnmProdTool";
import MnmToolSale from "./MnmTool/ProdTool/MnmToolSale";
import MnmToolProd from "./MnmTool/ProdTool/MnmToolProd";
import MnmSaleTool from "./MnmTool/SaleTool/MnmSaleTool";
import Dashboard from "./Dashboard/Dashboard";

const Management = ({ match }) => {
  return (
    <div>
      <Route exact path={match.path} component={MnmMain} />
      <Route path={`${match.path}/monthly`} component={MonthlyRoute} />
      <Route path={`${match.path}/summary`} component={SummaryRoute} />
      <Route path={`${match.path}/request`} component={MnmRequest} />
      <Route path={`${match.path}/prodTool`} component={ProdToolRoute} />
      <Route path={`${match.path}/saleTool`} component={SaleToolRoute} />
      <Route path={`${match.path}/dashboard`} component={DashboardRoute} />
    </div>
  );
};

const SummaryRoute = ({ match }) => {
  return (
    <div className="container">
      <Route path={`${match.path}/inventory`} component={MnmInv} />
      <Route path={`${match.path}/sale`} component={MnmSaleSummary} />
    </div>
  );
};

const ProdToolRoute = ({ match }) => {
  return (
    <div className="container">
      <Header name="เครื่องมือวิเคราะห์การผลิต" />
      <Route exact path={`${match.path}/`} component={MnmProdTool} />
      <Route path={`${match.path}/sale/:prodID`} component={MnmToolSale} />
      <Route path={`${match.path}/prod/:prodID`} component={MnmToolProd} />
    </div>
  );
};

const SaleToolRoute = ({ match }) => {
  return (
    <div className="container">
      <Header name="เครื่องมือวิเคราะห์การขาย" />
      <Route exact path={`${match.path}/`} component={MnmSaleTool} />
    </div>
  );
};

const DashboardRoute = ({ match }) => {
  return (
    <div className="container">
      <Header name="Dashboard" />
      <Route exact path={`${match.path}/`} component={Dashboard} />
    </div>
  );
};

const MonthlyRoute = ({ match }) => {
  return (
    <div className="container">
      <Header name="สรุปประจำเดือน" />
      {/* <Route exact path={`${match.path}/:type/:y/:m`} component={Data} /> */}
      <Route path={`${match.path}/day/:y/:m`} component={MnmDay} />
      <Route path={`${match.path}/product/:y/:m`} component={MnmProduct} />
      <Route path={`${match.path}/machine/:y/:m`} component={MnmMachine} />
      <Route path={`${match.path}/orders/:y/:m`} component={MnmOrders} />
      <Route path={`${match.path}/sale/:y/:m`} component={MnmSale} />
      <Route path={`${match.path}/extruding/:y/:m`} component={MnmExtruding} />
    </div>
  );
};

export default Management;
