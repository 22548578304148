import React, { useState, useEffect } from "react";
import ExtdDataRow from "./ExtdData/ExtdDataRow";
import { api } from "../../../api";

const ExtdMain = ({ m, y }) => {
  const [data, setData] = useState([]);

  const fetchData = () => {
    if (m && y) {
      api
        .post("/mnm/getExtruding", {
          m: m,
          y: y,
        })
        .then((xx) => {
          setData(xx.data.result);
        });
    } else {
      api.get("/extd/getExtruding").then((xx) => {
        setData(xx.data.result);
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [m, y]);

  return (
    <div>
      รายการฉีดใยย้อนหลัง
      {data ? (
        data.map((row, idx) => (
          <ExtdDataRow
            key={`extdData-${idx}`}
            row={row}
            idx={idx}
            reload={fetchData}
          />
        ))
      ) : (
        <div>กำลังโหลด</div>
      )}
    </div>
  );
};

export default ExtdMain;
