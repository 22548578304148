import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SearchProduct from "../../Components/Others/SearchProduct";
import "./Inventory.css";
import CardName from "./Components/CardName";
import { api } from "../../api";
import { withAuth } from "../../AuthContext";
import { convertDate } from "../../Components/System/mosFn";

const InvMove = ({ auth, data, reload }) => {
  const [dataMove, setDataMove] = useState([]);
  const [remark, setRemark] = useState("");
  const [choose, setChoose] = useState("initial");
  const [isChoose, setIsChoose] = useState(true);
  const [search, setSearch] = useState(["", "", "", ""]);
  const [filtered, setFiltered] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [moveDate, setMoveDate] = useState(new Date());
  const handleMoveInFT = () => {
    setChoose("inFT");
    setIsChoose(false);
  };
  const handleMoveFTWH1 = () => {
    setChoose("FTWH1");
    setIsChoose(false);
  };
  const handleMoveWH1WH2 = () => {
    setChoose("WH1WH2");
    setIsChoose(false);
  };
  const handleMoveWH2WH1 = () => {
    setChoose("WH2WH1");
    setIsChoose(false);
  };
  const handleOnChange = (e, type) => {
    const newSearch = [...search];
    newSearch[type] = e.target.value;
    setSearch(newSearch);
  };
  const handleClearSearch = () => {
    setSearch(["", "", "", ""]);
  };
  const handleOnRemarkChange = (e) => {
    setRemark(e.target.value);
  };
  const handleOnAddClick = (value) => {
    value.nmove = 1;
    setDataMove([...dataMove, value]);
  };
  const handleOnNmoveChange = (e, idx) => {
    const newDataMove = [...dataMove];
    if (isNaN(parseFloat(e.target.value))) {
      return;
    }
    newDataMove[idx].nmove = parseFloat(e.target.value);
    setDataMove(newDataMove);
  };
  const handleOnDeleteClick = (idx) => {
    const newDataMove = [...dataMove];
    delete newDataMove[idx].nmove;
    newDataMove.splice(idx, 1);
    setDataMove(newDataMove);
  };
  const handleClickOpen = () => {
    if (dataMove.length === 0) {
      return;
    }
    setIsOpen((prev) => !prev);
  };
  const handleOnSubmitClick = () => {
    const importData = {
      moveDate: convertDate(moveDate),
      remark: remark,
      submitter: auth.user,
    };
    const importDetail = dataMove.map((row) => ({
      prodID: row.prodID,
      quantity: row.nmove,
    }));

    api
      .post("/inv/" + choose, {
        importData: importData,
        importDetail: importDetail,
      })
      .then((xx) => {
        alert(xx.data);
        reload();
        clearData();
      });
  };

  useEffect(() => {
    const newFiltered = data
      .filter((aa) => {
        return aa.A.toString().indexOf(search[0]) !== -1;
      })
      .filter((aa) => {
        return aa.B.toString().indexOf(search[1]) !== -1;
      })
      .filter((aa) => {
        return aa.C.toString().indexOf(search[2]) !== -1;
      })
      .filter((aa) => {
        return aa.D.toString().indexOf(search[3]) !== -1;
      });
    setFiltered(newFiltered);
  }, [search, data]);

  useEffect(() => {
    const newData = [...data];
    for (let i = 0; i < newData.length; i++) {
      delete newData[i].nmove;
    }
  }, [data]);

  const clearData = () => {
    const newData = [...data];
    for (let i = 0; i < newData.length; i++) {
      delete newData[i].nmove;
    }
    setIsOpen(false);
    setDataMove([]);
  };

  return (
    <div>
      <div>
        <div
          className="btn-group"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "3px 10px",
          }}
        >
          <TransBtn
            check={choose === "inFT"}
            isChoose={isChoose}
            onClick={handleMoveInFT}
            name={"[ผลิต] → โรงงาน"}
          />
          <TransBtn
            check={choose === "FTWH1"}
            isChoose={isChoose}
            onClick={handleMoveFTWH1}
            name={"โรงงาน → โกดัง(1)"}
          />
          <TransBtn
            check={choose === "WH1WH2"}
            isChoose={isChoose}
            onClick={handleMoveWH1WH2}
            name={"โกดัง(1) → โกดัง(2)"}
          />
          <TransBtn
            check={choose === "WH2WH1"}
            isChoose={isChoose}
            onClick={handleMoveWH2WH1}
            name={"โกดัง(2) → โกดัง(1)"}
          />
        </div>

        {!isChoose && (
          <div
            className="col-12"
            style={{
              paddingTop: "10px",
            }}
          >
            <button
              className="btn btn-block btn-info"
              onClick={handleClickOpen}
            >
              จำนวนรายการสินค้า: {dataMove.length} รายการ
            </button>
            {isOpen && (
              <div
                style={{
                  padding: "10px",
                  margin: "0px 5px 5px 5px",
                  marginBottom: "10px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  backgroundColor: "rgb(240, 240, 240)",
                }}
              >
                <div style={{ display: "flex" }}>
                  <label className="col-2">วันที่บิล</label>
                  <div className="">
                    <DatePicker
                      className="form-control"
                      selected={moveDate}
                      onChange={(date) => {
                        setMoveDate(date);
                      }}
                    />
                  </div>
                </div>
                {dataMove.map((row, idx) => {
                  return (
                    <div
                      key={`dataMove-${idx}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <CardName data={row} />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control col-md"
                          style={{ display: "inline-block", width: "auto" }}
                          value={row.nmove}
                          onChange={(e) => handleOnNmoveChange(e, idx)}
                        />
                        <button
                          className="btn btn-danger"
                          onClick={() => handleOnDeleteClick(idx)}
                        >
                          ลบ
                        </button>
                      </div>
                    </div>
                  );
                })}
                <div>
                  หมายเหตุ
                  <input
                    className="form-control"
                    onChange={(e) => handleOnRemarkChange(e)}
                    value={remark}
                  />
                </div>
                <button
                  className="btn btn-block btn-success"
                  onClick={handleOnSubmitClick}
                >
                  ยืนยัน
                </button>
              </div>
            )}
            <SearchProduct
              onChange={handleOnChange}
              onClick={handleClearSearch}
              value={search}
            />
            <div
              style={{
                display: "block",
                width: "100%",
                overflow: "scroll",
                backgroundColor: "lightgrey",
              }}
            >
              {filtered.map((row, idx) => {
                return (
                  <div
                    key={`filtered-${idx}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "5px",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: "rgb(240, 240, 240)",
                    }}
                  >
                    <div className="col-10">
                      <div>
                        <CardName data={row} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div className="amount-bg">โรงงาน: {row.inv_ft}</div>
                        <div className="amount-bg">
                          โกดัง[1]: {row.inv_wh - row.inv_wh2}
                        </div>
                        <div className="amount-bg">โกดัง[2]: {row.inv_wh2}</div>
                      </div>
                    </div>
                    <button
                      className={`btn col-2 btn-secondary ${
                        row.nmove ? "mdisabled" : ""
                      }`}
                      style={{ borderRadius: "10px" }}
                      onClick={() => handleOnAddClick(row)}
                    >
                      {filtered[idx].nmove ? "✓" : "เพิ่ม"}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const TransBtn = ({ check, isChoose, onClick, name }) => {
  const linkStyle = {
    backgroundColor: "lightgrey",
    textDecoration: "none",
    fontSize: "1rem",
    color: "#212529",
  };

  return (
    <button
      // className={`textcenter flex1 ${check || isChoose ? "" : "mdisabled"}`}
      className={`btn  ${check || isChoose ? "" : "mdisabled"}`}
      style={{
        backgroundColor: "lightgray",
        border: "1px solid black",
      }}
      // style={linkStyle}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export default withAuth(InvMove);
