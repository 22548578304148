import React from "react";
import { Line } from "react-chartjs-2";

const MnmMacGraph = ({ data }) => {
  const gdate = data.map((aa) => {
    return aa.date;
  });

  const gmeter = data.map((aa) => {
    return aa.meter;
  });

  const chartData = {
    labels: gdate,
    datasets: [
      {
        label: "มีเตอร์ประจำวัน",
        data: gmeter,
        lineTension: 0,
        backgroundColor: "transparent",
        borderColor: "#007bff",
        borderWidth: 4,
        pointBackgroundColor: "#007bff",
      },
    ],
  };
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };
  return <Line data={chartData} options={options} />;
};

export default MnmMacGraph;
