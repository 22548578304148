import React from "react";
import { Route } from "react-router-dom";
import UserMain from "./UserMain";
import UserChangePwd from "./UserChangePwd";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/UI/Menu";

const User = ({ match }) => {
  const link = [
    { name: "ข้อมูลผู้ใช้", to: "" },
    { name: "เปลี่ยนรหัสผ่าน", to: "changepassword" },
  ];
  return (
    <div className="container">
      <Header name="ข้อมูลผู้ใช้" />
      <Menu match={match.path} link={link} />
      <Route exact path={match.path} component={UserMain}></Route>
      <Route
        path={`${match.path}/changepassword`}
        component={UserChangePwd}
      ></Route>
    </div>
  );
};

export default User;
