import React, { useState, useEffect } from "react";
import CustData from "./CustData";
import { api } from "../../api";

const ListCust = ({ chooseCust }) => {
  const [custList, setCustList] = useState([]);
  const [custListSearch, setCustListSearch] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await api
      .get("/cust/listCust")
      .then((xx) => {
        setCustList(xx.data.data);
        setCustListSearch(xx.data.data);
      })
      .catch((err) => console.error(err));
  };

  const handleOnChange = (searchKey) => {
    const newCustListSearch = custList.filter((aa) => {
      return aa.custName.toString().indexOf(searchKey) !== -1;
    });
    setCustListSearch(newCustListSearch);
  };

  return (
    <div>
      <div className="cust-search-2">
        <input
          className="form-control"
          placeholder="...พิมพ์ชื่อที่จะค้นหา"
          onChange={(e) => handleOnChange(e.target.value)}
        />
      </div>

      <div
        style={{ height: "30vh", overflow: "auto", backgroundColor: "white" }}
      >
        {custListSearch.map((data) => (
          <CustData
            key={`cust-${data.custID}`}
            data={data}
            onClick={(data) => chooseCust(data)}
          />
        ))}
      </div>
    </div>
  );
};

export default ListCust;
