import React, { useState, useEffect } from "react";
import { api } from "../../../api";

import "./Weaving.css";

import SearchProduct from "../../../Components/Others/SearchProduct";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withAuth } from "../../../AuthContext";

const color = [
  "maroon",
  "red",
  "#b36200",
  "olive",
  "purple",
  "MediumVioletRed",
  "darkgreen",
  "DarkCyan",
  "SteelBlue",
  "teal",
  "blue",
  "navy",
  "Chocolate",
  "SaddleBrown",
];

const WeavMain = ({ auth }) => {
  const [data, setData] = useState(false);
  const [open, setOpen] = useState(false);
  const [spool, setSpool] = useState(0);
  const [spoolOpen, setSpoolOpen] = useState(false);
  const [mac, setMac] = useState("");
  const [prodn, setProdn] = useState([]);
  const [Alist, setAlist] = useState(false);
  const [choosingA, setChoosingA] = useState(false);

  const [listProd, setListProd] = useState(null);
  const handleClickEdit = async (mac) => {
    setOpen(true);
    if (!listProd) {
      await api.get("/weav/listProd").then((xx) => {
        setListProd(
          xx.data.result.map((aa) => {
            aa.A = aa.A.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            aa.B =
              "" +
              aa.B +
              (aa.unit_B === 1 ? '"' + (aa.B2 !== "0" ? aa.B2 : "") : "");
            aa.prod = aa.A + "|" + aa.B + "|" + aa.C + "|" + aa.D;
            return aa;
          })
        );
      });
    }
    setMac(mac);
  };
  const handleSubmitSpool = () => {
    if (spool === 0) return alert("กรอกจำนวน");
    const spoolData = { mac: mac, spool: spool, user: auth.user };
    api.post("/weav/editSpool", spoolData).then((xx) => {
      if (xx.data.success) {
        alert(xx.data.result);
        fetchData();
        setSpoolOpen(false);
        setSpool(0);
      } else {
        alert(xx.data.error);
      }
    });
  };

  const handleClickSpool = (mac) => {
    if (!auth.access["prodWeavAdd"]) return;
    setMac(mac);
    setSpoolOpen(true);
  };

  const fetchData = async () => {
    const res = await api.get("/weav/listMachine");
    const result = res.data.result.map((row) => {
      if (row.A) {
        row.A = row.A.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return row;
    });
    let prodlist = [];
    let alist = [];
    let macObj = {};
    for (let i = 0; i < result.length; i++) {
      macObj[result[i].mac] = result[i];
      if (result[i].A && !alist.includes(result[i].A)) {
        alist.push(result[i].A);
      }
      if (result[i].p1 && !prodlist.includes(result[i].p1)) {
        prodlist.push(result[i].p1);
      }
      if (result[i].p2 && !prodlist.includes(result[i].p2)) {
        prodlist.push(result[i].p2);
      }
      if (result[i].p3 && !prodlist.includes(result[i].p3)) {
        prodlist.push(result[i].p3);
      }
    }
    alist.sort();
    setAlist(alist);
    setProdn(prodlist);
    setData(macObj);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flexcol">
      <EditDialog
        open={open}
        onClose={() => setOpen(false)}
        listProd={listProd}
        mac={mac}
        reload={fetchData}
      />
      <Dialog open={spoolOpen} onClose={() => setSpoolOpen(false)}>
        <form
          onSubmit={(e) => e.preventDefault()}
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
          }}
          // style={{ , backgroundColor: "white" }}
        >
          <h4>แก้ไขจำนวนกระสวย เครื่อง {mac}</h4>
          <p>ใส่จำนวนกระสวย เพื่อเอาไว้คำนวนจำนวนที่ผลิตได้ในแต่ละเครื่อง</p>
          <div style={{ display: "flex" }}>
            <Input
              autoFocus
              value={spool === 0 ? "" : spool}
              onChange={(e) => {
                const num = parseInt(e.target.value);
                setSpool(isNaN(num) ? 0 : num);
              }}
              endAdornment={
                <InputAdornment position="end">กระสวย</InputAdornment>
              }
            />
            <button
              type="submit"
              style={{
                border: "1px solid black",
                margin: "5px",
                borderRadius: "3px",
              }}
              className="flex1"
              onClick={handleSubmitSpool}
            >
              บันทึก
            </button>
          </div>
        </form>
      </Dialog>

      <div className="flexrow" style={{ flexWrap: "wrap" }}>
        <b className="center flex0" style={{ marginRight: "5px" }}>
          กรอง:{" "}
        </b>
        <div className="btn-group flex10">
          {Alist &&
            Alist.map((row) => (
              <button
                key={`btnA-${row}`}
                className={`btn btn-secondary ${
                  choosingA && choosingA !== row ? "mdisabled" : ""
                }`}
                onClick={() => {
                  if (choosingA === row) return setChoosingA(false);
                  setChoosingA(row);
                }}
              >
                {row}
              </button>
            ))}
        </div>
        <button
          className="btn btn-danger"
          style={{ flex: "1 0 0" }}
          onClick={() => setChoosingA(false)}
        >
          ทั้งหมด
        </button>
      </div>
      {data ? (
        <div>
          <MacBlock
            onClickEdit={handleClickEdit}
            onClickSpool={handleClickSpool}
            prodn={prodn}
            Alist={Alist}
            choosingA={choosingA}
            data={[data.D1, data.D2, data.D3, data.D4, data.D6]}
          />
          <hr className="mg0" />
          <MacBlock
            onClickEdit={handleClickEdit}
            onClickSpool={handleClickSpool}
            prodn={prodn}
            choosingA={choosingA}
            data={[data.C1, data.C2, data.C3, data.C4, data.C5]}
          />
          <hr className="mg0" />
          <MacBlock
            onClickEdit={handleClickEdit}
            onClickSpool={handleClickSpool}
            prodn={prodn}
            choosingA={choosingA}
            data={[data.B1, data.B2, data.B3, data.B4, data.B5]}
          />
          <hr className="mg0" />
          <MacBlock
            onClickEdit={handleClickEdit}
            onClickSpool={handleClickSpool}
            prodn={prodn}
            choosingA={choosingA}
            data={[data.A1, data.A2, data.A3, data.A4, data.A5]}
          />
          <br />
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

const _MacBlock = ({
  auth,
  data,
  prodn,
  choosingA,
  onClickEdit,
  onClickSpool,
}) => {
  return (
    <div className="row">
      {data.map((row, idx) => {
        if (row) {
          return (
            <div
              className={`flexcol flex-spb bd
              ${choosingA && choosingA !== row.A && "mdisabled"} ${
                row.A === "0.20"
                  ? "c1"
                  : row.A === "0.25"
                  ? "c2"
                  : row.A === "0.28"
                  ? "c3"
                  : row.A === "0.30"
                  ? "c4"
                  : row.A === "0.35"
                  ? "c5"
                  : row.A === "0.40"
                  ? "c6"
                  : row.A === "0.50"
                  ? "c7"
                  : row.A === "0.60"
                  ? "c8"
                  : "cn"
              }`}
              style={{
                flex: "1 1 0",
                maxWidth: "50%",
                minWidth: "150px",
                padding: "5px",
                margin: "5px",
              }}
              key={`mac${row.mac}`}
            >
              <div
                className="flexrow"
                style={{ backgroundColor: "lightgray", color: "#333333" }}
              >
                <b className="flex1 text-center">{row.mac}</b>
                <div
                  className="flex3 text-center"
                  style={{ backgroundColor: "#bfbfbf" }}
                  onClick={() => onClickSpool(row.mac)}
                >
                  {row.nspool} กระสวย
                </div>
              </div>
              <div style={{ margin: "5px" }}>
                {row.p1 && (
                  <div
                    className="text-center prodBlock"
                    style={{ background: `${color[prodn.indexOf(row.p1)]}` }}
                  >
                    {row.p1}
                  </div>
                )}
                {row.p2 && (
                  <div
                    className="text-center prodBlock"
                    style={{ background: `${color[prodn.indexOf(row.p2)]}` }}
                  >
                    {row.p2}
                  </div>
                )}
                {row.p3 && (
                  <div
                    className="text-center prodBlock"
                    style={{ background: `${color[prodn.indexOf(row.p3)]}` }}
                  >
                    {row.p3}
                  </div>
                )}
              </div>
              <div className="text-center">
                {row.utime && (
                  <small style={{ color: "#737373" }}>อัพเดท {row.utime}</small>
                )}
                {auth.access["prodWeavAdd"] ? (
                  <button
                    className="btn btn-block btn-shadow"
                    style={{
                      backgroundColor: "#d9d9d9",
                      padding: "0px",
                    }}
                    onClick={() => onClickEdit(row.mac)}
                  >
                    แก้ไขรายการผลิต
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        } else {
          return (
            <div
              className="flexcol flex-spb bd"
              style={{
                flex: "1 0 0",
                backgroundColor: "#f2f2f2",
                padding: "5px",
                margin: "5px",
              }}
              key={`mac-${idx}`}
            ></div>
          );
        }
      })}
    </div>
  );
};
const MacBlock = withAuth(_MacBlock);

const _EditDialog = (props) => {
  const { auth, onClose, open, listProd, mac, reload } = props;
  const [search, setSearch] = useState(["", "", "", ""]);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState(null);
  const handleChange = (e, type) => {
    const newSearch = [...search];
    newSearch[type] = e.target.value;
    setSearch(newSearch);
  };
  const handleChoose = (row) => {
    if (data.length === 3)
      return alert(
        "จำกัด3รายการ กดที่ชื่อสินค้าเพื่อลบ แต่ถ้าใช้มากกว่านั้นโปรดติดต่อผู้ดูแลระบบ"
      );
    row.choosed = true;
    const newData = [...data];
    newData.push(row);
    setData(newData);

    setSearch([row.A, row.B, "", ""]);
  };
  const handleDelete = (idx) => {
    const newData = [...data];
    delete newData[idx].choosed;
    newData.splice(idx, 1);
    setData(newData);
  };
  const handleClearSearch = () => {
    setSearch(["", "", "", ""]);
  };
  useEffect(() => {
    if (!listProd) return;
    const filtered = listProd
      .filter((aa) => {
        return aa.A.toString().indexOf(search[0]) !== -1;
      })
      .filter((aa) => {
        return aa.B.toString().indexOf(search[1]) !== -1;
      })
      .filter((aa) => {
        return aa.C.toString().indexOf(search[2]) !== -1;
      })
      .filter((aa) => {
        return aa.D.toString().indexOf(search[3]) !== -1;
      });
    setFiltered(filtered);
  }, [search, listProd]);

  const handleClose = () => {
    onClose();
    const newData = [...data];
    for (let i = 0; i < newData.length; i++) {
      delete newData[i].choosed;
    }
    setData([]);
    handleClearSearch();
  };

  const handleSubmit = () => {
    if (!data) return alert("เลือกรายการผลิต");
    const editData = [
      data[0] ? data[0].prodID : null,
      data[1] ? data[1].prodID : null,
      data[2] ? data[2].prodID : null,
    ];
    const editDetail = { mac: mac, user: auth.user };
    api
      .post("/weav/updateMacProd", { data: editData, detail: editDetail })
      .then((xx) => {
        if (xx.data.success) {
          alert(xx.data.result);
          reload();
          handleClose();
        } else {
          alert(xx.data.error);
        }
      });
  };

  return (
    <Dialog
      scroll="paper"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        เปลี่ยนรายการผลิต {mac}
      </DialogTitle>

      {data.length > 0 && (
        <div
          className="flexrow"
          style={{ padding: "16px 24px", flexWrap: "wrap" }}
        >
          {data.map((row, idx) => (
            <div
              key={row.prodID}
              className="col-md text-center bd"
              onClick={() => handleDelete(idx)}
              style={{ borderRadius: "10px", margin: "5px" }}
            >
              {row.prod}
            </div>
          ))}
        </div>
      )}

      <DialogContent
        style={{
          backgroundColor: "rgb(240, 240, 240)",
          height: "50vh",
        }}
      >
        <SearchProduct
          onChange={handleChange}
          onClick={handleClearSearch}
          value={search}
        />
        {filtered &&
          filtered.map((row, idx) => {
            return (
              <div
                key={`prodID-${row.prodID}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px",
                  padding: "5px",
                  borderRadius: "3px",
                  backgroundColor: "rgb(240, 240, 240)",
                  alignItems: "center",
                }}
              >
                <div>{row.prod}</div>
                <div>
                  <div
                    className={`btn btn-secondary ${
                      filtered[idx].choosed && "disabled"
                    }`}
                    onClick={() => handleChoose(row)}
                  >
                    {filtered[idx].choosed ? "✓" : "เพิ่ม"}
                  </div>
                </div>
              </div>
            );
          })}
      </DialogContent>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "16px 24px",
        }}
      >
        <button type="button" onClick={onClose} className="btn btn-danger">
          ยกเลิก
        </button>
        <button className="btn btn-success" onClick={handleSubmit}>
          ยืนยัน
        </button>
      </div>
    </Dialog>
  );
};

const EditDialog = withAuth(_EditDialog);

export default withAuth(WeavMain);
