import React from "react";
import { Route } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Menu from "../../Components/UI/Menu";
import MatPlastic from "./MatPlastic";
import MatPlasticTrans from "./MatPlasticTrans";
import MatPlasticImport from "./MatPlasticImport";

const Material = ({ match }) => {
  return (
    <div className="container">
      <Header name="วัตถุดิบ" />

      {/* <Route exact path={`${match.path}`} component={ExtdMain} /> */}
      <Route path={`${match.path}/plastic`} component={PlasticRoute} />
    </div>
  );
};

const PlasticRoute = ({ match }) => {
  const link = [
    { name: "พลาสติกคงเหลือ", to: "" },
    { name: "นำเม็ดเข้าโรงงาน", to: "import", auth: "" },
    { name: "รายการเคลื่อนไหวพลาสติก", to: "transfer" },
  ];
  return (
    <div className="container">
      <Menu match={match.path} link={link} />
      <Route exact path={`${match.path}/`} component={MatPlastic} />
      <Route path={`${match.path}/import`} component={MatPlasticImport} />
      <Route path={`${match.path}/transfer`} component={MatPlasticTrans} />
    </div>
  );
};

export default Material;
